import React, { useState, useEffect } from "react";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  dvbs2CodeRates,
  dvbs2CodeRatesOp4,
  dvbs2Modulations,
  dvbs2ModulationsOp4,
  plpCodeRates,
  plpModulations,
  plpGuardInterval,
  plpPilotConfig,
  fftSizes,
  outerParityTypes,
  ldpcTypes,
  RfPowerLevels,
  TunerVoltages,
  MaxSubframes,
  MaxPlpsPerSubframe,
  BRHModels,
  RfPowerLevels5w,
} from "../../../../common/constants/config_params";
import StatisticsRowTable from "./StatisticsRowTable";

let role = localStorage.getItem("roleKey");

const secondsToHHMMSS = (seconds) => {
  let secs, mins, hrs, days;
  mins = Math.floor(seconds / 60);
  secs = seconds % 60;
  hrs = Math.floor(mins / 60);
  mins = mins % 60;
  days = Math.floor(hrs / 24);
  hrs = hrs % 24;

  let outputstr = "";
  if (days > 0) {
    outputstr += `${days} Days, `;
  }
  if (hrs > 0) {
    outputstr += `${hrs} Hours, `;
  }
  if (mins > 0) {
    outputstr += `${mins} Minutes, `;
  }

  outputstr += `${secs} Seconds`;

  return outputstr;
  //return (Math.floor(seconds / 3600)) + ":" +
  //("0" + Math.floor(seconds / 60) % 60).slice(-2)
  // + ":" + ("0" + seconds % 60).slice(-2)
};

const formatTimestamp = (
  timestamp,
  locale = "en-US",
  formatterObject = {
    month: "short",
    day: "2-digit",
    hour: "numeric",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  }
) => {
  // timestamp should be passed in millseconds
  var date = new Date(timestamp);
  return date.toLocaleString(locale, formatterObject);
};

const getLastDowntime = (start, end, uptime) => {
  let startTimestamp = formatTimestamp(start * 1000); // passing start in milliseconds
  let endTimestamp = formatTimestamp(end * 1000); // passing end in milliseconds

  if (start === 0) {
    if (end === 0) {
      return "unknown";
    }
    return `end: ${endTimestamp}, Duration: ${0}`;
  }
  if (end === 0) {
    let currentTimeInSec = Math.floor(Date.now() / 1000);
		if (currentTimeInSec - start > uptime) {
			return `Start: ${startTimestamp}; Duration: ${secondsToHHMMSS(uptime)}`;
		} else {
			return `Start: ${startTimestamp}; Duration: ${secondsToHHMMSS(currentTimeInSec - start)}`;
		}
  }
  let duration = secondsToHHMMSS(end - start);
  return `Start: ${startTimestamp}, End: ${endTimestamp}; Duration: ${duration}`;
};

const getValue = (array, value) => {
  let r = array.find((e) => e.value === value);
  return r ? r.key : "undefined";
};

const initialPanelStates = {
  backhaulLink: { expanded: false, alarmKeys: [] },
  fronthaulLink: { expanded: false, alarmKeys: [] },
  systemStatus: { expanded: false, alarmKeys: [] },
  gpsStatus: { expanded: false, alarmKeys: [] },
  sensor_diagnostics: { expanded: false, alarmKeys: [] },
};

export default function SimpleExpansionPanel(props) {
  //let device1 = {}
  const [panelStates, setPanelStates] = useState(() => initialPanelStates);
  const { selectedDevice: device, lastDeviceStats: stats, selected_device_configuration } = props;
  const device_transition_frequency = selected_device_configuration.transition_frequency
  const selected_device_tx_frequency = (props.selectedDevice ? (device_transition_frequency === true ? device.device_atsc3_tx_frequency_8m : device.device_atsc3_tx_frequency) : "Unknown");
  const selected_device_model = (props.selectedDevice ? props.selectedDevice.provisioned_device.model_no : "Unknown");
  const selected_device_model_no = (props.selectedDevice ? props.selectedDevice.device_model_version : "Unknown");
  const stl_source = parseInt(selected_device_configuration.stl_source);
  let deviceId = device && device.id ? device.id : null;
  const dvbs2LockStatus = Boolean(stats && stats.dvbs2_lock_status);
  const noDvbs2 = dvbs2LockStatus ? "" : "(No DVBS2 Signal)";

  // following useEffect will be called everytime 'stats' changes
  useEffect(() => {
    if (stats) {
      generateAlarmConditions();
    }
  }, [stats]);

  // generateAlarmConditions() will generate alarmKeys as per alarmConditions
  const generateAlarmConditions = () => {
    const alarmConditions = {
      backhaulLink: [stats.dvbs2_lock_status === false && "Lock Status"],
      fronthaulLink: [
        stats.pipeline_sched_stl_out_rate === 0 && "STL-Out Rate (Frames/Sec)",
      ],
      systemStatus: [
        stats.system_stats_active_running_time === 0 &&
          "RF Active Running Time",
      ],
      gpsStatus: [stats.gps_lock_status === false && "Lock Status"],
    };

    for (const [panelName, alarmKeys] of Object.entries(alarmConditions)) {
      let filteredAlarmKeys = alarmKeys.filter((alarmKey) => alarmKey);
      setPanelStates((prevPanelStates) => ({
        ...prevPanelStates,
        [panelName]: {
          ...prevPanelStates[panelName],
          alarmKeys: filteredAlarmKeys,
        },
      }));
    }
  };

  // following useEffect will be called everytime 'deviceId' changes
  useEffect(() => {
    setPanelStates({ ...initialPanelStates });
  }, [deviceId]);

  const handleChangeExpanded = (panelName) => {
    setPanelStates({
      ...panelStates,
      [panelName]: {
        ...panelStates[panelName],
        expanded: !panelStates[panelName].expanded,
      },
    });
  };

  let getSubframeStats = () => {
    if (!stats) {
      return null;
    }

    let subframes_content = [];

    for (let i = 0; i < MaxSubframes; i++) {
      let subframe_header_content = null;
      let subframe_prefix = `subframe${i}`;

      let subframe_active = stats[`${subframe_prefix}_active`];

      if (subframe_active) {
        subframe_header_content = (
          <StatisticsRowTable
            tableHeader={`Subframe ${i} Parameters`}
            data={{
              "FFT Size": getValue(
                fftSizes,
                stats[`${subframe_prefix}_fft_size`]
              ),
              "Guard Interval": getValue(
                plpGuardInterval,
                stats[`${subframe_prefix}_gi_config`]
              ),
              "Pilot Config": getValue(
                plpPilotConfig,
                stats.subframe0_pilot_config
              ),
            }}
          />
        );

        subframes_content.push(subframe_header_content);

        for (let j = 0; j < MaxPlpsPerSubframe; j++) {
          let plpcontent = null;
          if (stats[`subframe${i}_plp${j}_active`]) {
            plpcontent = (
              <StatisticsRowTable
                tableHeader={`Subframe${i}: PLP ${j}`}
                data={{
                  "Code Rate": getValue(
                    plpCodeRates,
                    stats[`subframe${i}_plp${j}_code_rate`]
                  ),
                  "Modulation Scheme": getValue(
                    plpModulations,
                    stats[`subframe${i}_plp${j}_mod_code`]
                  ),
                  "Outer Parity Type":
                    outerParityTypes[
                      stats[`subframe${i}_plp${j}_outer_parity_type`]
                    ],
                  "LDPC Length": stats[`subframe${i}_plp${j}_ldpc_len`],
                  "LDPC Type":
                    ldpcTypes[stats[`subframe${i}_plp${j}_ldpc_type`]],
                }}
              />
            );
          }
          subframes_content.push(plpcontent);
        }
      }
    }
    return subframes_content;
  };

  return (
    <div>
      <ExpansionPanel
        defaultExpanded={false}
        expanded={panelStates.backhaulLink.expanded}
        onChange={() => handleChangeExpanded("backhaulLink")}
        className={
          Boolean(panelStates.backhaulLink.alarmKeys.length) ? "alarm" : ""
        }
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <strong style={{color:'black'}}>Backhaul Link </strong>
        </ExpansionPanelSummary>

        {stats ? (
          <ExpansionPanelDetails>
            <div className="row">
              {stl_source === 1 ? (
              <StatisticsRowTable
                tableHeader={`DVBS2 Link `}
                tableHeader1={`${noDvbs2}`}
                alarmKeys={panelStates.backhaulLink.alarmKeys}
                data={{
                  "Lock Status": String(stats.dvbs2_lock_status),
                  [`${selected_device_model === BRHModels[1] ? "Signal Strength (%)" : "Input (dbm)"}`]: stats.dvbs2_input_dbm,
                  "SNR (dB)": stats.dvbs2_snr,
                  PER: stats.dvbs2_per.toFixed(4),
                  "Code Rate": getValue(
                    selected_device_model === BRHModels[1] ? dvbs2CodeRatesOp4 : dvbs2CodeRates,
                    stats.dvbs2_code_rate
                    ),
                  "Modulation Scheme": getValue(
                    selected_device_model === BRHModels[1] ? dvbs2ModulationsOp4 : dvbs2Modulations,
                    stats.dvbs2_modulation
                  ),
                  "Roll Off Factor": stats.dvbs2_roll_of_factor.toFixed(4),
                  "Tuner Voltage": TunerVoltages[stats.dvbs2_tuner_voltage].key,
                }}
              /> ) : null}

              <StatisticsRowTable
                tableHeader="Backhaul Errors (per minute)"
                data={{
                  "MPE Section Errors": stats.dvbs2_mpe_section_error_count,
                  "RTP Sequence Erros": stats.dvbs2_rtp_seq_error_count,
                  "TS Continuity Errors": stats.dvbs2_ts_continuity_error_count,
                  "Backhaul Misc Errors": stats.dvbs2_backhaul_misc_error_count,
                }}
              />

              {stats.stltp_smpte_rows > 0 ? (
                <StatisticsRowTable
                  tableHeader="SMPTE Info"
                  data={{
                    "SMPTE FEC Rows": stats.stltp_smpte_rows,
                    "SMPTE FEC Columns": stats.stltp_smpte_columns,
                  }}
                />
              ) : null}
            </div>
          </ExpansionPanelDetails>
        ) : null}
      </ExpansionPanel>

      <ExpansionPanel
        defaultExpanded={false}
        expanded={panelStates.fronthaulLink.expanded}
        onChange={() => handleChangeExpanded("fronthaulLink")}
        className={
          Boolean(panelStates.fronthaulLink.alarmKeys.length) ? "alarm" : ""
        }
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <strong>Fronthaul Link</strong>
        </ExpansionPanelSummary>

        {stats ? (
          <ExpansionPanelDetails>
            <div className="row">
              <StatisticsRowTable
                tableHeader="Pipeline Scheduler"
                alarmKeys={panelStates.fronthaulLink.alarmKeys}
                data={{
                  "Current Sampling Rate (Msps)":
                    stats.pipeline_sched_curr_sampling_rate,
                  "Last Pkt Proc Latency (\u00B5Sec)": stats.pipeline_sched_last_proc_latency.toFixed(
                    4
                  ),
                  "STL-Out Rate (Frames/Sec)":
                    stats.pipeline_sched_stl_out_rate,
                  "Emission Rate (Frames/Sec)":
                    stats.pipeline_sched_emission_rate,
                  "Buffered Jitter Frame Count":
                    stats.pipeline_sched_buffered_jitter_frame_count,
                }}
              />

              <StatisticsRowTable
                tableHeader="Transmitter Status"
                data={{
                  "Transmission Power": device
                    ? getValue(
                      device.device_model_version === "1.4" ? RfPowerLevels5w : RfPowerLevels,
                        device.device_atsc3_tx_rf_power_level
                      )
                    : "-",
                  "Transmission Frequency (MHz)": device
                    ? parseFloat(selected_device_tx_frequency).toFixed(1)
                    : "-",
                  ...(() => {
                    return device && device.device_model_version >= "1.1"
                      ? {
                          "BB Board TX Gain (dB)": parseFloat(
                            stats.bb_board_tx_gain
                          ).toFixed(2),
                          "RF Transmission":
                            stats.pa_control !== undefined &&
                            stats.model_version >= 1
                              ? stats.pa_control === false
                                ? "Off"
                                : "On"
                              : "",
                        }
                      : null;
                  })(),
                }}
              />

              {getSubframeStats()}
            </div>
          </ExpansionPanelDetails>
        ) : null}
      </ExpansionPanel>

      <ExpansionPanel
        defaultExpanded={false}
        expanded={panelStates.systemStatus.expanded}
        onChange={() => handleChangeExpanded("systemStatus")}
        className={
          Boolean(panelStates.systemStatus.alarmKeys.length) ? "alarm" : ""
        }
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <strong>System Status</strong>
        </ExpansionPanelSummary>

        {stats ? (
          <ExpansionPanelDetails>
            <div className="row">
              <StatisticsRowTable
                tableHeader="BRH System Info"
                alarmKeys={panelStates.systemStatus.alarmKeys}
                data={{
                  "BIST Status": String(stats.system_stats_brh_bist_status),
                  "Last RF Downtime": getLastDowntime(
                    stats.system_stats_brh_last_downtime_start,
                    stats.system_stats_brh_last_downtime_end,
										stats.system_stats_system_uptime
                  ),
                  "RF Active Running Time":
                    "Duration: " +
                    secondsToHHMMSS(stats.system_stats_active_running_time),
                  "System Uptime":
                    "Duration: " +
                    secondsToHHMMSS(stats.system_stats_system_uptime),
                }}
              />
            </div>
          </ExpansionPanelDetails>
        ) : null}
      </ExpansionPanel>

      <ExpansionPanel
        defaultExpanded={false}
        expanded={panelStates.gpsStatus.expanded}
        onChange={() => handleChangeExpanded("gpsStatus")}
        className={
          Boolean(panelStates.gpsStatus.alarmKeys.length) ? "alarm" : ""
        }
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <strong>GPS Status</strong>
        </ExpansionPanelSummary>

        {stats ? (
          <ExpansionPanelDetails>
            <div className="row">
              <StatisticsRowTable
                tableHeader="GPS Info"
                alarmKeys={panelStates.gpsStatus.alarmKeys}
                data={{
                  "Lock Status": String(stats.gps_lock_status),
                  "Location (Latitude, Longitude)": stats.gps_lock_status
                    ? `${stats.gps_latitude.toFixed(4)},
                                        ${stats.gps_longitude.toFixed(4)}`
                    : "-",
                  "GPS Time": stats.gps_lock_status
                    ? formatTimestamp(stats.gps_epoc_time * 1000)
                    : "-",
                }}
              />
            </div>
          </ExpansionPanelDetails>
        ) : null}
      </ExpansionPanel>


      {selected_device_model_no > "1.5" && selected_device_model !== BRHModels[0] &&
      (role === "owner" || role === "admin") ? (
      <ExpansionPanel
        defaultExpanded={false}
        expanded={panelStates.sensor_diagnostics.expanded}
        onChange={() => handleChangeExpanded("sensor_diagnostics")}
        className={
          Boolean(panelStates.sensor_diagnostics.alarmKeys.length) ? "alarm" : ""
        }
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <strong>Sensor Diagnostics</strong>
        </ExpansionPanelSummary>

        {stats ? (
          <ExpansionPanelDetails>
            <div className="row">


              <StatisticsRowTable
                tableHeader="System Temperatures"
                alarmKeys={panelStates.sensor_diagnostics.alarmKeys}
                data={{
                  "Driver PA Temperature (\u00B0C)": `${stats.system_stats_driver_pa_temp.toFixed(2)}`,
                  "Fullnim Temperature (\u00B0C)": `${stats.system_stats_fullnim_temp.toFixed(2)}`,
                  "Main PA Temperature (\u00B0C)": `${stats.system_stats_main_pa_temp.toFixed(2)}`,
                  "P3BB Temperature (\u00B0C)": `${stats.system_stats_p3bb_temp.toFixed(2)}`,
                  "PA PS Temperature (\u00B0C)": `${stats.system_stats_pa_ps_temp.toFixed(2)}`,
                  "PROC PS Temperature (\u00B0C)": `${stats.system_stats_proc_ps_temp.toFixed(2)}`,
                  "RFSSG Temperature (\u00B0C)": `${stats.system_stats_rfssg_temp.toFixed(2)}`,
                  "SOM External Temperature (\u00B0C)": `${stats.system_stats_som_external_temp.toFixed(2)}`,
                  "SOM Internal Temperature (\u00B0C)": `${stats.system_stats_som_internal_temp.toFixed(2)}`,
                }}
              />

              <StatisticsRowTable
                tableHeader="System Voltage and Currents"
                alarmKeys={panelStates.sensor_diagnostics.alarmKeys}
                data={{
                  "PA 50V Current (A)": `${stats.system_stats_pa_50v_current.toFixed(4)}`,
                  "PA 50V Voltage (V)": `${stats.system_stats_pa_50v_voltage.toFixed(4)}`,
                  "PROC 12V Current (A)": `${stats.system_stats_proc_12v_current.toFixed(4)}`,
                  "PROC 12V Voltage (V)": `${stats.system_stats_proc_12v_voltage.toFixed(4)}`,
                  "PROC 6V Current (A)": `${stats.system_stats_proc_6v_current.toFixed(4)}`,
                  "PROC 6V Voltage (V)": `${stats.system_stats_proc_6v_voltage.toFixed(4)}`,
                  "Forward Power Voltage (V)": `${stats.system_stats_fwd_pwr_voltage.toFixed(4)}`,
                  "Reverse Power Voltage (V)": `${stats.system_stats_rev_pwr_voltage.toFixed(4)}`,
                }}
              />

              <StatisticsRowTable
                tableHeader="Other Fields"
                alarmKeys={panelStates.sensor_diagnostics.alarmKeys}
                data={{
                  "VGA Value (dB)": `${stats.system_stats_vga_value.toFixed(4)}`,
                  // "Placeholder 1": `${stats.system_stats_placeholder1.toFixed(4)}`,
                  // "Placeholder 2": `${stats.system_stats_placeholder2.toFixed(4)}`,
                  // "Placeholder 3": `${stats.system_stats_placeholder3.toFixed(4)}`,
                  // "Placeholder 4": `${stats.system_stats_placeholder4.toFixed(4)}`,
                  // "Placeholder 5": `${stats.system_stats_placeholder5.toFixed(4)}`,
                }}
              />

            </div>
          </ExpansionPanelDetails>
        ) : null}
      </ExpansionPanel>
      ): null}

    </div>
  );
}
