import HTTPRequestHandler from "../../../utils/HTTPRequestHandler";
const httpRequestHandler = new HTTPRequestHandler();
const user_id = localStorage.getItem("user_id");
const user_name = localStorage.getItem("username");
const user_suffix = "?user_id=" + user_id + "&user_name=" + user_name;
const device_url = "frontend/devices/?user_id=" + user_id + "&user_name=" + user_name


export function fetchBRHDevicesData(url=device_url) {
  return function(dispatch) {
    httpRequestHandler
      .get(url)
      .then(response => {
        dispatch({
          type: "FETCH_BRHDEVICES_DATA_SUCCESS",
          payload: response.data
        });
      })
      .catch(error => {
        dispatch({
          type: "FETCH_BRHDEVICES_DATA_FAILURE",
          error
        });
      });
  };
}

export function firmwaresList(model_no) {
  return function(dispatch) {
    httpRequestHandler
      .get("frontend/firmwares/?model_no=" + model_no)
      .then(response => {
        dispatch({
          type: "FETCH_FIRMWARE_GET_DATA_SUCCESS",
          payload: response.data
        });
      })
      .catch(error => console.error(error.response));
  };
}

export function callShutdownAPI(deviceId, data, successCB, errorCB) {
  httpRequestHandler
    .post('frontend/devices/' + deviceId + '/shutdown/'+ user_suffix, data)
    .then(response => {
      if(successCB) {
        successCB(response.data);
      }
    })
    .catch(error => {
      if(errorCB) {
        errorCB(error.response);
      } else {
        console.error(error.response);
      }
    });
}

export function requestSnapshotAPI(deviceId, data, successCB, errorCB) {
  httpRequestHandler
    .post('frontend/devices/' + deviceId + '/snapshot/'+ user_suffix, data)
    .then(response => successCB(response.data))
    .catch(error => errorCB(error.response));
}

export function deleteSnapshotAPI(snapshotId, successCB, errorCB) {
  httpRequestHandler
    .delete(
      `frontend/snapshots/${snapshotId}/`+user_suffix
    )
    .then(response => successCB(response))
    .catch(error => errorCB(error));
}

export function fetchSnapshotsAPI(deviceId) {
  return function(dispatch) {
    httpRequestHandler
      .get(`frontend/snapshots/?device=${deviceId}&user_id=${user_id}&user_name=${user_name}`)
      .then(response => {
        dispatch({
          type: "FETCH_SNAPSHOTS_DATA_SUCCESS",
          payload: response.data
        });
      })
      .catch(error => {
        dispatch({
          type: "FETCH_SNAPSHOTS_DATA_FAILURE",
          error
        });
      });
  };
}

export function callRebootAPI(deviceId, data, successCB, errorCB) {
  httpRequestHandler
    .post('frontend/devices/' + deviceId + '/reboot/'+ user_suffix, data)
    .then(response => {
      if(successCB) {
        successCB(response.data);
      }
    })
    .catch(error => {
      if(errorCB) {
        errorCB(error.response);
      } else {
        console.error(error.response);
      }
    });
}

export function upgradeDevice(
  upgradeData,
  deviceId,
  successCallback,
  errorCallback
) {
  httpRequestHandler
    .post("frontend/devices/" + deviceId + "/upgrade/" + user_suffix, upgradeData)
    .then((response) => successCallback(response.data))
    .catch((error) => errorCallback(error.response));
}

export function upgradeManyDevicesAPI(upgradeData, successCB, errorCB) {
  httpRequestHandler
    .post("frontend/devices/upgrade_many/"+user_suffix, upgradeData)
    .then((response) => successCB(response))
    .catch((error) => errorCB(error));
}

export function fetchUpgradeTaskStatus(
  taskId,
  deviceId,
  successCallback,
  errorCallback
) {
    httpRequestHandler
      .get("frontend/devices/" + deviceId + "/task/?task_id=" + taskId)
      .then(response => {
        if (response.data.task_status !== "PENDING") {
          successCallback(response.data);
        }
      })
      .catch(error => errorCallback(error));
}

export function getDeviceStatistics(deviceId, successCallback, errorCallback) {
  return function(dispatch) {
    httpRequestHandler
      .get("frontend/devices/" + deviceId + "/stats/")
      .then(response => {
        dispatch({
          type: "FETCH_DEVICESTATS_GET_DATA_SUCCESS",
          payload: response.data
        });
        if(successCallback) {
          successCallback();
        }
      })
      .catch(error => {
        if(errorCallback) {
          errorCallback();
        }
      });
  };
}
export function getHistoricalDeviceStatistics(
  deviceId,
  fromDate,
  tillDate,
  successCallback,
  errorCallback,
  historical=false
) {
  let url =
    "frontend/devices/" +
    deviceId +
    "/stats/?stats_after="+fromDate+"&stats_before="+tillDate;

  if (historical) {
    url += '&historical=1';
  }

  return function(dispatch) {
    httpRequestHandler
      .get(url)
      .then(response => {
        dispatch({
          type: "FETCH_HISTORICAL_DEVICESTATS_GET_DATA_SUCCESS",
          payload: response.data
        });
        if(successCallback) {
          successCallback();
        }
      })
      .catch(error => {
        if(errorCallback) {
          errorCallback(error);
        }
      });
  }
}

export function doRuntimeConfigUpdate(
    deviceId,
    cfgdata,
    successCB,
    errorCB
    ) {
    let url = "frontend/devices/" + deviceId + "/runtimecfg/?user_name=" + user_name;
    httpRequestHandler
      .post(url, cfgdata)
      .then(response => {
        if (successCB) {
          successCB(response);
        }
      })
      .catch( error => {

        if(errorCB) {
          errorCB(error);
        }
        console.error(error);

      });
}
