import { API_URL } from './constants';
import {store} from '../App'

const axios = require('axios');

// const {
//   REQUEST_TIME_OUT,
//   CACHE_MAX_AGE
// } = require('../../constants');

const REQUEST_TIME_OUT = 60000;

export const BASE_URL = API_URL;
var base_url = API_URL;

  const requestInterceptor = (config)=>{
   if (config.url.includes('stats_after') ||
       config.url.includes('csv') ||
       config.url.includes('loadmore') ||
       config.method !== 'get'
      ){
    store.dispatch({type:'SHOW_LOADER', data: true})
   }
   return config
  }

  const responseInterceptor = (response)=>{
   if (response.config.url.includes('stats_after') ||
       response.config.url.includes('csv') ||
       response.config.url.includes('loadmore') ||
       response.config.method !== 'get'
      ){
    store.dispatch({type:'SHOW_LOADER', data: false})
   }
   return response
  }

  const errorInterceptor = (error)=>{
   store.dispatch({type:'SHOW_LOADER', data: false})
   return Promise.reject(error);
  }

class HTTPRequestHandler {
  constructor() {
    const instance = axios.create({
      baseURL: base_url,
      timeout: REQUEST_TIME_OUT
    });
    instance.defaults.xsrfHeaderName = "X-CSRFToken";
    instance.defaults.xsrfCookieName = "csrftoken";
    instance.defaults.withCredentials = true
    instance.interceptors.request.use(requestInterceptor);
    instance.interceptors.response.use(responseInterceptor, errorInterceptor);
    this.instance = instance;
    // this.memoizedGet = cacheMe(this.get, CACHE_MAX_AGE, 'GETAPI');
  }

  getSecuredRequest(headers) {
    const instance = axios.create({
      baseURL: '/feapi',
      headers: { 'x-api-key': (headers && headers.apiKey)
                              ? headers.apiKey
                              : process.env.SG_SERVICES_API_KEY },
      timeout: REQUEST_TIME_OUT
    });
    // instance.interceptors.response.use(this.handleSuccess, this.handleError);
    this.instance = instance;
    return this;
  }

  handleSuccess(response) {
    return Promise.resolve(response);
  }

  handleError(error) {
    switch (error) {
      case 401: //Unauthorized
        break;
      case 404: //Host unreachable
        break;
      case 502: //Bad Gateway
        break;
      case 500: // Service Failure
        break;
      case 503: //Service Unavailable
        break;
      default:
        break;
    }
    return Promise.reject(error);
  }
  // getFromCache(path) {
  //   return this.memoizedGet(path);
  // }

  get(path) {
    return new Promise((resolve, reject) => {
        this.instance
        .get(path)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  post(path, body, config) {
    return new Promise((resolve, reject) => {
      this.instance
        .post(path, body, config || {
          headers: {
              'Content-Type': 'application/json',
          },
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  delete(path, body) {
    return new Promise((resolve, reject) => {
      this.instance
        .delete(path, body,{
          headers: {
              'Content-Type': 'application/json',
          }
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  patch(path, body) {
    return new Promise((resolve, reject) => {
      this.instance
        .patch(path, body)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  put(path, body) {
    return new Promise((resolve, reject) => {
      this.instance
        .put(path, body)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}


export default HTTPRequestHandler;
