import React, { Component } from 'react';
import validator from 'validator';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import UserForm from './UserForm';
import { toast } from 'react-toastify';
import ContentWrapper from './ContentWrapper';
import Switch from '@material-ui/core/Switch';
import AlertDialogSlide  from "../../common/components/AlertDialog";
import { SpecialCharsRegEx } from '../constants/constants';
import { ExcludeSpecialCharsRegEx } from '../constants/constants';
import lodash from 'lodash';
import { TableContainer } from '@material-ui/core';


function Transition(props) {
  return <Slide direction="up" {...props} />;
}

export default class UserComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      newUserForm: false,
      editUserForm: false,
      usersInfo: [],
      helperTextPassword: null,
      helperTextUsername: null,
      helperTextEmail: null,
      helperTextPhone: null,
      helperTextAddress: null,
      canSubmit: false,
      deleteUserRow: '',
      showPassword: false,
      validPassword: false,
      validPhone: false,
      validEmail: false,
      validUsername: false,
      validAddress: false,
      newUser: {
        name: '',
        address_1: null,
        username: null,
        password: "",
        email: "",
        telephone: "",
        address_state: "Karnataka",
        address_zipcode: "560001",
        address_country: "India",
        organization: localStorage.getItem("organization")
      },

      existingUser: {
        name: "",
        address_1: "",
        username: "",
        password: "",
        email: "",
        telephone: "",
        address_state: "Karnataka",
        address_zipcode: "560001",
        address_country: "India",
        organization: localStorage.getItem("organization")
      },
      editedFields: {},

      // For validation
      apiToFormFieldIDs: {
        name:       {key: "user-name",      editable: true, },
        username:   {key: "user-username",  editable: false,},
        password: {
          key: "user-password", editable: false,
          customValidator: value =>
            this.onUserPasswordChangeError()
        },
        telephone: {
          key: "user-telephone", editable: true,
          customValidator: value => {
            let val = value.replace(/[+() -]/g, '')
            return (val.length <= 14 ?
              { text: '', error: false } : { text: 'Invalid Phone Number', error: true });

          }
        },
        email: {
          key: "user-email", editable: true,
          customValidator: value =>
            validator.isEmail(value) ?
              { text: '', error: false } : { text: 'Invalid Email ID', error: true }
        },
        address_1: {
          key: "user-address_1", editable: true,
          customValidator: value =>
            value.length < 100 ?
              { text: '', error: false } : { text: 'Address must be less than 100', error: true }
        },
      },
      validateFormErrors: {},
      apiResponseErrors: {}
    }

    this.getContentView = this.getContentView.bind(this);
    this.onUserNameChange = this.onUserNameChange.bind(this);
    this.onUserUserNameChange = this.onUserUserNameChange.bind(this);
    this.onUserPasswordChange = this.onUserPasswordChange.bind(this);
    this.onUserEmailIdChange = this.onUserEmailIdChange.bind(this);
    this.onUserAddress1Change = this.onUserAddress1Change.bind(this);
    this.addNewUser = this.addNewUser.bind(this);
    this.editUserFormDialog = this.editUserFormDialog.bind(this);
    this.editExistingUser = this.editExistingUser.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.onUserTelephoneChange = this.onUserTelephoneChange.bind(this);
    this.onPhoneValCheck = this.onPhoneValCheck.bind(this);
    this.onEmailValCheck = this.onEmailValCheck.bind(this);
    this.isFormValid = this.isFormValid.bind(this);
    this.onAddressCheck = this.onAddressCheck.bind(this);
    this.onUserValid= this.onUserValid.bind(this);
    this.deleteUserTableRow = this.deleteUserTableRow.bind(this)
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    this.onUserPasswordChangeError = this.onUserPasswordChangeError.bind(this);
    this.isFieldEmpty = this.isFieldEmpty.bind(this);
    this.addressOne = this.addressOne.bind(this);

  }

  addressOne(val) {
      return val.substr(0,30)
  }

  validateNotEmpty(value) {
    return (value && (value.length > 0));
  }

  validateFormPreSubmit = () => {

    let obj = (this.state.editUserForm)?
        this.state.existingUser : this.state.newUser;
    let errorCount = 0;
    let validateFormErrors = {};

    // Non Empty validations first
    for (let prop in this.state.apiToFormFieldIDs) {

      if (this.state.editUserForm &&
         !this.state.apiToFormFieldIDs[prop].editable) {
        continue;
      }

      let fieldval = obj[prop];

      let result = this.validateNotEmpty(fieldval);
      if (!result) {
        validateFormErrors[prop] = {
          text: "This field should be Non-Empty",
          error: true
        };

        errorCount += 1;
      }
      else if (this.state.apiToFormFieldIDs[prop].customValidator) {

      let fn = this.state.apiToFormFieldIDs[prop].customValidator;
      let result = fn(fieldval);

      if (result.error ) {
        validateFormErrors[prop] = result;
        errorCount += 1;
      }
      }
    }
    if (errorCount > 0) {
      this.setState({...this.state,
        validateFormErrors: validateFormErrors});
      return false;
    }

    // catchall false for now
    return true;
  }

  isFormFieldInvalid = (field) => {

    var err = this.state.validateFormErrors[field];

    if (err) {
      return { helperText: err.text, error: err.error};
    }

    return null;
  }

  displayAPIErrorstoFormFields = errorResponseMessages => {

    let validateFormErrors = {};
    let errorCount = 0;

    for (let APIKey in errorResponseMessages) {
      if (APIKey === 'message') {
        continue;
      }
      let APIErrorMessage = errorResponseMessages[APIKey].join(' ');
      validateFormErrors[APIKey] = {text: APIErrorMessage, error:true}
      errorCount += 1;
      if (errorCount > 0) {
        this.setState({
        validateFormErrors: validateFormErrors
      })
      }
    }
}

  componentDidMount() {
    this.props.changeSidebarButtonState(
      this.props.userRole,
      this.props.userState
        );
    this.props.fetchUsersData(this.props.userRole);
  }

  isFieldEmpty(value) {
    if (value) {
      return false
    }
    return true
  }

  isFormValid = () => {

    let enableNewSubmit = Boolean(
      this.state.newUser.name &&
      this.state.validUsername &&
      this.state.validPassword &&
      this.state.validPhone &&
      this.state.validEmail &&
      this.state.validAddress
    );

    let enableEditSubmit = Boolean(
      this.state.validUsername &&
      this.state.validPhone &&
      this.state.validEmail &&
      this.state.validAddress
    );

    this.setState({ canSubmit: (enableNewSubmit || enableEditSubmit) });
  }

  onUserNameChange(event) {
    if (this.state.editUserForm) {
      this.setState(
        {
          ...this.state,
          existingUser: {
            ...this.state.existingUser,
            name: event.target.value
          },
          editedFields: {
            ...this.state.editedFields,
            name: event.target.value
          }
        },
        () => {
          this.isFormValid();
        }
      )
    }
    else {
      this.setState(
        {
          ...this.state,
          newUser: {
            ...this.state.newUser,
            name: event.target.value,
          }
        },
        () => {
          this.isFormValid();
        }
      )
    }
  }

  onUserUserNameChange(event) {
    if (this.state.editUserForm) {
      this.setState(
        {
          ...this.state,
          validUsername: true,
          helperTextUsername: '',
          existingUser: {
            ...this.state.existingUser,
            username: event.target.value
          }
        }
      )
    }
    else {
      this.setState(
        {
          ...this.state,
          validUsername: true,
          helperTextUsername: '',
          newUser: {
            ...this.state.newUser,
            username: event.target.value
          }
        },
        () => {
          this.isFormValid();
        }
      )
    }
  }

  onUserPasswordChangeError() {

    var hasUpperCase = /[A-Z]/.test(this.state.newUser.password);
    var hasLowerCase = /[a-z]/.test(this.state.newUser.password);
    var hasNumbers = /\d/.test(this.state.newUser.password);
    var hasSpecialChars = SpecialCharsRegEx.test(this.state.newUser.password);
    var excludeSpecialChars = ExcludeSpecialCharsRegEx.test(this.state.newUser.password);

    if (!hasUpperCase || !hasLowerCase || !hasNumbers || !hasSpecialChars || excludeSpecialChars || this.state.newUser.password.length < 6) {
      let text = "needs min 6 chars, 1 lowercase, uppercase, special character(!,@,#,$,%,^,&,*,(,),-,+), number."

      this.setState(
        {
          ...this.state.newUser,
          helperTextPassword:
          "needs min 6 chars, 1 lowercase, uppercase, special character(!,@,#,$,%,^,&,*,(,),-,+), number.",
          validPassword: false,
        },
        () => {
          this.isFormValid();
        }
      )
      return {
        text,
        error: true,
      }

    } else {
      this.setState(
        {
          ...this.state.newUser,
          helperTextPassword: '',
          validPassword: true,
        },
        () => {
          this.isFormValid();
        }
      )
      return {text:'', error:false}
    }
  }

  onUserPasswordChange(event) {
    if (this.state.editUserForm) {
      this.setState(
        {
          ...this.state,
          existingUser: {
            ...this.state.existingUser,
            password: event.target.value
          }
        }
      )
    }
    else {
      this.setState(
        {
          ...this.state,
          newUser: {
            ...this.state.newUser,
            password: event.target.value,
          }
        }
      )
    }
  }

  onEmailValCheck(event) {
    var emailValCheck = validator.isEmail(event.target.value);
    if (!emailValCheck) {
      this.setState(
        {
          ...this.state,
          helperTextEmail: 'Enter valid Email Id',
          validEmail: false,
        },
        () => {
          this.isFormValid();
        }
      )
    } else {
      this.setState(
        {
          ...this.state,
          helperTextEmail: '',
          validEmail: true,
        },
        () => {
          this.isFormValid();
        }
      )
    }
  }

  onPhoneValCheck(event) {
    var phoneValCheck = validator.isNumeric(event.target.value);
    if (!phoneValCheck) {
      this.setState(
        {
          ...this.state,
          helperTextPhone: 'Enter valid Contact Number',
          validPhone: false,
        },
        () => {
          this.isFormValid();
        }
      )
    }
    else {
      this.setState(
        {
          ...this.state,
          helperTextPhone: '',
          validPhone: true,
        },
        () => {
          this.isFormValid();
        }
      )
    }
  }


  onUserTelephoneChange(value) {
    if (this.state.editUserForm) {
      this.setState(
        {
          ...this.state,
          editedFields: {
            ...this.state.editedFields,
            telephone: value
          },
          existingUser: {
            ...this.state.existingUser,
            telephone: value
          },
        }
      )
    }
    else {
      this.setState(
        {
          ...this.state,
          newUser: {
            ...this.state.newUser,
            telephone: value
          }
        }
      )
    }
  }

  onUserValid(event) {
    this.setState(
      {
        ...this.state,
       validUsername: true,
       helperTextUsername: '',
      },
      () => {
        this.isFormValid();
      }
    )
  }
  onAddressCheck(event) {
    this.setState(
      {
        ...this.state,
       validAddress: true,
       helperTextAddress: '',
      },
      () => {
        this.isFormValid();
      }
    )
  }

  onUserEmailIdChange(event) {
    if (this.state.editUserForm) {
      this.setState(
        {
          ...this.state,
          editedFields: {
            ...this.state.editedFields,
            email: event.target.value
          },
          existingUser: {
            ...this.state.existingUser,
            email: event.target.value
          }
        }
      )
    }
    else {
      this.setState(
        {
          ...this.state,
          newUser: {
            ...this.state.newUser,
            email: event.target.value
          }
        }
      )
    }
  }

  onUserAddress1Change(event) {
    if (this.state.editUserForm) {
      this.setState(
        {
          ...this.state,
          validAddress: true,
          helperTextAddress:"",
          editedFields: {
            ...this.state.editedFields,
            address_1: event.target.value
          },
          existingUser: {
            ...this.state.existingUser,
            address_1: event.target.value
          }
        },
        () => {
          this.isFormValid();
        }
      )
    }
    else {

      this.setState(
        {
          ...this.state,
          validAddress: true,
          helperTextAddress:"",
          newUser: {
            ...this.state.newUser,
            address_1: event.target.value
          },
        },
        () => {
          this.isFormValid();
        }
      )
    }
  }

  addNewUser() {

    if (!this.validateFormPreSubmit()) {
      return
    }

    this.props.addUser(this.state.newUser,

      (successResponse) => {
        toast("User Added Successfully", {
          autoClose: 5000,
          type: toast.TYPE.SUCCESS,
          position: toast.POSITION.BOTTOM_CENTER,
        });
        this.setState({
          ...this.state,
          open: false,
          editUserForm: false,
          newUser: {
            ...this.state.newUser,
            name: "",
            address_1: "",
            username: "",
            password: "",
            email: "",
            telephone: "",
          }
        }, () => {
          this.props.fetchUsersData(this.props.userRole);
        });
      },
      (errorResponse) => {
        this.displayAPIErrorstoFormFields(errorResponse.response.data)
        this.setState(
          {
            ...this.state,
            helperTextUsername:'',
            helperTextAddress:'',
            helperTextEmail:''

          })
      if(errorResponse.response.status === 400 ) {
        if(errorResponse.response.data.username) {
          var validUsername=errorResponse.response.data.username;
          this.setState(
            {
              ...this.state,
              helperTextUsername:validUsername.join(", "),
              validUsername: false,
            },
            () => {
              this.isFormValid();
            }
          )
        }
        if(errorResponse.response.data.email) {
          var validEmail=errorResponse.response.data.email;
          this.setState(
            {
              ...this.state,
              helperTextEmail:validEmail.join(", "),
              validEmail: false,
            },
            () => {
              this.isFormValid();
            }
          )
        }
        if(errorResponse.response.data.address_1) {
          var validAddress=errorResponse.response.data.address_1;
          this.setState(
            {
              ...this.state,
              helperTextAddress:validAddress.join(", "),
              validEmail: false,
            },
            () => {
              this.isFormValid();
            }
          )
          }
      }
        toast("User Adding Failed", {
          autoClose: 5000,
          type: toast.TYPE.ERROR,
          position: toast.POSITION.TOP_CENTER,
        });
      }
    );
  }

  editExistingUser() {

    if (!this.validateFormPreSubmit()) {
      return
    }

    if (lodash.isEmpty(this.state.editedFields)) {
      this.setState({
        ...this.state,
        open: false,
        editUserForm: false
      });
      return;
    }


    this.props.editUser(
      this.state.existingUser.id,
      this.state.editedFields,

      successResponse => {
        this.setState({
          ...this.state,
          open: false,
          editUserForm: false
        }, () => {
          this.props.fetchUsersData(this.props.userRole);
        });

        toast("User Updated Successfully", {
          autoClose: 5000,
          type: toast.TYPE.SUCCESS,
          position: toast.POSITION.BOTTOM_CENTER,
        });

      },

      errorResponse => {
        this.displayAPIErrorstoFormFields(errorResponse.response.data);
        toast("User Update Failed. Error: "+ errorResponse.response.data['message'], {
          autoClose: 5000,
          type: toast.TYPE.ERROR,
          position: toast.POSITION.TOP_CENTER,
        });

      }
    );
  }

  handleClickOpen = () => {
    this.setState({ open: true, newUserForm: true },
      this.isFormValid()
    );
  };

  editUserFormDialog = (existingUser) => {
    this.setState({
      ...this.state,
      editUserForm: true,
      open: true,
      validPhone: true,
      validEmail: true,
      validUsername: true,
      validAddress: true,
      existingUser
    },
      () => {
        this.isFormValid();
      }
    );
  };

  handleClose = () => {
    this.setState({
      ...this.state,
      open: false,
      canSubmit: false,
      validEmail: false,
      validPassword: false,
      validPhone: false,
      validUsername: false,
      validAddress: false,
      newUserForm: false,
      editUserForm: false,
      helperTextPassword: '',
      helperTextPhone: '',
      helperTextEmail: '',
      helperTextAddress: '',
      helperTextUsername: '',
      newUser: {
        ...this.state.newUser,
        name: "",
        address_1: "",
        username: "",
        password: "",
        email: "",
        telephone: "",
      },
      existingUser: {
        ...this.state.existingUser,
        name: "",
        address_1: "",
        email: "",
        telephone: "",
      },
      validateFormErrors: {},
      apiResponseErrors: {}

    });
  };

  handleClickShowPassword = () => {
    this.setState({
      type: this.state.type === 'password' ? 'text' : 'password',
    })
  };

  openDeleteAlert = (row) => {
    this.setState({
      openAlert: true,
      username: row.username,
      userID: row.id
    })
  }

  closeDeleteAlert = () => {
    this.setState({
      openAlert: false,
      username: null,
      userID: null
    })
  }

  deleteUserTableRow(userID) {
      this.props.deleteUserIdData(userID,
        (successResponse) => {
          toast("User Deleted Successfully", {
            autoClose: 5000,
            type: toast.TYPE.SUCCESS,
            position: toast.POSITION.BOTTOM_CENTER,
          });
        this.props.fetchUsersData(this.props.userRole);
        },
        (errorResponse) => {
          toast('User might be associated with some device, Kindly contact Administrator', {
            autoClose: 5000,
            type: toast.TYPE.ERROR,
            position: toast.POSITION.TOP_CENTER,
          });
        });
      this.closeDeleteAlert();
  }

  toggleUserLogin = (userID) => {

    this.props.toggleUserLogin(userID,
      successResponse => {
        this.props.fetchUsersData(this.props.userRole)
        let loginState = successResponse['is_active'] ? "Enabled" : "Disabled";
        toast("User Login " + loginState, {
        autoClose: 5000,
        type: toast.TYPE.SUCCESS,
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
    );
  }

	canCurrentUserEdit = (userType) => {
		let role = localStorage.getItem("roleKey");
		return (role == "owner" || userType == "Operator")
	}

  getContentView() {
    const users = this.props.usersInfo;
    let currentUser = localStorage.getItem("username");
    let role = localStorage.getItem("roleKey");

    return (
      <div className="content-wrapper">
        <div className="content">
          <div>
            <div className="row main_top_headding">
              <div className="col-md-4 col-sm-4 col-xs-0"></div>
              <div className="col-md-4 col-sm-4 col-xs-6 main_top_headding_mid">
                <h4> <i className="fa fa-user" aria-hidden="true"></i> &nbsp; {this.props.userType}s</h4>
              </div>
              <div className="col-md-4 col-sm-4 col-xs-6 main_top_headding_right">
							{(role === "owner" || this.props.userType === "Operator") ?
                <button
                  type="button"
                  className="btn btn-outline-secondary pull-right"
                  onClick={this.handleClickOpen}
                >
                  <span className="glyphicon glyphicon-plus"></span>
                  &nbsp; <strong>
                  Add {this.props.userType}
                  </strong>
                </button>
								:null}
              </div>
            </div>{/*<!-- row ---> */}
            <UserForm
              open={this.state.open}
              handleClose={this.handleClose}
              Transition={Transition}
              editUserForm={this.state.editUserForm}
              existingUser={this.state.existingUser}
              newUser={this.state.newUser}
              isFormValid={this.isFormValid}
              onUserNameChange={this.onUserNameChange}
              onUserUserNameChange={this.onUserUserNameChange}
              onUserPasswordChangeError={this.onUserPasswordChangeError}
              onUserPasswordChange={this.onUserPasswordChange}
              helperTextPassword={this.state.helperTextPassword}
              handleClickShowPassword={this.handleClickShowPassword}
              type={this.state.type}
              helperTextPhone={this.state.helperTextPhone}
              onUserTelephoneChange={this.onUserTelephoneChange}
              onPhoneValCheck={this.onPhoneValCheck}
              helperTextEmail={this.state.helperTextEmail}
              helperTextUsername={this.state.helperTextUsername}
              helperTextAddress={this.state.helperTextAddress}
              onUserEmailIdChange={this.onUserEmailIdChange}
              onEmailValCheck={this.onEmailValCheck}
              onUserAddress1Change={this.onUserAddress1Change}
              editExistingUser={this.editExistingUser}
              addNewUser={this.addNewUser}
              canSubmit={this.state.canSubmit}
              user={this.props.userType}
              isFormFieldInvalid={this.isFormFieldInvalid}
              role={role}

            />

            <div className="row common_div_row">
              <Paper>
                <TableContainer className="table-all  table-responsive">
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell align="left" className="text-wrap-head">Full Name</TableCell>
                        <TableCell align="left" className="text-wrap-head">User Name</TableCell>
                        <TableCell align="center" className="text-wrap-head">Contact No.</TableCell>
                        <TableCell align="left" className="text-wrap-head">Email ID</TableCell>
                        <TableCell align="left" className="text-wrap-head">Address</TableCell>
                        <TableCell align="center" className="text-wrap-head">
													{ this.canCurrentUserEdit(this.props.userType) ? 'Edit' : 'View' } Profile
                        </TableCell>
                        { this.canCurrentUserEdit(this.props.userType) ?
													<TableCell align="center" className="text-wrap-head">Delete</TableCell>
													:null}
                        { this.canCurrentUserEdit(this.props.userType) ?
													<TableCell align="center" className="text-wrap-head">Login</TableCell>
													: null}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {users.length > 0 && users.map(row => (
                        <TableRow key={row.id}>
                          <TableCell align="left" className="text-wrap"> {row.name}  </TableCell>
                          <TableCell align="left" className="text-wrap">{row.username === currentUser ?
                            row.username + " (you)" : row.username} </TableCell>
                          <TableCell align="center" className="text-wrap">{row.telephone}  </TableCell>
                          <TableCell align="left" className="text-wrap">{row.email}</TableCell>
                          <TableCell align="left" className="text-wrap">{row.address_1}</TableCell>
                          <TableCell align="center">
                            <button
                              type="button"
                              className="btn btn-outline-secondary"
                              onClick={() => {
                                this.editUserFormDialog(row);
                              }}
                            >
                            {this.canCurrentUserEdit(this.props.userType) ?
                            <span className="glyphicon glyphicon-edit"></span>
                            :
                            <span className="glyphicon glyphicon-user"></span>
                            }
                            </button>
                          </TableCell>
													{this.canCurrentUserEdit(this.props.userType) ? (
														<TableCell align="center">
															<button
																type="button"
																className="btn btn-outline-secondary"
																onClick={() => {
																	this.openDeleteAlert(row);
																}}
															>
																<span className="glyphicon glyphicon-trash"></span>
															</button>
														</TableCell>
														): null}
													{this.canCurrentUserEdit(this.props.userType) ? (
														<TableCell align="center">
                              <Switch
                                checked={row.is_active}
                                disabled={row.username === currentUser}
                                onClick={() => {this.toggleUserLogin(row.id)}}
                                color="primary"
															/>
														</TableCell>
                            ) : null}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </div>
            <AlertDialogSlide
              open={this.state.openAlert}
              handleClose={this.closeDeleteAlert}
              title={"Delete User " + this.state.username + "?" }
              desc={"are you sure you want to delete the user "+this.state.username+"?"}
              action={()=>this.deleteUserTableRow(this.state.userID)}
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <ContentWrapper
        contentView={this.getContentView()} netError={this.props.netError}
      />
    );
  }
}
