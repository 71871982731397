import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";

class SearchComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValid: true,
      searchValue: "",
      defaultSearchString: props.defaultSearchString,
      helperTextSearch: ""
    };
    this.searchTermFunc = this.searchTermFunc.bind(this);
    this.onBlurInput = this.onBlurInput.bind(this);
    this.doValidateSearchString = this.doValidateSearchString.bind(this);

    if (this.state.defaultSearchString) {
      let inputResponse = this.props.isValidInput(
        this.state.defaultSearchString
      );
      this.state.helperTextSearch = inputResponse.errorMessage;
      this.state.inputValid = inputResponse.inputValid;
      this.state.searchValue = inputResponse.searchValue;
    }
  }

  componentDidMount() {
    //  This call is required for BRHDevice Show Notifications
    if (this.state.inputValid && this.state.searchValue) {
      this.props.setFilterURL(this.props.url + this.state.searchValue);
      this.props.searchAPICall(this.props.url + this.state.searchValue);
    }
  }

  doValidateSearchString(searchString, keyCode) {
    let inputResponse = this.props.isValidInput(searchString);
    this.setState(
      {
        helperTextSearch: inputResponse.errorMessage,
        inputValid: inputResponse.inputValid,
        searchValue: inputResponse.searchValue
      },
      () => {
        if (keyCode === 13) this.searchTermFunc();
      }
    );
  }

  onBlurInput(event) {
    this.doValidateSearchString(event.target.value, event.keyCode);
  }

  searchTermFunc() {
    if (this.state.inputValid) {
      if (this.props.setFilterURL)
        this.props.setFilterURL(this.props.url + this.state.searchValue);
      this.props.searchAPICall(this.props.url + this.state.searchValue);
    }
  }

  onKeyPress = e => {
    if (e.keyCode === 27) this.clearSearchBox();
    else if (e.keyCode === 13) this.onBlurInput(e);
  };

  clearSearchBox = () => {
    this.setState({
      searchValue: "",
      helperTextSearch: "",
      defaultSearchString: ""
    });
    if (this.props.setFilterURL) this.props.setFilterURL(null);
  };

  render() {
    return (
      <div>
        <Paper className="tip-tool">
          <TextField
            autoComplete="off"
            placeholder={this.props.placeHolder}
            value={this.state.defaultSearchString}
            onChange={e => {
              this.setState({
                defaultSearchString: e.target.value
              });
            }}
            defaultValue={this.state.defaultSearchString}
            className="searchClass"
            variant="outlined"
            onBlur={this.onBlurInput}
            helperText={this.state.helperTextSearch}
            onKeyUp={this.onKeyPress}
            fullWidth
          />
          <IconButton
            aria-label="Search"
            className="searchIcon"
            onClick={this.searchTermFunc}
          >
            <SearchIcon />
          </IconButton>

        </Paper>
      </div>
    );
  }
}

export default SearchComponent;
