import HTTPRequestHandler from "../../utils/HTTPRequestHandler";
import { toast } from "react-toastify";

const httpRequestHandler = new HTTPRequestHandler();

export function signOutFunction() {
  const id = localStorage.getItem("user_id");
  const user_name = localStorage.getItem("username");
  const url_suffix = "?user_id=" + id + "&user_name=" + user_name;
  // First clear local session.
  localStorage.clear();

  // Make logout request and redirect to signin page
  const url = "logout/" + url_suffix;
  httpRequestHandler
    .post(url)
    .then(response => {})
    .catch(error => {
      if (!error.response) {
        let errorMessage = "Network Error: unable to reach server";
        toast(errorMessage, {
          autoClose: 5000,
          type: toast.TYPE.ERROR,
          position: toast.POSITION.TOP_CENTER
        });
      }
    });
  window.location.href = "/signin";
}
