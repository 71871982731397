import React, { Component } from 'react';
import {COPYRIGHT} from '../constants/constants.js';

class Footer extends Component {

  getSWVersion() {
    return process.env.REACT_APP_SW_VERSION || "dev";

  }

  render() {
    return (
        <div className="footer-container">
          <footer>{COPYRIGHT}</footer>
        </div>
    )
  }
}

export default Footer;
