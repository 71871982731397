import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import AppBarComponent from "../../common/components/AppBarComponent";
import MuiPhoneInput from 'material-ui-phone-number'

let UserForm = props => {
  let fieldError = field => {
    var f = props.isFormFieldInvalid(field);
    if (f) {
      return f.error;
    } else {
      return false;
    }
  };

  let fieldHelperText = field => {
    var f = props.isFormFieldInvalid(field);
    if (f) {
      return f.helperText;
    } else {
      return "";
    }
  };

  return (
    <div className="row main_popup">
      <Dialog
        fullScreen
        open={props.open}
        onClose={props.handleClose}
        TransitionComponent={props.Transition}
        className="popup-module"
        disableBackdropClick
      >
        <AppBarComponent
          icon={"fa fa-user"}
          title={props.user}
          handleClose={props.handleClose}
        />

        <DialogContent className="dialogContent">
          <div className="col-md-4 col-sm-0"></div>
          <div className="col-md-4 col-sm-12">
            <form className="dialogContent-form">
              <TextField
                required
                id="user-name"
                label="Full Name"
                style={{ marginBottom: 8 }}
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  shrink: true
                }}
                value={
                  props.editUserForm
                    ? props.existingUser.name
                    : props.newUser.name
                }
                onChange={props.onUserNameChange}
                onBlur={props.isFormValid}
                error={fieldError("name")}
                helperText={fieldHelperText("name")}
              />
              <TextField
                required
                id="user-username"
                label="Username"
                type="text"
                style={{ marginBottom: 8 }}
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  shrink: true
                }}
                value={
                  props.editUserForm
                    ? props.existingUser.username
                    : props.newUser.username
                }
                inputProps={{
                    form: {
                      autocomplete: 'off',
                    },
                }}
                disabled={props.editUserForm}
                onChange={props.onUserUserNameChange}
                onBlur={props.onUserValid}
                error={fieldError("username")}
                helperText={fieldHelperText("username")}
              />

              { !props.editUserForm ?
                <TextField
                  required
                  id="user-password"
                  label="Password"
                  onBlur={props.onUserPasswordChangeError}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true
                  }}
                  type={props.type === "password" ? "text" : "password"}
                  value={
                    props.editUserForm
                      ? props.existingUser.password
                      : props.newUser.password
                  }
                  autoComplete="new-password"
                  onChange={props.onUserPasswordChange}
                  InputProps={{
                    form: {
                      autocomplete: 'off'
                    },
                    endAdornment: (
                      <InputAdornment position="end" className="passwordicon">
                        <IconButton
                          aria-label="Toggle password visibility"
                          onClick={props.handleClickShowPassword}
                          disabled={props.editUserForm}
                        >
                          {props.type === "password" ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  error={fieldError("password")}
                  helperText={fieldHelperText("password")}
                  disabled={props.editUserForm}
                />
              :null }

              <MuiPhoneInput
                defaultCountry={'us'}
                disableDropdown
                required
                id="user-telephone"
                type="text"
                label="Contact No."
                style={{ marginBottom: 8 }}
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  shrink: true
                }}
                value={
                  props.editUserForm
                    ? props.existingUser.telephone
                    : props.newUser.telephone
                }
                onChange={props.onUserTelephoneChange}
                onBlur={props.onPhoneValCheck}
                error={fieldError("telephone")}
                helperText={fieldHelperText("telephone")}
              />

              <TextField
                required
                id="user-email"
                label="Email Id"
                type="text"
                style={{ marginBottom: 8 }}
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  shrink: true
                }}
                value={
                  props.editUserForm
                    ? props.existingUser.email
                    : props.newUser.email
                }
                onChange={props.onUserEmailIdChange}
                onBlur={props.onEmailValCheck}
                error={fieldError("email")}
                helperText={fieldHelperText("email")}
              />
              <TextField
                required
                id="user-address_1"
                label="Address"
                onChange={props.onUserAddress1Change}
                multiline
                rows="4"
                style={{ marginBottom: 8 }}
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  shrink: true
                }}
                value={
                  props.editUserForm
                    ? props.existingUser.address_1
                    : props.newUser.address_1
                }
                onBlur={props.onAddressCheck}
                error={fieldError("address_1")}
                helperText={fieldHelperText("address_1")}
              />
              {(props.role === "owner" || props.user === "Operator") ? (
                <div className="form-button-container">
                <Button
                  variant="outlined"
                  className="form-submit-button"
                  onClick={
                    props.editUserForm
                      ? props.editExistingUser
                      : props.addNewUser
                  }
                >
                  {props.editUserForm
                    ? "Edit " + props.user
                    : "Add " + props.user}
                </Button>
                  <Button
                  variant="outlined"
                  className="form-cancel-button"
                  onClick={props.handleClose}
                  >
                    Cancel
                  </Button>
                </div>
              ) : null}
            </form>
          </div>

          <div className="col-md-4 col-sm-0"></div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default UserForm;
