const initialState = {
  operatorsInfo: [],
  netError: null
};

export default (state = initialState, action) => {
  let currentState = state;
  switch (action.type) {
    case "FETCH_OPERATORS_DATA_SUCCESS":
      currentState = { ...state, operatorsInfo: action.payload, netError: null};
      break;

    case "FETCH_OPERATORS_DATA_FAILURE":
      currentState = { ...state, netError: action.error };
      break;

    default:
  }
  return currentState;
};
