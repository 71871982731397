/**
 * A simple Dialog that selects one of the passed entries and automatically
 * closes itself.
 *
 * Usage: <ListSelectorDialog props={Object} />, where the `props` is directly
 * passed to React Material UI Dialog actions.
 * props : {
 *    open: Boolean: Whether the Dialog is open or not.
 *    onClose: function to be called when the dialog is closed.
 *    selectedValue: Default Selected Value if the dialog is closed without action.
 * }
 *
 */
import React, { Component } from 'react';

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

export default class ListSelectorDialog extends Component {

	handleClose = () => {
		this.props.onClose(this.props.selectedValue);
	}

	handleListItemClick = (value) => {
		this.props.onClose(value);
	}

	render() {
		return (
			<div>
				<Dialog
					onClose={this.handleClose}
					open={this.props.open}
				>
				<DialogTitle>{this.props.title}</DialogTitle>
				<List>
					{this.props.items.map((item) => (
						<ListItem button onClick={ () => this.handleListItemClick(item)} key={item}>
							<ListItemText primary={item}/>
						</ListItem>
					))}
				</List>

				</Dialog>
			</div>
		);
	}

}
