import React from "react";

import { toast } from "react-toastify";

import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";

import AppBarComponent from "../../../../common/components/AppBarComponent";
import validator from "validator";

import { BRHModels } from "../../../../common/constants/config_params";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const InitialStates = {
  helperTextMacId: null,
  newDevice: {
    description: "",
    serial_no: "",
    model_no: BRHModels[0],
    mac_address: "",
    operators: [],
    config: "",
    admin: localStorage.getItem("user_id"),
    transmitter_id: 0,
    transmitter_group_id: 0
  },

  existingDevice: {
    id: "",
    description: "",
    serial_no: "",
    model_no: "",
    mac_address: "",
    operators: "",
    config: "",
    admin: localStorage.getItem("user_id"),
    transmitter_id: 0,
    transmitter_group_id: 0
  },
  editedFields: {},

      // For validation
  apiToFormFieldIDs: {
    description: {
      key: "device-description", editable: true,
      customValidator: value => {
        if (value.length > 100) {
          return {text: 'Description length should be less than 100', error: true};
        }
        let x = value.match(/[^a-zA-Z0-9+\- ()]/)
        if (x) {
          return {text: "Only supported characters are Alphanumeric and '(',')',','+',' '-'.", error: true};
        }
        return {text: '', error:false};
      },
    },
    serial_no: { key: "device-serial-no", editable: false,
      customValidator: value => {
        if (value.length > 32) {
          return {text: 'Serial No length should be less than 32', error: true};
        }
        let x = value.match(/[^a-zA-Z0-9-]/)
        if (x) {
          return {text: "Only supported characters are Alphanumeric and '-'.", error: true};
        }
        return {text: '', error:false};
      },
    },
    model_no: { key: "device-model-no", editable: false,
      /* FIXME : custom validaor */
    },
    mac_address: { key: "device-mac-id", editable: false,
      customValidator: value => {
        if(!validator.isMACAddress(value)) {
          return { text: 'Not a valid MAC Address. Supported format is AA:BB:CC:DD:EE:FF.', error: true}
        } else if (!validator.isUppercase(value)){
          return { text: 'MACID should be in UPPER CASE ONLY', error: true }
        }
	else {
          return { text: '', error: false}
        }
      }
    },
    transmitter_id : { key: 'device-transmitter-id', editable: false,
      customValidator: value => {
        let x = validator.isNumeric(value);
        if (!x) {
          return { text: "Input should be a valid integer between 0-8191", error: true}
        }
        x = parseInt(value)
        if ( x < 0 || x >  8191) {
          return { text: "Input should be a valid integer between 0-8191", error: true}
        }
        return { text: '', error: false}
      }
    },
    transmitter_group_id : { key: 'device-transmitter-group-id', editable: false,
      customValidator: value => {
        let x = validator.isNumeric(value);
        if (!x) {
          return { text: "Input should be a valid integer between 0-127", error: true}
        }
        x = parseInt(value);
        if ( x < 0 || x >  127) {
          return { text: "Input should be a valid integer between 0-127", error: true}
        }
        return { text: '', error: false}
      }
    },
    config : { key: 'device-config', editable: true,
    },
    operators : { key: 'device-operators', editable: true,
    },
  },
  validateFormErrors: {},
  apiResponseErrors: {}
}

export default class DeviceForm extends React.Component {

  state = { ...InitialStates };

  validateNotEmpty(value) {
    return (value && (value.length > 0));
  }

  validateFormPreSubmit = () => {

    let obj = (this.props.showEditDevice)?
        this.state.existingDevice : this.state.newDevice;
    let errorCount = 0;
    let validateFormErrors = {};

    // Non Empty validations first
    for (let prop in this.state.apiToFormFieldIDs) {

      if (this.props.showEditDevice &&
         !this.state.apiToFormFieldIDs[prop].editable) {
        continue;
      }

      let fieldval = obj[prop];

      fieldval = fieldval.toString(); // Just in case fieldval is an integer
      let result = this.validateNotEmpty(fieldval);
      if (!result) {
        validateFormErrors[prop] = {
          text: "This field should be Non-Empty",
          error: true
        };

        errorCount += 1;
      }
      else if (this.state.apiToFormFieldIDs[prop].customValidator) {

        let fn = this.state.apiToFormFieldIDs[prop].customValidator;
        let result = fn(fieldval);

        if (result.error ) {
          validateFormErrors[prop] = result;
          errorCount += 1;
        }
      }
    }

    if (errorCount > 0) {
      this.setState({...this.state,
        validateFormErrors: validateFormErrors});
      return false;
    }

    // catchall false for now
    return true;
  }

  isFormFieldInvalid = (field) => {

    var err = this.state.validateFormErrors[field];

    if (err) {
      return { helperText: err.text, error: err.error};
    }

    return null;
  }

  fieldError = field => {
    var f = this.isFormFieldInvalid(field);
    if (f) {
      return f.error;
    } else {
      return false;
    }
  };

  fieldHelperText = field => {
    var f = this.isFormFieldInvalid(field);
    if (f) {
      return f.helperText;
    } else {
      return "";
    }
  };

  displayAPIErrorstoFormFields = errorResponseMessages => {

    let validateFormErrors = {};
    let errorCount = 0;

    let msg = errorResponseMessages["message"];
    for (let APIKey in msg) {
      if (APIKey === 'message') {
        continue;
      }
      let APIErrorMessage = msg[APIKey][0].message;
      validateFormErrors[APIKey] = {text: APIErrorMessage, error:true}
      errorCount += 1;
      if (errorCount > 0) {
        this.setState({
        validateFormErrors: validateFormErrors
      })
      }
    }

  }

  componentDidMount() {
    if (Object.keys(this.props.existingDevice).length) {
      this.setState({
        existingDevice: { ...this.props.existingDevice }
      });
    }
  }

  onTransmitterIdChange = event => {
    if (this.props.showEditDevice) {
      this.setState({
        ...this.state,
        existingDevice: {
          ...this.state.existingDevice,
          transmitter_id: event.target.value
        }
      });
    } else {
      this.setState({
        ...this.state,
        newDevice: {
          ...this.state.newDevice,
          transmitter_id: event.target.value
        }
      });
    }
  };

  onTransmitterGroupIdChange = event => {
    if (this.props.showEditDevice) {
      this.setState({
        ...this.state,
        existingDevice: {
          ...this.state.existingDevice,
          transmitter_group_id: event.target.value
        }
      });
    } else {
      this.setState({
        ...this.state,
        newDevice: {
          ...this.state.newDevice,
          transmitter_group_id: event.target.value
        }
      });
    }
  };

  onDeviceDescChange = event => {
    if (this.props.showEditDevice) {
      this.setState({
        ...this.state,
        existingDevice: {
          ...this.state.existingDevice,
          description: event.target.value
        },
        editedFields: {
          ...this.state.editedFields,
          description: event.target.value
        }
      });
    } else {
      this.setState({
        ...this.state,
        newDevice: { ...this.state.newDevice, description: event.target.value }
      });
    }
  };

  onDeviceSlNochange = event => {
    if (this.props.showEditDevice) {
      this.setState({
        ...this.state,
        existingDevice: {
          ...this.state.existingDevice,
          serial_no: event.target.value
        }
      });
    } else {
      this.setState({
        ...this.state,
        newDevice: { ...this.state.newDevice, serial_no: event.target.value }
      });
    }
  };

  onModelNochange = event => {
    if (this.props.showEditDevice) {
      this.setState({
        ...this.state,
        existingDevice: {
          ...this.state.existingDevice,
          model_no: event.target.value
        },
        editedFields: {
          ...this.state.editedFields,
          model_no: event.target.value
        }
      });
    } else {
      this.setState({
        ...this.state,
        newDevice: { ...this.state.newDevice, model_no: event.target.value }
      });
    }
  };

  onMacidChange = event => {
    if (this.props.showEditDevice) {
      this.setState({
        ...this.state,
        existingDevice: {
          ...this.state.existingDevice,
          mac_address: event.target.value
        }
      });
    } else {
      this.setState({
        ...this.state,
        newDevice: { ...this.state.newDevice, mac_address: event.target.value }
      });
    }
  };

  validateMacID = () => {
    let helperTextMacId = "Enter valid Mac ID. eg. aa:bb:cc:dd:ee:ff";
    let validMacID = validator.isMACAddress(
      this.props.showEditDevice
        ? this.state.existingDevice.mac_address
        : this.state.newDevice.mac_address
    );
    if (validMacID) {
      helperTextMacId = "";
    }
    this.setState({ ...this.state, helperTextMacId });
    return validMacID;
  };

  onOperatorListChange = event => {
    if (this.props.showEditDevice) {
      this.setState({
        ...this.state,
        existingDevice: {
          ...this.state.existingDevice,
          operators: event.target.value
        },
        editedFields: {
          ...this.state.editedFields,
          operators: event.target.value
        }
      });
    } else {
      this.setState({
        ...this.state,
        newDevice: { ...this.state.newDevice, operators: event.target.value }
      });
    }
  };

  onConfigurationListChange = event => {
    if (this.props.showEditDevice) {
      this.setState({
        ...this.state,
        existingDevice: {
          ...this.state.existingDevice,
          config: event.target.value
        },
        editedFields: { ...this.state.editedFields, config: event.target.value }
      });
    } else {
      this.setState({
        ...this.state,
        newDevice: { ...this.state.newDevice, config: event.target.value }
      });
    }
  };

  onAdminChange = e => {
    if (this.props.showEditDevice) {
      this.setState({
        ...this.state,
        existingDevice: {
          ...this.state.existingDevice,
          admin: e.target.value
        },
        editedFields: {
          ...this.state.editedFields,
          admin: e.target.value
        }
      });
    }
  };

  addNewDevice() {

    if (!this.validateFormPreSubmit()) {
      return
    }

    this.props.addDevices(
      this.state.newDevice,
      successResponse => {
        toast("Device Added Successfully", {
          autoClose: 5000,
          type: toast.TYPE.SUCCESS,
          position: toast.POSITION.BOTTOM_CENTER
        });
        this.handleCloseDeviceForm(true);
      },
      errorResponse => {
        var toastMessage = "Device Adding Failed. "
        this.displayAPIErrorstoFormFields(errorResponse.response.data);
        if(errorResponse.response.status === 405){
          toastMessage = toastMessage + errorResponse.response.data.message
        }
        toast(toastMessage, {
          autoClose: 10000,
          type: toast.TYPE.ERROR,
          position: toast.POSITION.TOP_CENTER
        });
      }
    );
  }

  editExistingDevice() {
    if (!this.validateFormPreSubmit()) {
      return
    }

    this.props.editDevices(
      this.state.existingDevice.id,
      this.state.editedFields,
      successResponse => {
        toast("Device Updated Successfully.", {
          autoClose: 5000,
          type: toast.TYPE.SUCCESS,
          position: toast.POSITION.BOTTOM_CENTER
        });
        this.handleCloseDeviceForm(true);
      },
      errorResponse => {
        var toastMessage = "Device Update Failed. "
        this.displayAPIErrorstoFormFields(errorResponse.response.data);
        if(errorResponse.response.status === 405){
          toastMessage = toastMessage + errorResponse.response.data.message
        }
        toast(toastMessage, {
          autoClose: 10000,
          type: toast.TYPE.ERROR,
          position: toast.POSITION.TOP_CENTER
        });
      }
    );
  }

  handleCloseDeviceForm = (fetch = false) => {
    this.setState({ ...InitialStates }, () => {
      this.props.handleCloseDeviceForm();
      if (fetch) {
        this.props.fetchDevicesData();
      }
    });
  };

  onDeviceFormSubmit = () => {
    this.props.showEditDevice
      ? this.editExistingDevice()
        : this.addNewDevice();
  }

  render() {
    const { classes, operators, configurations, admins } = this.props;
    let selectedOperators = this.props.showEditDevice
      ? this.state.existingDevice.operators
      : this.state.newDevice.operators;
 
    if (this.props.showEditDevice && typeof(selectedOperators)=="string"){
        selectedOperators = selectedOperators.split(",")
    }

    return (
      <div className="row main_popup">
        <Dialog
          fullScreen
          open={this.props.openDeviceForm}
          onClose={this.handleCloseDeviceForm}
          TransitionComponent={Transition}
          className="popup-module"
          disableBackdropClick
        >
          <AppBarComponent
            icon={"fa fa-laptop"}
            title={"Device"}
            handleClose={this.handleCloseDeviceForm}
          />

          <DialogContent className="dialogContent">
            <div className="col-md-4 col-sm-0"></div>
            <div className="col-md-4 col-sm-12">
              <form className="dialogContent-form">
                <TextField
                  autoComplete={"off"}
                  id="device-description"
                  label="Description"
                  style={{ marginBottom: 8 }}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={
                    this.props.showEditDevice
                      ? this.state.existingDevice.description
                      : this.state.newDevice.description
                  }
                  placeholder="Default"
                  onChange={this.onDeviceDescChange}
                  error={this.fieldError("description")}
                  helperText={this.fieldHelperText("description")}
                />
                <TextField
                  autoComplete={"off"}
                  id="device-serial-no"
                  label="Serial No."
                  style={{ marginBottom: 8 }}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={
                    this.props.showEditDevice
                      ? this.state.existingDevice.serial_no
                      : this.state.newDevice.serial_no
                  }
                  placeholder="SL-BRH-06-10-"
                  disabled={this.props.showEditDevice}
                  onChange={this.onDeviceSlNochange}
                  error={this.fieldError("serial_no")}
                  helperText={this.fieldHelperText("serial_no")}
                />

                <div  className="formCtr">
                <FormControl className="formCtr">
                <InputLabel id="demo-simple-select-label">Model No</InputLabel>
                <Select
                labelId="demo-simple-select-label"
                id="device-model-no"
                SelectProps={{
                  MenuProps: {
                    className: classes.menu
                  }
                }}
                value={
                  this.props.showEditDevice
                  ? this.state.existingDevice.model_no
                  : this.state.newDevice.model_no
                }
                disabled={this.props.showEditDevice}
                onChange={this.onModelNochange}
                >
                {BRHModels.map(option => (
                  <MenuItem value={option}>{option}</MenuItem>
                ))}
                </Select>
                </FormControl>
                </div>
                <TextField
                  id="device-mac-id"
                  label="Mac ID"
                  autoComplete="off"
                  style={{ marginBottom: 8 }}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={
                    this.props.showEditDevice
                      ? this.state.existingDevice.mac_address
                      : this.state.newDevice.mac_address
                  }
                  onChange={this.onMacidChange}
                  onBlur={this.validateMacID}
                  disabled={this.props.showEditDevice}
                  error={this.fieldError("mac_address")}
                  helperText={this.fieldHelperText("mac_address")}
                />

                <TextField
                  autoComplete="off"
                  id="device-transmitter-id"
                  label="Transmitter ID"
                  style={{ marginBottom: 8 }}
                  margin="normal"
                  variant="outlined"
                  inputProps={{ min: "0", max: "8191", step: "1" }}
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={
                    this.props.showEditDevice
                      ? this.state.existingDevice.transmitter_id
                      : this.state.newDevice.transmitter_id
                  }
                  disabled={this.props.showEditDevice}
                  onChange={this.onTransmitterIdChange}
                  error={this.fieldError("transmitter_id")}
                  helperText={this.fieldHelperText("transmitter_id")}
                />

                <TextField
                  autoComplete="off"
                  id="device-transmitter-group-id"
                  label="Transmitter Group ID"
                  style={{ marginBottom: 8 }}
                  margin="normal"
                  variant="outlined"
                  inputProps={{ min: "0", max: "127", step: "1" }}
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={
                    this.props.showEditDevice
                      ? this.state.existingDevice.transmitter_group_id
                      : this.state.newDevice.transmitter_group_id
                  }
                  disabled={this.props.showEditDevice}
                  onChange={this.onTransmitterGroupIdChange}
                  error={this.fieldError("transmitter_group_id")}
                  helperText={this.fieldHelperText("transmitter_group_id")}
                />

                {this.props.showEditDevice ? (

                  <div  className="formCtr">
                  <FormControl className="formCtr">
                  <InputLabel id="device-admin-select-label">Admin</InputLabel>
                  <Select
                  labelId="device-admin-select-label"
                  id="device-admin"
                  SelectProps={{
                    MenuProps: {}
                  }}
                  value={
                    this.props.showEditDevice
                      ? this.state.existingDevice.admin
                      : this.state.newDevice.admin
                  }
                  onChange={this.onAdminChange}
                  >

                    {admins.length > 0 &&
                      admins.map(option => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                   </div>


                ) : null}

                <FormControl
                    error={this.fieldError("operators")}
                  >
                  <InputLabel htmlFor="device-operators-select-label">
                    Device Operators (Username ONLY)
                  </InputLabel>
                  <Select
                    multiple
                    id="device-operators"
                    value={selectedOperators}
                    onChange={this.onOperatorListChange}
                    input={<Input id="device-operators-select-label" />}
                    error={this.fieldError("operators")}
                    helperText={this.fieldHelperText("operators")}
                    renderValue={selected => {
                      let selectedNames = operators
                        .filter(e => selectedOperators.indexOf(e.id) > -1)
                        .map(e => e.username)
                        .join(", ");
                      return selectedNames;
                    }}
                    MenuProps={() => {
                      const ITEM_HEIGHT = 48;
                      const ITEM_PADDING_TOP = 8;
                      const MenuProps = {
                        PaperProps: {
                          style: {
                            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                            width: 250
                          }
                        }
                      };

                      return MenuProps;
                    }}
                  >
                    {operators.map(e => (
                      <MenuItem key={e.id} value={e.id}>
                        <Checkbox
                          checked={selectedOperators.indexOf(e.id) > -1}
                        />
                        <ListItemText primary={e.username} />
                      
                      </MenuItem>
                      
                    ))}
                  </Select>
                  <FormHelperText>{this.fieldHelperText("operators")}</FormHelperText>
                </FormControl>

                <div  className="formCtr">
                <FormControl className="formCtr">
                <InputLabel id="device-config-select-label">Configuration</InputLabel>
                <Select
                  labelId="device-config-select-label"
                  id="device-config"
                  SelectProps={{
                    MenuProps: {}
                  }}
                  value={
                    this.props.showEditDevice
                      ? this.state.existingDevice.config
                      : this.state.newDevice.config
                  }
                  onChange={this.onConfigurationListChange}
                  error={this.fieldError("config")}
                  helperText={this.fieldHelperText("config")}
                >

                {configurations.length > 0 &&
                  configurations.map(option => (
                    <MenuItem key={option.id} value={option.id}>
                      {`${option.name} (${option.id})`}

                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
                 </div>

                <div className="form-button-container">
                <Button
                  variant="outlined"
                  className="form-submit-button"
                  onClick={this.onDeviceFormSubmit}
                >
                  {this.props.showEditDevice ? "Edit Device" : "Add Device"}
                </Button>
                <Button
                  variant="outlined"
                  className="form-cancel-button"
                  onClick={this.handleCloseDeviceForm}
                >
                  Cancel
                </Button>
                </div>
              </form>
            </div>
            <div className="col-md-4 col-sm-0"></div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
