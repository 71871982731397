import React from "react";
import Helmet from "react-helmet";

const TitleComponent = ({ title, favicon, faviconType }) => {
  let defaultTitle = "BRH-EMS";
  let stylesheet =  process.env.REACT_APP_ENVIRON === "production" ? "/dist/css/brh-prod.css" : "/dist/css/brh.css";
  return (
    <Helmet>
      <title>{title ? defaultTitle + " \u00BB " + title : defaultTitle}</title>
      <link rel="icon" type={faviconType} href={favicon} />
      <link rel="stylesheet" type="text/css" href={stylesheet} />
    </Helmet>
  );
};

export default TitleComponent;
