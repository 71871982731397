import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import dateFormat from "dateformat";
import ContentWrapper from "../../../common/components/ContentWrapper";
import CSVComponent from "../../../common/components/CSVComponent";
import SearchComponent from "../../../common/components/searchComponent";
import { changeSidebarButtonState } from "../../dashboard/actions/dashboardSidebarActions";
import { fetchAuditLogsData, isValidInput } from "../actions/auditlogsActions";
import { TableContainer } from "@material-ui/core";

const user_id = localStorage.getItem("user_id");
const user_name = localStorage.getItem("username");
const user_suffix = "user_id=" + user_id + "&user_name=" + user_name + "&";


class AuditlogsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterURL: "frontend/auditlogs/",
    };
  }
  componentDidMount() {
    this.props.changeSidebarButtonState("auditlogs", "AUDITLOGS_BTN_ACTIVE");
    this.props.fetchAuditLogsData();
  }

  loadMore = () => {
    this.props.fetchAuditLogsData(this.props.nextLink + "&loadmore=1", true);
  };

  setFilterURL = (url) => this.setState({ filterURL: url });

  render() {
    return <ContentWrapper contentView={this.getContentView()} netError={this.props.netError} />;
  }

  getContentView = () => {
    const auditlogs = this.props.auditlogsInfo;

    let placeholder =
      "Search here";

    return (
      <div className="content-wrapper">
        <div className="content">
          <div className="row main_top_headding">
            <div className="col-md-4 main_top_headding_mid textLeft">
              <h4>
                <i className="fa fa-bell-o" aria-hidden="true"></i> &nbsp; Audit
                Logs
              </h4>
            </div>
            <div className="col-md-7">
            <div className="tip-tool">
              <SearchComponent
                searchAPICall={this.props.fetchAuditLogsData}
                url={"frontend/auditlogs/?page=1&"+user_suffix}
                isValidInput={isValidInput}
                placeHolder={placeholder}
                setFilterURL={this.setFilterURL}
              />
              <span className="tooltiptext"> macid=aa:bb:cc:dd:ee:ff, facility=facility, username=username</span>
              </div>
            </div>
            <div className="main_top_headding_right pull-right">
              <CSVComponent
                url={this.state.filterURL}
                csvName={"Auditlogs_Data"}
              />
            </div>
          </div>
          {/*<!-- row ---> */}
          {auditlogs.length === 0 ? (
            <div style={{ textAlign: "center" }}>
              <h4>No Audit Logs Found</h4>
            </div>
          ) : (
            <div className="row common_row">
              <Paper>
                <TableContainer className="table-all table-responsive">
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell align="left" className="text-wrap-head">Date & Time</TableCell>
                        <TableCell align="center" className="text-wrap-head">Facility</TableCell>
                        <TableCell align="left" className="text-wrap-head">Username</TableCell>
                        <TableCell align="center" className="text-wrap-head">Device SL NO.</TableCell>
                        <TableCell align="center" className="text-wrap-head">MAC ID</TableCell>
                        <TableCell align="center" className="text-wrap-head">Audit Level</TableCell>
                        <TableCell align="left" className="text-wrap-head">Message</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {auditlogs.length > 0 &&
                        auditlogs.map(row => (
                          <TableRow id={row.id}>
                            <TableCell align="left"  className="text-wrap">
                              {dateFormat(row.created_at)}
                            </TableCell>
                            <TableCell align="center"  className="text-wrap">{row.facility}</TableCell>
                            <TableCell align="left"  className="text-wrap">
                              {row.username ? row.username : "-"}
                            </TableCell>
                            <TableCell align="center"  className="text-wrap">
                              {row.serialno ? row.serialno : "-"}
                            </TableCell>
                            <TableCell align="center"  className="text-wrap">
                              {row.deviceid ? row.deviceid : "-"}
                            </TableCell>
                            <TableCell align="center"  className="text-wrap">{row.auditlevel}</TableCell>
                            <TableCell align="left"  className="text-wrap">{row.message}</TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </div>
          )}
          {this.props.nextLink ? (
            <div>
              <button
                type="button"
                className="btn btn-outline-primary "
                onClick={() => {
                  this.loadMore();
                }}
              >
                Load More...
              </button>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auditlogsInfo: state.auditlogsInfo.auditlogsInfo,
    nextLink: state.auditlogsInfo.nextLink,
    netError: state.auditlogsInfo.netError
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      changeSidebarButtonState,
      fetchAuditLogsData,
      isValidInput
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AuditlogsComponent);
