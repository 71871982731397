import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import dateFormat from "dateformat";
import { toast } from "react-toastify";
import { getSeverity } from "../constants/constants";
import {
  fetchAlarmsData,
  acknowledgeAlarmData
} from "../actions/NotificationTableActions";
import TableSort from "../../common/components/TableSort";
import {FormControlLabel, Checkbox, TextField } from "@material-ui/core";
import AlertDialogSlide from "./AlertDialog";

const headCells = [
  { id: "alarm_level", numeric: true, label: "Severity", align: "left" },
  { id: "alarm_type", numeric: true, label: "Alarm Type", align: "center" },
  { id: "alarm_source", numeric: true, label: "Source", align: "left" },
  { id: "alarm_created_at", numeric: true, label: "Date & Time", align: "left" },
  { id: "alarm_device_id", numeric: true, label: "MAC ID", align: "center" },
  { id: "alarm_info", numeric: true, label: "Description", align: "left" },
  { id: "acknowledge", numeric: true, label: "Acknowledge", align: "center" }
];

const initialState = {
    openAcknowledge: false,
    note: '',
    all: 1,
    alarm: {}
}

class NotificationTable extends Component {

  state = { ...initialState };

  handleOpenAcknowledge = (alarm) => {
    this.setState({ openAcknowledge: true, alarm });
  };

  handleClose = () => {
    this.setState({ ...initialState });
  }

  handleChangeNote = (e) => {
    this.setState({ note: e.target.value })
  }

  handleChangeAll = (e) => {
    this.setState({ all: +!this.state.all })
  }

  acknowledgeAlarm =() => {
    const { note, all, alarm } = this.state;
    const data = note ? {note, all} : { all }

    this.props.acknowledgeAlarmData(
      alarm.id,
      data,
      successResponse => {
        this.handleClose();
        toast("Alarm Acknowledged", {
          autoClose: 5000,
          type: toast.TYPE.SUCCESS,
          position: toast.POSITION.BOTTOM_CENTER
        });

        this.props.filterURL
          ? this.props.fetchAlarmsData(this.props.filterURL)
          : this.props.fetchAlarmsData();
      },
      errorResponse => {
        let message =
          errorResponse.response.status === 404
            ? "No alarm with given ID"
            : "Unknown error occurred";

        toast(message, {
          autoClose: 5000,
          type: toast.TYPE.ERROR,
          position: toast.POSITION.TOP_CENTER
        });
      }
    );
  };

  render() {
    if (this.props.notifications.length === 0)
      return (
        <div style={{ textAlign: "center" }}>
          <h4>No Unacknowledged Alarms Found!</h4>
        </div>
      );

    return (
        <div className="row dashboard_table responsive-table">
          <AlertDialogSlide
            open={this.state.openAcknowledge}
            handleClose={this.handleClose}
            title={`Acknowledge Alarm(s) with  type=${this.state.alarm.alarm_type}, source=${this.state.alarm.alarm_source}`}
            elements={
              < >
                <TextField
                  label="Note"
                  variant="outlined"
                  fullWidth
                  multiline
                  margin="normal"
                  rowsMax={4}
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={this.state.note}
                  onChange={this.handleChangeNote}
                />
                <FormControlLabel
                  control={ <Checkbox color="primary" checked={Boolean(this.state.all)} /> }
                  label="Acknowledge all previous occurences"
                  onChange={this.handleChangeAll}
                />
              </>
              }
            action={this.acknowledgeAlarm}
          />

            <TableSort
              headCells={headCells}
              rows={this.props.notifications}
              getSeverity={getSeverity}
              dateFormat={dateFormat}
              openAcknowledgeAlarm={this.handleOpenAcknowledge}
              page={this.props.page}
              changePage={this.props.changePage}
              count={this.props.count}
            />
        </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    notifications: state.notificationTableInfo.notifications,
    count: state.notificationTableInfo.count,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchAlarmsData,
      acknowledgeAlarmData
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationTable);
