import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchAllData } from "../actions/CSVActions";

class CSVComponent extends Component {
  render() {
    return (
      <button
        type="button"
        className="btn btn-outline-secondary "
        onClick={() => {
          if (this.props.mapper && this.props.sorter)
            this.props.fetchAllData(
              this.props.url,
              this.props.csvName,
              this.props.mapper,
              this.props.sorter
            );
          else if (this.props.mapper)
            this.props.fetchAllData(this.props.url, this.props.csvName, this.props.mapper, null);
          else if (this.props.sorter)
            this.props.fetchAllData(this.props.url, this.props.csvName, null, this.props.sorter);
          else this.props.fetchAllData(this.props.url, this.props.csvName);
        }}
      >
        CSV <span className="glyphicon glyphicon-download-alt"></span>
      </button>
    );
  }
}

function mapStateToProps(state) {
  return {
    CSVInfo: state.CSVInfo.CSVInfo
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchAllData
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CSVComponent);
