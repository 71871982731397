import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import CSVComponent from "../../../common/components/CSVComponent";
import SearchComponent from "../../../common/components/searchComponent";
import { isValidInput } from "../actions/notificationActions";
import ContentWrapper from "../../../common/components/ContentWrapper";
import { changeSidebarButtonState } from "../../dashboard/actions/dashboardSidebarActions";
import { getSeverityObj, getAlarmStateString } from "../../../common/constants/constants";
import { fetchAlarmsData } from "../../../common/actions/NotificationTableActions";
import NotificationTable from "../../../common/components/NotificationTable";

const user_id = localStorage.getItem("user_id");
const user_name = localStorage.getItem("username");
const alarm_url = "frontend/alarms/?user_id=" + user_id + "&user_name=" + user_name;

class NotificationsComponent extends Component {
  constructor(props) {
    var macId = localStorage.getItem("macIdForNotificationFilter");
    var filterString = macId ? "macid=" + macId : null;
    super(props);
    this.state = {
      open: false,
      searchString: filterString,
      // filterURL: "frontend/alarms/?page=1&alarm_state=0&",
      filterURL: alarm_url + "&page=1&alarm_state=0&",
      page: 1,
      intervalID: null
    };
    this.getContentView = this.getContentView.bind(this);
  }

  componentDidMount() {
    if (!this.state.searchString) {
      this.props.fetchAlarmsData();
    }
    this.setPeriodicFetch();
    localStorage.removeItem("macIdForNotificationFilter");
    this.props.changeSidebarButtonState(
      "notifications",
      "NOTIFICATIONS_BTN_ACTIVE"
    );
  }

  setPeriodicFetch = () => {
    var intervalID = setInterval(() => {
      this.props.fetchAlarmsData(this.state.filterURL);
    }, 30 * 1000);
    this.setState({ intervalID });

  };

  mapper(array) {
    return array.map(elem => {
      let mappedElem = JSON.parse(JSON.stringify(elem));
      mappedElem.alarm_state = getAlarmStateString(elem.alarm_state);
      mappedElem.alarm_level = getSeverityObj(elem.alarm_level).text;
      return mappedElem;
    });
  }

  sorter = array => {
    array.sort((a, b) => {
      let aDate = new Date(a.alarm_created_at);
      let bDate = new Date(b.alarm_created_at);
      return bDate - aDate;
    });

    return array;
  };

  changePage = page => {
    let pageLink =
      page > this.state.page ? this.props.nextLink : this.props.prevLink;
    this.setState({ page, filterURL: pageLink}, () => {
      this.props.fetchAlarmsData(pageLink);
      if (this.state.page !== 1) {
        clearInterval(this.state.intervalID);
      } else if (this.state.page === 1) {
        this.setPeriodicFetch();
      }
    });
  };

  setFilterURL = (filterURL) => this.setState({filterURL, page:1})

  getContentView() {
    this.mapper(this.props.notifications);
    var placeholder ="Search here";

    return (
      <div className="content-wrapper">
        <div className="content">
          <div className="row main_top_headding">
            <div className="col-md-3 main_top_headding_mid textLeft">
              <h4>
                <i className="fa fa-bell-o" aria-hidden="true"></i> &nbsp;
                Alarms
              </h4>
            </div>
            <div className="main_top_headding_right pull-right">
              <CSVComponent
                url={this.state.filterURL}
                csvName={"Alarms_Data"}
                mapper={this.mapper}
                sorter={this.sorter}
              />
            </div>
            <div className="col-md-8 main_top_headding_right">
              {!this.props.netError ? (
              <div className="tip-tool">
                <SearchComponent
                  searchAPICall={this.props.fetchAlarmsData}
                  url={alarm_url+"&page=1&alarm_state=0&"}
                  isValidInput={isValidInput}
                  placeHolder={placeholder}
                  defaultSearchString={this.state.searchString}
                  setFilterURL={this.setFilterURL}
                />
                <span className="tooltiptext">  macid=aa:bb:cc:dd:ee:ff, severity=[Info|Warning|Minor|Major|Critical], source=[DVBS2|MPE|STL_TP|OTHER|OAM|GLOBAL|GPS|EMS, ATSC3], last=1d/D|1h/H </span>

                </div>
              ) : null}
            </div>
          </div>
          <NotificationTable
            filterURL={this.state.filterURL}
            page={this.state.page}
            changePage={this.changePage}
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <ContentWrapper
        contentView={this.getContentView()}
        netError={this.props.netError}
      />
    );
  }
}

// //Required to tell redux how this component is mapped with dashboard reducers
function mapStateToProps(state) {
  return {
    notifications: state.notificationTableInfo.notifications,
    netError: state.notificationTableInfo.netError,
    nextLink: state.notificationTableInfo.nextLink,
    prevLink: state.notificationTableInfo.prevLink,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      changeSidebarButtonState,
      fetchAlarmsData
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationsComponent);
