import React, { Component } from "react";
import { signOutFunction } from "../actions/signoutActions";
import Avatar from "@material-ui/core/Avatar";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import DialogContent from "@material-ui/core/DialogContent";
import UserProfileForm from "../../common/components/UserProfileForm";
import HTTPRequestHandler from "../../utils/HTTPRequestHandler";
import ChangePassword from "./ChangePassword";
import AppBarComponent from "../../common/components/AppBarComponent";
import dateFormat from "dateformat";

const httpRequestHandler = new HTTPRequestHandler();

const roleToAPIURLMapping = {
  owner: "provisioning/admins/",
  admin: "provisioning/admins/",
  operator: "provisioning/operators/"
};

function Transition(props) {
  return <Slide direction="up" {...props} />;
}
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      editProfile: false,
      userProfile: { name: "" },
      changePassword: false
    };
  }

  componentDidMount() {
    this.getProfile();
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState(
      {
        ...this.state,
        open: false,
        editProfile: false
      },
      () => {
        this.getProfile();
      }
    );
  };

  getLogoFilename() {
    return (
        process.env.REACT_APP_LOGO_FILENAME ||
        "Saankhya_logo.png"
    );
  }

  getEmsPdfUrl() {
    return (
      process.env.REACT_APP_USER_MANUAL_URL ||
      "SL_Yoga-BRH_EMS_User_Manual.pdf"
    );
  }

  getFirmwarePdfUrl() {
    return (
      process.env.REACT_APP_FIRMWARE_USER_MANUAL_URL ||
      "BRH_User_Manual_40W.pdf"
    );
  }

  getKailashFirmwarePdfUrl() {
    return (
      process.env.REACT_APP_FIRMWARE_USER_MANUAL_URL_5W ||
      "BRH_User_Manual_Kailash.pdf"
    );
  }

  getSWVersion() {
    return process.env.REACT_APP_SW_VERSION || "dev";
  }

	getSupportedFWVersion40w() {
		return process.env.REACT_APP_MIN_FW_VERSION_40W || "Unknown" ;
	}

  getSupportedFWVersion5w() {
		return process.env.REACT_APP_MIN_FW_VERSION_5W || "Unknown" ;
	}

  getBuildDate() {
    return process.env.REACT_APP_BUILD_TIME || (new Date()).toGMTString();
  }

  getURLForUserProfile() {
    const id = localStorage.getItem("user_id");
    const role = localStorage.getItem("roleKey");
    const url = roleToAPIURLMapping[role] + id + "/";
    return url;
  }

  getProfile = () => {
    const url = this.getURLForUserProfile();
    httpRequestHandler
      .get(url)
      .then(response => {
        this.setState({
          userProfile: response.data
        });
      })
      .catch(error => {
        console.error(error);
      });
  };

  changePassword = () => {
    this.setState({
      changePassword: true
    });
  };

  onCloseChangePassword = () => {
    this.setState({
      changePassword: false
    });
  };

  render() {
		const role = localStorage.getItem('roleKey') || 'Unknonwn';

    return (
      <div>
        <header className="main-header">
          <div className="nav-container ">
            <nav className="navbar navbar-static-top">
              <a
                className="sidebar-toggle"
                data-toggle="push-menu"
                role="button"
              >
                <span className="sr-only">Toggle navigation</span>
              </a>
              <a href="/dashboard" className="logo">
                <span className="logo-mini">
                  <img
                    src={this.getLogoFilename()}
                    className="img-responsive"
                    alt="logo"
                  />
                </span>
                <span className="logo-lg">
                  <img
                    src={this.getLogoFilename()}
                    className="img-responsive"
                    alt="logo"
                  />
                </span>
              </a>
              <div className="navbar-custom-menu">
                <ul className="nav navbar-nav">
                  <li className="dropdown user user-menu">
                    <a className="dropdown-toggle" data-toggle="dropdown">
                      <Avatar className="user-image">
                        {this.state.userProfile.name.slice(0, 1)}
                      </Avatar>
                      <span className="hidden-xs user-name"></span>
                    </a>
                    <ul className="dropdown-menu">
                      <li className="user-header">
                        <div className="av-container">
                          <div className="avatar-name">
                            <Avatar>
                              <span className="name-caps">
                                {this.state.userProfile.name.slice(0, 1)}
                              </span>{" "}
                            </Avatar>
                          </div>
                          <div className="user-namepic df">
                            <span className="username-name ">
                              {`${this.state.userProfile.name} (${role})`}
                            </span>
                            <div>
                              <button
                                type="button"
                                className="btn btn-outline-secondary"
                                onClick={() => {
                                  this.setState({ editProfile: true });
                                }}
                              >
                                Profile{" "}
                                <span className="glyphicon glyphicon-edit"></span>
                              </button>
                            </div>
                            {this.state.editProfile ? (
                              <UserProfileForm
                                open={this.state.editProfile}
                                handleClose={this.handleClose}
                                Transition={Transition}
                                editProfile={this.state.editProfile}
                                userData={this.state.userProfile}
                                submitURL={this.getURLForUserProfile()}
                              />
                            ) : (
                              <div></div>
                            )}


                          </div>
                        </div>
                        <div>
                          <div className="infobox">
                              {this.state.userProfile.last_login ?
                                `Last Login: ${dateFormat(this.state.userProfile.last_login)}`
                              :null}
                          </div>
                          <div className="infobox">
                          <a onClick={this.handleClickOpen}>About</a>
                          </div>
                          <div  className="infobox">
                            {" "}
                            <a href={this.getEmsPdfUrl()} target="_blank" rel="noopener noreferrer">
                              EMS User Manual
                            </a>{" "}
                          </div>
                          <div  className="infobox">
                            {" "}
                            <a href={this.getFirmwarePdfUrl()} target="_blank" rel="noopener noreferrer">
                              Yoga BRH User Manual (SLBRH40WA/B)
                            </a>{" "}
                          </div>
                          <div  className="infobox">
                            {" "}
                            <a href={this.getKailashFirmwarePdfUrl()} target="_blank" rel="noopener noreferrer">
                              Kailash BRH User Manual (SLBRH05WA)
                            </a>{" "}
                          </div>
                          <div  className="infobox">
                          {this.state.changePassword ? (
                            <ChangePassword
                              open={this.state.changePassword}
                              handleClose={this.onCloseChangePassword}
                              Transition={Transition}
                              submitURL={this.getURLForUserProfile() + "changepassword/"}
                            />
                          ) : null}
                          <a onClick={this.changePassword} style={{color: "red"}}>Change Password</a>

                           </div>
                        </div>
                      </li>
                      <li className="user-footer">
                        <div className="center-blcok">
                          <a
                            onClick={signOutFunction}
                            className="btn btn-default btn-flat"
                          >
                            Sign out &nbsp; &nbsp;{" "}
                            <span className="glyphicon glyphicon-log-out"></span>
                          </a>
                        </div>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div className="row main_popup small-popup_module">
                <Dialog
                  fullScreen
                  open={this.state.open}
                  onClose={this.handleClose}
                  TransitionComponent={Transition}
                  className="smallpopup-module"
                  disableBackdropClick
                >
                  <AppBarComponent
                    icon={"fa fa-laptop"}
                    title={"About"}
                    handleClose={this.handleClose}
                  />

                  <DialogContent className="dialogContent content about-us">

                    <div>
                      <form className="dialogContent-form">
                        <div>
													{`Saankhya Labs BRH EMS Version - ${this.getSWVersion()} - (Built On - ${this.getBuildDate()} ).`}
													<br/>
													{`Supported Device Firmware Versions are:`}
                          <br/> 
                          {`1. For SLBRH40WA/SLBRH40WB (40 Watt) devices: ${this.getSupportedFWVersion40w()} or higher.`}
                          <br/>
                          {`2. For SLBRH05WA (5 Watt) devices: ${this.getSupportedFWVersion5w()} or higher.`}
                        </div>
                        <br/>
                        <div > For Support Contact: <br/><a href="mailto:support-ems@saankhyalabs.com" target="_blank" rel="noopener noreferrer">support-ems@saankhyalabs.com </a></div>

                        <div> <b>Note</b> :  Provide below details while raising support emails <br/>1. EMS Version, 2. BRH Frimware Version, 3. Problem Statement / Query </div>
                      </form>
                    </div>

                  </DialogContent>
                </Dialog>
              </div>
            </nav>
          </div>
        </header>
      </div>
    );
  }
}

export default Header;
