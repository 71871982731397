import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import HTTPRequestHandler from "../../utils/HTTPRequestHandler";
import lodash from "lodash";
import { toast } from "react-toastify";
import {
  isFormFieldInvalid,
  validateFormPreSubmit,
  displayAPIErrorstoFormFields
} from "../../common/actions/UserFormValidators";
import AppBarComponent from '../../common/components/AppBarComponent'
import MuiPhoneInput from 'material-ui-phone-number'

const httpRequestHandler = new HTTPRequestHandler();

class UserForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: this.props.userData,
      anyValueChanged: false,
      formErrors: {}
    };
  }

  onNameChange = event => {
    this.setState({
      ...this.state,
      userData: {
        ...this.state.userData,
        name: event.target.value
      },
      anyValueChanged: true
    });
  };

  onUserNameChange = event => {
    this.setState({
      ...this.state,
      userData: {
        ...this.state.userData,
        username: event.target.value
      },
      anyValueChanged: true
    });
  };

  onPasswordChange = event => {
    this.setState({
      ...this.state,
      userData: {
        ...this.state.userData,
        password: event.target.value
      },
      anyValueChanged: true
    });
  };

  onTelephoneChange = value => {
    this.setState({
      ...this.state,
      userData: {
        ...this.state.userData,
        telephone: value
      },
      anyValueChanged: true
    });
  };

  onEmailChange = event => {
    this.setState({
      ...this.state,
      userData: {
        ...this.state.userData,
        email: event.target.value
      },
      anyValueChanged: true
    });
  };

  onAddressChange = event => {
    this.setState({
      ...this.state,
      userData: {
        ...this.state.userData,
        address_1: event.target.value
      },
      anyValueChanged: true
    });
  };

  fieldError = field => {
    var f = isFormFieldInvalid(this.state.formErrors, field);
    if (f) {
      return f.error;
    } else {
      return false;
    }
  };

  fieldHelperText = field => {
    var f = isFormFieldInvalid(this.state.formErrors, field);
    if (f) {
      return f.helperText;
    } else {
      return "";
    }
  };

  submitForm = () => {
    let response = validateFormPreSubmit(this.state.userData);

    this.setState({
      formErrors: response.formErrors
    });

    if (response.formValid) {
      this.updateUserProfile();
    }
  };

  updateUserProfile = () => {
    if(!this.state.anyValueChanged) {
      this.props.handleClose();
      return;
    }
    let userObject = lodash.pick(this.state.userData, [
      "name",
      "email",
      "telephone",
      "address_1"
    ]);

    httpRequestHandler
      .patch(this.props.submitURL, userObject)
      .then(response => {
        this.props.handleClose();
        toast("User Profile Updated Successfully", {
          autoClose: 5000,
          type: toast.TYPE.SUCCESS,
          position: toast.POSITION.BOTTOM_CENTER
        });
      })
      .catch(error => {
        this.handleAPIErrors(error);
      });
  };

  handleAPIErrors = error => {
    let APIErrorData = error.response.data;
    let APIErrorResponse = displayAPIErrorstoFormFields(APIErrorData);
    this.setState({
      formErrors: APIErrorResponse.formErrors
    });
  };

  render() {
    return (
      <div className="row main_popup">
        <Dialog
          fullScreen
          open={this.props.open}
          onClose={this.props.handleClose}
          TransitionComponent={this.props.Transition}
          className="popup-module"
        >
        <AppBarComponent
          icon={"fa fa-user"}
          title={'Edit Profile'}
          handleClose={this.props.handleClose}
          />

          <DialogContent className="dialogContent">
            <div className="col-md-4 col-sm-0"></div>
            <div className="col-md-4 col-sm-12">
              <form className="dialogContent-form">
                <TextField
                  required
                  id="user-name"
                  label="Name"
                  style={{ marginBottom: 8 }}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={this.state.userData.name}
                  onChange={this.onNameChange}
                  error={this.fieldError("name")}
                  helperText={this.fieldHelperText("name")}
                />
                <TextField
                  required
                  id="user-username"
                  label="Username"
                  type="text"
                  style={{ marginBottom: 8 }}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={this.state.userData.username}
                  disabled={this.props.editProfile}
                  onChange={this.onUserNameChange}
                  error={this.fieldError("username")}
                  helperText={this.fieldHelperText("username")}
                />

                {this.props.editProfile ? null : (
                  <TextField
                    required
                    id="user-password"
                    label="Password"
                    style={{ marginBottom: 8 }}
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    type={this.props.type === "password" ? "text" : "password"}
                    value={this.state.userData.password}
                    onChange={this.onPasswordChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" className="passwordicon">
                          <IconButton
                            aria-label="Toggle password visibility"
                            onClick={this.props.handleClickShowPassword}
                            disabled={this.props.editProfile}
                          >
                            {this.props.type === "password" ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    error={this.fieldError("password")}
                    helperText={this.fieldHelperText("password")}
                    disabled={this.props.editProfile}
                  />
                )}

                <MuiPhoneInput
                  disableDropdown
                  defaultCountry={'us'}
                  required
                  id="user-telephone"
                  type="text"
                  label="Contact No."
                  style={{ marginBottom: 8 }}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={this.state.userData.telephone}
                  onChange={this.onTelephoneChange}
                  error={this.fieldError("telephone")}
                  helperText={this.fieldHelperText("telephone")}
                />

                <TextField
                  required
                  id="user-email"
                  label="Email Id"
                  type="text"
                  style={{ marginBottom: 8 }}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={this.state.userData.email}
                  onChange={this.onEmailChange}
                  error={this.fieldError("email")}
                  helperText={this.fieldHelperText("email")}
                />
                <TextField
                  required
                  id="user-address_1"
                  label="Address"
                  onChange={this.onAddressChange}
                  multiline
                  rows="4"
                  style={{ marginBottom: 8 }}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={this.state.userData.address_1}
                  error={this.fieldError("address_1")}
                  helperText={this.fieldHelperText("address_1")}
                />
                <div className="form-button-container">
                <Button
                  variant="outlined"
                  className="form-submit-button"
                  onClick={() => this.submitForm()}
                >
                  {this.props.editProfile ? "Update " : "Add "} User
                </Button>
                  <Button
                    variant="outlined"
                    className="form-cancel-button"
                    onClick={this.props.handleClose}
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            </div>

            <div className="col-md-4 col-sm-0"></div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default UserForm;
