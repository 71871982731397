import HTTPRequestHandler from "../../../utils/HTTPRequestHandler";
import {toast} from 'react-toastify';

const httpRequestHandler = new HTTPRequestHandler();
const user_id = localStorage.getItem("user_id");
const user_name = localStorage.getItem("username");
const device_url = "frontend/devices/?user_id=" + user_id + "&user_name=" + user_name;

export function fetchDashboardData() {
  return function(dispatch) {
    httpRequestHandler
      .get(device_url)
      .then(response => {
        dispatch({
          type: "FETCH_DASHBOARD_DATA_SUCCESS",
          payload: response.data
        });
      })
      .catch(error => {
        console.error(error);
      });
  };
}

export function acceptEULAFromDashboard() {
	return function(dispatch) {
		var role = localStorage.getItem("roleKey");
		if (role === undefined) {
			dispatch({
				type: "ERR_ACCEPTING_EULA",
				error: "Unable to determine current role."
			});
			return;
		}
		role = (role === "operator") ? "operators": "admins" ;
		const eula_url = `provisioning/${role}/${user_id}/accept_eula/`;
		httpRequestHandler
			.post(eula_url)
			.then(response => {
				toast("License Accepted Successfully. Loading Dashboard.", {
					autoClose: 2000,
					type: toast.TYPE.INFO,
					position: toast.POSITION.TOP_CENTER,
				});
				localStorage.setItem("eulaAccepted", JSON.stringify(true));
				dispatch({
					type: "USER_ACCEPTED_EULA",
					payload: true
				});
			})
			.catch(error => {

				toast("Error in accepting License. Retry", {
					autoClose: 5000,
					type: toast.TYPE.ERROR,
					position: toast.POSITION.TOP_CENTER,
				});
				dispatch({
					type: "USER_ACCEPTED_EULA",
					payload: false
				});
			});
	};
}
