import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import { TablePagination, TableContainer } from "@material-ui/core";

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {props.headCells.map(headCell => (
          <TableCell align={headCell.align} className="text-wrap-head" key={headCell.id}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function EnhancedTable(props) {
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('alarm_created_at');

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  };

  const changePageHandler = (e, page) => {
    setOrder('desc');
    setOrderBy('alarm_created_at');
    props.changePage(page+1)
  }

  return (
    <div>
      <Paper>
            <TablePagination
              page={props.page-1}
              onChangePage={changePageHandler}
              count={props.count}
              rowsPerPageOptions={[50]}
              rowsPerPage={50}
            />
        <TableContainer className="table-all table-responsive">
          <Table stickyHeader aria-labelledby="tableTitle" aria-label="enhanced table">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={props.headCells}
            />
            <TableBody>
              {stableSort(props.rows, getSorting(order, orderBy)).map(row => (
                <TableRow key={row.id} hover>
                  <TableCell align="left" className="text-wrap">
                    {props.getSeverity(row.alarm_level)}
                  </TableCell>
                  <TableCell align="center" className="text-wrap">{row.alarm_type}</TableCell>
                  <TableCell align="left" className="text-wrap">{row.alarm_source}</TableCell>
                  <TableCell align="left" className="text-wrap" >
                    {props.dateFormat(row.alarm_created_at)}
                  </TableCell>
                  <TableCell align="center" className="text-wrap">{row.alarm_device_id}</TableCell>
                  <TableCell align="left" className="text-wrap"> {row.alarm_info} </TableCell>
                  <TableCell align="center" className="text-wrap">
                    <button
                      type="button"
                      className="btn btn-outline-secondary"
                      onClick={() => {
                        props.openAcknowledgeAlarm(row);
                      }}
                    >
                      <span className="glyphicon glyphicon-check"></span>
                    </button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
