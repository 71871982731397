import lodash from "lodash";
import HTTPRequestHandler from "../../utils/HTTPRequestHandler";
import { toast } from "react-toastify";

const httpRequestHandler = new HTTPRequestHandler();

export function fetchAllData(url, csvName="CSV_DATA", mapper = null, sorter = null) {
  let csvUrl = url + (url.includes('?') ? '&csv=1' : '?csv=1' );
  return function(dispatch) {
    httpRequestHandler
      .get(csvUrl)
      .then(response => {
        dispatch({
          type: "FETCH_ALL_DATA_SUCCESS",
          payload: response.data
        });
        if (mapper && sorter) toCSV(mapper(sorter(response.data)), csvName);
        else if (mapper) toCSV(mapper(response.data), csvName);
        else if (sorter) toCSV(sorter(response.data), csvName);
        else toCSV(response.data, csvName);
      })
      .catch(error => {
        let message = "Error Downloading CSV.";
        if (error.code === "ECONNABORTED") {
          message += " Request Timed Out. Try again later.";
        } else {
          message += "Server Returned Error.";
        }
        toast(message, {
          autoClose: 5000,
          type: toast.TYPE.ERROR,
          position: toast.POSITION.TOP_CENTER
        });
        console.error(error);
      });
  };
}

const toCSV = (array, csvName)  => {
  if (array.length > 0) {
    let keysArr = lodash.keys(array[0]);
    let keys = keysArr.toString() + "\n";
    let values = "";
    for (let arr of array) {
      let valuesArr = lodash.values(arr);
      for (let i = 0; i < valuesArr.length; i++) {
        if (
          valuesArr[i] &&
          typeof valuesArr[i] === "string" &&
          valuesArr[i].includes(",")
        ) {
          valuesArr[i] = valuesArr[i].replace(/,/g, " | ");
        }
      }
      values = values + valuesArr.toString() + "\n";
    }
    let csv = keys + values;

    // show CSV save as dialog
    const url = window.URL.createObjectURL(new Blob([csv]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${csvName}.csv`);
    document.body.appendChild(link);
    link.click();

  }
};
