import HTTPRequestHandler from "../../../../utils/HTTPRequestHandler";
const httpRequestHandler = new HTTPRequestHandler();

const user_id = localStorage.getItem("user_id");
const user_name = localStorage.getItem("username");
const alarmconfig_url = "frontend/alarmconfigs/?user_id=" + user_id + "&user_name=" + user_name;

export function fetchAlarmsData() {
  return function(dispatch) {
    httpRequestHandler
      .get(alarmconfig_url)
      .then(response => {
        dispatch({
          type: "FETCH_ALARMS_CONFIGS_DATA_SUCCESS",
          payload: response.data
        });
      })
      .catch(error => {
        dispatch({
          type: "FETCH_ALARMS_CONFIGS_DATA_FAILURE",
          error
        });
      });
  };
}

export function editAlarms(id, editedFields, successCallback, errorCallback) {
  return function(dispatch) {
    httpRequestHandler
      .patch("frontend/alarmconfigs/" + id + "/?user_id=" + user_id + "&user_name=" + user_name, editedFields)
      .then(response => {
        successCallback(response.data);
      })
      .catch(error => {
        errorCallback(error);
      });
  };
}
