import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import HTTPRequestHandler from "../../utils/HTTPRequestHandler";
import { toast } from "react-toastify";
import { signOutFunction } from "../../common/actions/signoutActions";
import AppBarComponent from "../../common/components/AppBarComponent";
import { SpecialCharsRegEx } from '../../common/constants/constants';
import { ExcludeSpecialCharsRegEx } from '../../common/constants/constants'

const httpRequestHandler = new HTTPRequestHandler();


export default class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showExistingPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
      validateNewPassword: {
        helperText: null,
        error: false
      },
      validateConfirmPassword: {
        helperText: null,
        error: false
      }
    };
  }

  onClickShowExistingPassword = () => {
    this.setState({
      showExistingPassword: !this.state.showExistingPassword
    });
  };

  onClickShowNewPassword = () => {
    this.setState({
      showNewPassword: !this.state.showNewPassword
    });
  };

  onClickShowConfirmPassword = () => {
    this.setState({
      showConfirmPassword: !this.state.showConfirmPassword
    });
  };

  onChangeExistingPassword = event => {
    this.setState({
      existingPassword: event.target.value
    });
  };

  onChangeNewPassword = event => {
    this.setState({
      newPassword: event.target.value
    });
  };

  onChangeConfirmPassword = event => {
    this.setState({
      confirmPassword: event.target.value
    });
  };

  validatePassword(value) {
    var hasUpperCase = /[A-Z]/.test(value);
    var hasLowerCase = /[a-z]/.test(value);
    var hasNumbers = /\d/.test(value);
    var hasSpecialChars = SpecialCharsRegEx.test(value);
    var excludeSpecialChars = ExcludeSpecialCharsRegEx.test(value);
    if (
      !hasUpperCase ||
      !hasLowerCase ||
      !hasNumbers ||
      !hasSpecialChars ||
      excludeSpecialChars ||
      value.length < 6
    ) {
      return {
        helperText:
          "needs min 6 chars, 1 lowercase, uppercase, special character(!,@,#,$,%,^,&,*,(,),-,+), number.",
        error: true
      };
    }
    return { helperText: null, error: false };
  }

  changePassword = () => {
    this.setState(
      {
        validateNewPassword: this.validatePassword(this.state.newPassword),
        validateConfirmPassword: this.validatePassword(
          this.state.confirmPassword
        )
      },
      () => {
        if (
          !(
            this.state.validateNewPassword["error"] ||
            this.state.validateConfirmPassword["error"]
          )
        ) {
          this.callChangePassword();
        }
      }
    );
  };

  callChangePassword = () => {
    const data = {
      existingPassword: this.state.existingPassword,
      newPassword: this.state.newPassword
    };

    if (this.state.existingPassword === this.state.newPassword) {
      toast("New Password can not be same as Existing Password", {
        autoClose: 5000,
        type: toast.TYPE.ERROR,
        position: toast.POSITION.TOP_CENTER
      });
      return;
    }
    if (!(this.state.newPassword === this.state.confirmPassword)) {
      toast("New Password doesn't match with Confirm Password", {
        autoClose: 5000,
        type: toast.TYPE.ERROR,
        position: toast.POSITION.TOP_CENTER
      });
      return;
    }

    httpRequestHandler
      .post(this.props.submitURL, data)
      .then(response => {
        toast("Password Changed Successfully. Logging User Out", {
          autoClose: 5000,
          type: toast.TYPE.SUCCESS,
          position: toast.POSITION.TOP_CENTER
        });
        this.props.handleClose();
        setTimeout(() => {
          signOutFunction();
        }, 1.5 * 1000);
      })
      .catch(error => {
        let message = "Unknown error occurred during Password Change";
        if (error.response && error.response.status === 403) {
          message = error.response.data.message;
        }
        toast(message, {
          autoClose: 5000,
          type: toast.TYPE.ERROR,
          position: toast.POSITION.TOP_CENTER
        });
      });
  };

  handleKeyUp = (e) => {
    if (e.keyCode === 13) {
      this.changePassword();
    }
  }

  render() {
    return (
      <div className="row main_popup">
        <Dialog
          fullScreen
          open={this.props.open}
          onClose={this.props.handleClose}
          TransitionComponent={this.props.Transition}
          className="popup-module"
          disableBackdropClick
        >
          <AppBarComponent
            icon={"fa fa-unlock-alt"}
            title={"Change Password"}
            handleClose={this.props.handleClose}
          />
          <DialogContent className="dialogContent">
            <div className="col-md-4 col-sm-0"></div>
            <div className="col-md-4 col-sm-12">
              <form className="dialogContent-form">
                <TextField
                  required
                  id="user-existing-password"
                  label="Existing Password"
                  style={{ marginBottom: 8 }}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={this.state.existingPassword}
                  onChange={this.onChangeExistingPassword}
                  type={this.state.showExistingPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" className="passwordicon">
                        <IconButton
                          aria-label="Toggle password visibility"
                          onClick={this.onClickShowExistingPassword}
                        >
                          {this.state.showExistingPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                <TextField
                  required
                  id="user-new-password"
                  label="New Password"
                  style={{ marginBottom: 8 }}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={this.state.newPassword}
                  onChange={this.onChangeNewPassword}
                  type={this.state.showNewPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" className="passwordicon">
                        <IconButton
                          aria-label="Toggle password visibility"
                          onClick={this.onClickShowNewPassword}
                        >
                          {this.state.showNewPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  error={this.state.validateNewPassword["error"]}
                  helperText={this.state.validateNewPassword["helperText"]}
                />
                <TextField
                  required
                  id="user-confirm-password"
                  label="Confirm New Password"
                  style={{ marginBottom: 8 }}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={this.state.confirmPassword}
                  onChange={this.onChangeConfirmPassword}
                  type={this.state.showConfirmPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" className="passwordicon">
                        <IconButton
                          aria-label="Toggle password visibility"
                          onClick={this.onClickShowConfirmPassword}
                        >
                          {this.state.showConfirmPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  error={this.state.validateConfirmPassword["error"]}
                  helperText={this.state.validateConfirmPassword["helperText"]}
                  onKeyUp={this.handleKeyUp}
                />
                <div className="form-button-container">
                <Button
                  variant="outlined"
                  className="form-submit-button"
                  onClick={this.changePassword}
                >
                  Change Password
                </Button>
                  <Button
                  variant="outlined"
                  className="form-cancel-button"
                  onClick={this.props.handleClose}
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            </div>

            <div className="col-md-4 col-sm-0"></div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
