import HTTPRequestHandler from '../../../utils/HTTPRequestHandler';
const httpRequestHandler = new HTTPRequestHandler();
const user_id = localStorage.getItem("user_id");
const user_name = localStorage.getItem("username");
const firmware_url = "frontend/firmwares/?user_id=" + user_id + "&user_name=" + user_name;


export function firmwaresList() {
    return (function (dispatch) {
        httpRequestHandler
        .get(firmware_url)
        .then(response => {
            dispatch({
                type: 'FETCH_FIRMWARE_GET_DATA_SUCCESS',
                payload: response.data
            });
       })
        .catch(error => {
            console.error(error);
            dispatch({
                type: 'FETCH_FIRMWARE_GET_DATA_FAILURE',
                error
            });
        });
    });
};

export function addFirmware(newFirmwares, successCallback, errorCallback) {
    return (function (dispatch) {
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
            timeout: 600000, // Sometimes on very slow networks, we need to give enough time to upload the firmwares
        }
        const formData = new FormData();
        formData.append('filename', newFirmwares.filename);
        formData.append('uploaded_by', newFirmwares.uploaded_by);
        formData.append('version', newFirmwares.version);
        formData.append('model_no', newFirmwares.model_no);
        if (newFirmwares.release_notes.name) {
            formData.append('release_notes', newFirmwares.release_notes);
        }

        httpRequestHandler
        .post(firmware_url, formData, config)
        .then((response) => {
           successCallback(response.data)
        })
        .catch((error) => {
          errorCallback(error)
        })
    });
};

export function deleteFirmwareData(deleteId, successCallback, errorCallback) {
    return (function (dispatch) {
        httpRequestHandler
        .delete('frontend/firmwares/'+ deleteId +'/?user_id=' + user_id + '&user_name=' + user_name)
        .then((response) => {
           successCallback(response.data)
        })
        .catch((error) => {
         errorCallback(error)
        })
    });
};
