import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import React from "react";
import { changeSidebarButtonState } from "../../../dashboard/actions/dashboardSidebarActions";
import UserComponent from "../../../../common/components/UserComponent";

import {
  fetchUsersData,
  addUser,
  editUser,
  deleteUserIdData,
  toggleUserLogin
} from "../../../../common/actions/UserActions";

function AdminComponent(props) {
  return (
    <UserComponent
      userType={"Admin"}
      fetchUsersData={props.fetchUsersData}
      addUser={props.addUser}
      editUser={props.editUser}
      deleteUserIdData={props.deleteUserIdData}
      toggleUserLogin={props.toggleUserLogin}
      usersInfo={props.adminsInfo}
      changeSidebarButtonState={props.changeSidebarButtonState}
      userRole={"admins"}
      userState={"ADMINS_BTN_ACTIVE"}
      netError={props.netError}
    />
  );
}

function mapStateToProps(state) {
  return {
    adminsInfo: state.adminsInfo.adminsInfo,
    netError: state.adminsInfo.netError,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      changeSidebarButtonState,
      fetchUsersData,
      addUser,
      editUser,
      deleteUserIdData,
      toggleUserLogin
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminComponent);
