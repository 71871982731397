import lodash from "lodash";
import getNormalizedChartData from "../actions/Normalizer";

export default function getChartData(props) {
  if (props.deviceStats.length > 0) {
    const timeFrame = props.deviceStats.length;
    let graphData = [];
    let lastDeviceStats = props.deviceStats[props.deviceStats.length - 1];
    let graphDataPoints = [];
    let labels = lodash.map(props.selectedFields, "label");
    let values = lodash.map(props.selectedFields, "value");
    labels.splice(0, 0, "Timestamp");
    values.splice(0, 0, "timestamp");

    for (let i = 0; i < timeFrame; i++) {
      if (i > props.deviceStats.length) {
        break;
      }
      const deviceStat = props.deviceStats[i];
      graphDataPoints = [];
      let yAxisValues = [];

      if (i === 0) {
        graphData.push(labels);
      }
      values.forEach(key => {
        let value = deviceStat[key];
        if (key === "timestamp") {
          let utcTimestamp = new Date(value);
          value = utcTimestamp.toLocaleString("en-US", {
            month: "short",
            day: "2-digit",
            hour: "numeric",
            minute: "2-digit",
            second: "2-digit",
            hour12: false
          });
        }
        yAxisValues.push(value);
      });

      graphDataPoints = graphDataPoints.concat(yAxisValues);
      graphData.push(graphDataPoints);
    }

    let normalizedChartData = getNormalizedChartData(graphData);
    normalizedChartData.splice(0, 0, labels);
    let i = 0;
    for (let arr of normalizedChartData.slice(1)) {
      let utcTimestamp = new Date(props.deviceStats[i]["timestamp"]);
      arr.splice(0, 0, utcTimestamp);
      i++;
    }

    return [normalizedChartData, lastDeviceStats];
  }
}
