import React from "react";
import { toast } from "react-toastify";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import AppBarComponent from "../../../../common/components/AppBarComponent";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const InitialStates = {
  certificate: null,
  key: null,
  certificateError: false,
  keyError: false
};
export default class UploadCertForm extends React.Component {
  state = {
    ...InitialStates
  };

  handleCloseUploadForm = () => {
    this.setState(
      {
        ...InitialStates
      },
      () => {
        this.props.handleCloseUploadCert();
      }
    );
  };

  onChangeDataFile = (e, fileType) => {
    let file = e.target.files[0];

    if (file) {
      if (file.size > 64 * 1024) {
        toast("File size should be less than 64kb", {
          autoClose: 5000,
          type: toast.TYPE.ERROR,
          position: toast.POSITION.TOP_CENTER
        });
        e.target.value = null;
        return;
      }

      let reader = new FileReader();

      // called when reading is done
      reader.onload = event => {
        let isValidFile = this.validateData(event.target.result, fileType);
        this.setState({
          [fileType]: isValidFile ? event.target.result : null,
          [`${fileType}Error`]: !isValidFile
        });
      };

      //  reads file and then triggers reader.onload function
      reader.readAsText(file);
    }
  };

  validateData = (data, ftype) => {
    if (data) {
      if (
        data.includes(
          `-----BEGIN ${
            ftype === "key" ? "PRIVATE KEY" : "CERTIFICATE"
          }-----`
        ) &&
        data.includes(
          `-----END ${
            ftype === "key" ? "PRIVATE KEY" : "CERTIFICATE"
          }-----`
        )
      ) {
        toast(`Valid ${ftype} selected`, {
          autoClose: 5000,
          type: toast.TYPE.SUCCESS,
          position: toast.POSITION.TOP_CENTER
        });
        return true;
      }
    }
    toast(`File doesn't include valid ${ftype}`, {
      autoClose: 5000,
      type: toast.TYPE.ERROR,
      position: toast.POSITION.TOP_CENTER
    });
    return false;
  };

  uploadCert = () => {
    if (this.props.deviceID && this.state.certificate && this.state.key) {
      this.props.uploadCert(
        this.props.deviceID,
        this.state.certificate,
        this.state.key,
        successResponse => {
          toast("Device Certificate Uploaded Successfully.", {
            autoClose: 5000,
            type: toast.TYPE.SUCCESS,
            position: toast.POSITION.TOP_CENTER
          });
          this.handleCloseUploadForm();
          this.props.fetchDevicesData();
        },
        errorResponse => {
          toast("Device Certificate Upload Failed", {
            autoClose: 5000,
            type: toast.TYPE.ERROR,
            position: toast.POSITION.TOP_CENTER
          });
        }
      );
    } else {
      toast("Please select valid certificate and key", {
        autoClose: 5000,
        type: toast.TYPE.ERROR,
        position: toast.POSITION.TOP_CENTER
      });
    }
  };
  render() {
    return (
      <div className="row main_popup">
        <Dialog
          fullScreen
          open={this.props.openUploadCert}
          onClose={this.handleCloseUploadForm}
          TransitionComponent={Transition}
          className="popup-module"
          disableBackdropClick
        >
          <AppBarComponent
            icon={"fa fa-upload"}
            title={"Upload Certificate"}
            handleClose={this.handleCloseUploadForm}
          />

          <DialogContent className="dialogContent">
            <div className="col-md-4 col-sm-0"></div>
            <div className="col-md-4 col-sm-12">
              <form className="dialogContent-form">
                <TextField
                  required
                  type="file"
                  label="Certificate"
                  onChange={e => this.onChangeDataFile(e, "certificate")}
                  error={this.state.certificateError}
                  variant="outlined"
                  style={{ marginBottom: 10 }}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
                <TextField
                  required
                  type="file"
                  label="Key"
                  onChange={e => this.onChangeDataFile(e, "key")}
                  error={this.state.keyError}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true
                  }}
                />

              <div className="form-button-container">
                <Button
                  variant="outlined"
                  className="form-submit-button"
                  onClick={this.uploadCert}
                  disabled={!(this.state.certificate && this.state.key)}
                >
                  Upload Certificate
                </Button>
                <Button
                  variant="outlined"
                  className="form-cancel-button"
                  onClick={this.handleCloseUploadForm}
                >
                  Cancel
                </Button>
              </div>
              </form>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
