const initialState = {
  CSVInfo: []
};

export default (state = initialState, action) => {
  let currentState = state;
  switch (action.type) {
    case "FETCH_ALL_DATA_SUCCESS":
      currentState = {
        ...state,
        CSVInfo: action.payload
      };
      break;

    default:
  }
  return currentState;
};
