import React from "react";
import LoadingOverlay from "react-loading-overlay";
import Modal from '@material-ui/core/Modal'
import { BallBeat } from "react-pure-loaders";
import { connect } from "react-redux";

const LoaderRequest = props => {
  return (
    <Modal
    open={props.loader}
    >
        <div
          style={{
            top: "50%",
            left: "50%",
            alignSelf:"center",
            transform: "translate(-50%, -50%)",
            position: "absolute",
            width: "5%",
            height: "2%",
            paddingBottom:20,
            backgroundColor: "white",
            outline: "none"
          }}
        >
    <LoadingOverlay
      active={props.loader}
      spinner={<BallBeat loading={true} color="#000" />}
    />
    </div>
    </Modal>
  );
};

const mapStateToProps = state => {
  return {
    loader: state.LoaderRequestInfo
  };
};

export default connect(mapStateToProps, null)(LoaderRequest);
