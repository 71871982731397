import validator from "validator";
import HTTPRequestHandler from "../../../utils/HTTPRequestHandler";
const httpRequestHandler = new HTTPRequestHandler();

export function fetchAuditLogsData(
  url = "frontend/auditlogs/?page=1",
  append = false
) {
  return function(dispatch) {
    httpRequestHandler
      .get(url)
      .then(response => {
        dispatch({
          type: "FETCH_AUDITLOGS_DATA_SUCCESS",
          payload: response.data.results,
          nextLink: response.data.next,
          append: append
        });
      })
      .catch(error => {
        console.error(error);
        dispatch({
          type: "FETCH_AUDITLOGS_DATA_FAILURE",
          error
        });
      });
  };
}

export function isValidInput(rawSearchString) {
  let valid_keys = ["macid", "username", "facility"];
  // let search_string = searchString.replace(/\s/g, '');
  let searchString = rawSearchString.trim();
  let queryStrings = [];
  let apiResponse = { errorMessage: "", inputValid: true, searchValue: "" };

  if (searchString.length === 0) {
    return apiResponse;
  }
  let tokens = searchString.split(",");
  for (let token of tokens) {
    if (token.trim().length === 0) {
      continue;
    }

    let kvs = token.split("=");

    if (kvs.length !== 2) {
      let errorMessage =
        "Invalid input, valid input format is macid=<mac Address>, username=<username>, facility=<facility>";
      return {
        ...apiResponse,
        errorMessage: errorMessage,
        inputValid: false,
        searchValue: ""
      };
    }

    let key = kvs[0].trim();
    let val = kvs[1].trim();

    if (valid_keys.indexOf(key) === -1) {
      let errorMessage =
        key +
        '" is not a supported search item. Supported items are ' +
        valid_keys.join();
      return {
        ...apiResponse,
        errorMessage: errorMessage,
        inputValid: false,
        searchValue: ""
      };
    }

    let validatedKeyValue = validateKeyValue(key, val);

    if (validatedKeyValue) {
      let errorMessage = validatedKeyValue;
      return {
        ...apiResponse,
        errorMessage: errorMessage,
        inputValid: false,
        searchValue: ""
      };
    } else {
      let mapped = toApiParams(key);
      if (mapped) {
        if (key === "macid") {
          val = val.toUpperCase();
        }
        let query_string = mapped + "=" + val;
        queryStrings = queryStrings.concat(query_string);
      }
    }
  }
  return {
    ...apiResponse,
    errorMessage: "",
    inputValid: true,
    searchValue: queryStrings.join("&")
  };
}

function validateKeyValue(key, value) {
  let keyValueErrorMessage = null;
  if (key === "macid") {
    let macValid = validator.isMACAddress(value);
    if (macValid === false) {
      keyValueErrorMessage = "macid value " + value + " is invalid.";
    }
  }
  if (!key || !value) {
    keyValueErrorMessage = "Empty Key or Value is not supported";
  }
  return keyValueErrorMessage;
}

function toApiParams(key) {
  let map = {
    username: "username",
    facility: "facility",
    macid: "deviceid"
  };
  return map[key];
}
