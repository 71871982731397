import React from "react";
import Modal from "@material-ui/core/Modal";

export default function SimpleModal(props) {
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <button id="submit" type="button" onClick={handleOpen} disabled={props.disable}>
        {props.buttonName}
      </button>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        //open={true}
        onClose={handleClose}
      >
        <div
          style={{
            fontFamily: "sans-serif",
            textAlign: "center",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            position: "absolute",
            width: "90%",
            height: "80%",
            backgroundColor: "white",
            border: "2px solid #FFDF00",
            // boxShadow: theme.shadows[5],
            // padding: theme.spacing(2, 4, 4),
            outline: "none"
          }}
        >
          <button onClick={handleClose} style={{float:'right'}}>X</button>
          {props.data}
        </div>
      </Modal>
    </div>
  );
}
