import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide(props) {
  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        onClose={props.handleClose}
      >
        <DialogTitle id="alert-dialog-slide-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {props.desc}
            <br />
            {props.elements}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={
              props.onDeclineAction ? props.onDeclineAction : props.handleClose
            }
            color="primary"
						variant="outline"
          >
            No
          </Button>
          <Button onClick={props.action} color="primary" variant="outline">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
