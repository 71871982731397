import { combineReducers } from "redux";
import alarmConfigsReducers from "../../pages/provisioning/alarmConfigs/reducers/alarmConfigsReducers";
import MenuReducers from "../../common/reducers/MenuReducers";
import dashboardSidebarReducers from "../../pages/dashboard/reducers/dashboardSidebarReducers";
import adminsReducers from "../../pages/provisioning/admins/reducers/adminsReducers";
import operatorsReducers from "../../pages/provisioning/operators/reducers/operatorsReducers";
import configurationsReducers from "../../pages/configurations/reducers/configurationsReducers";
import devicesReducers from "../../pages/provisioning/devices/reducers/devicesReducers";
import brhDevicesReducers from "../../pages/brhdevice/reducers/brhDevicesReducers";
import brhFirmwareReducers from "../../pages/brhfirmwares/reducers/brhFirmwareReducers";
import auditlogsReducers from "../../pages/auditlogs/reducers/auditlogsReducers";
import globalSettingsReducers from "../../pages/setting/reducers/settingReducers";
import CSVReducers from "../reducers/CSVReducers";
import NotificationTableReducers from "../reducers/NotificationTableReducers";
import LoaderRequestReducers from "../reducers/LoaderRequestReducers";

export default combineReducers({
  menuInfo: MenuReducers,
  dashboardSidebar: dashboardSidebarReducers,
  adminsInfo: adminsReducers,
  operatorsInfo: operatorsReducers,
  configurationsInfo: configurationsReducers,
  devicesInfo: devicesReducers,
  brhDevicesInfo: brhDevicesReducers,
  firmwareInfo: brhFirmwareReducers,
  alarmConfigsInfo: alarmConfigsReducers,
  auditlogsInfo: auditlogsReducers,
  globalSettingInfo: globalSettingsReducers,
  CSVInfo: CSVReducers,
  notificationTableInfo: NotificationTableReducers,
  LoaderRequestInfo: LoaderRequestReducers
});
