import React, {Component} from "react";
import {
  Dialog,
  DialogContent,
  Slide,
} from "@material-ui/core";

import AppBarComponent from "../../../../common/components/AppBarComponent";
import MapcontainerComponent from "../../../../common/components/MapContainer";

const initialState = {};

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

export default class GeolocationModal extends Component {
  state = { ...initialState };


  handleClose = () => {
    this.setState({ ...initialState });
    this.props.handleClose();
  };

  render() {
    const {
      open,
    } = this.props;
		return (
			<div>
				<Dialog
          fullScreen
          open={open}
          onClose={this.handleClose}
          TransitionComponent={Transition}
          className="popup-module"
          disableBackdropClick
				>
        <AppBarComponent
          icon={"fa fa-map-marker"}
          title={this.props.title}
          handleClose={this.handleClose}
        />
          <DialogContent className="dialogContent">
              <MapcontainerComponent devices={this.props.activeDevices} />
          </DialogContent>        
				</Dialog>
			</div>
		);
	}

}
