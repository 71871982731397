import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toast } from "react-toastify";

import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";

import "../../../pages/dashboard/styles/dashboard.css";
import { acceptEULAFromDashboard } from "../../../pages/dashboard/actions/dashboardActions";
import ContentWrapper from "../../../common/components/ContentWrapper";
import { fetchAlarmsData } from "../../../common/actions/NotificationTableActions";
import { LicenseAccepted } from "../../../common/actions/MenuActions";
import { signOutFunction } from "../../../common/actions/signoutActions";
import NotificationTable from "../../../common/components/NotificationTable";
import AlertDialogSlide from "../../../common/components/AlertDialog";

const user_id = localStorage.getItem("user_id");
const user_name = localStorage.getItem("username");
const alarm_url = "frontend/alarms/?user_id=" + user_id + "&user_name=" + user_name;

class DashboardComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      filterURL: alarm_url + "&page=1&alarm_state=0&",
      page: 1,
      intervalID: null,
			eulaAccepted: false,
			eulaAcceptDialogOpen: false,
			eulaRejectDialogOpen: false
    };
  }

  componentDidMount() {
     this.props.fetchAlarmsData();
     this.setPeriodicFetch();
  }

  setPeriodicFetch = () => {
    var intervalID = setInterval(() => {
      this.props.fetchAlarmsData(this.state.filterURL);
    }, 30 * 1000);
    this.setState({ intervalID });

  };

  changePage = page => {
    let pageLink =
      page > this.state.page ? this.props.nextLink : this.props.prevLink;
    this.setState({ page, filterURL: pageLink}, () => {
      this.props.fetchAlarmsData(pageLink);
      if (this.state.page !== 1) {
        clearInterval(this.state.intervalID);
      } else if (this.state.page === 1) {
        this.setPeriodicFetch();
      }
    });
  };

	getContentViewEULANotAccepted = () => {
    return (
      <div className="content-wrapper">
        <div className="content">
					<div className="col-lg-12 col-xs-6 bg-aqua text-center">
						<h1> Saankhya Labs End User Agreement </h1>
					</div>
					<div className="col-lg-12 col-xs-6 bg-aqua">
						<p>End User License Agreement</p>
					</div>
					<div className="col-lg-12 col-xs-6 bg-white pt2p-pb1p form-button-container">
						<Button
							variant="outlined"
							className="form-submit-button"
							onClick={this.handleOpenAcceptEULA}
							endIcon={<CheckIcon />}
						>
							Accept Agreement
						</Button>
						<Button
							variant="outlined"
							className="form-cancel-button"
							onClick={this.handleOpenRejectEULA}
							endIcon={<CloseIcon />}
						>
							Reject
						</Button>
					</div>
				</div>
				<AlertDialogSlide
					open={this.state.eulaAcceptDialogOpen}
					title="Accept EULA"
					desc="You are going to Accept End User License Agreement. By Clicking 'Yes', you agree, you have read the agreement and agree with the terms of the agreement. Click 'No' if you would like to review the therms and conditions again."
					handleClose={this.handleCloseAcceptEULA}
					action={this.props.acceptEULAFromDashboard}
				/>
				<AlertDialogSlide
					open={this.state.eulaRejectDialogOpen}
					title="Reject EULA"
					desc="Are you sure, you want to Reject End User License Agreement. If you click 'Yes', you will not be able to manage/monitor the devices and will be logged out."
					handleClose={this.handleCloseRejectEULA}
					action={this.rejectEULA}
				/>
			</div>
		)
	}

	handleOpenAcceptEULA = () => { this.setState({eulaAcceptDialogOpen: true}); }

	handleCloseAcceptEULA = () => { this.setState({eulaAcceptDialogOpen: false}); }

	handleOpenRejectEULA = () => { this.setState({eulaRejectDialogOpen: true}); }

	handleCloseRejectEULA = () => { this.setState({eulaRejectDialogOpen: false}); }

	rejectEULA = () => {
		toast("User Rejected License. Logging Out.", {
		autoClose: 5000,
		type: toast.TYPE.ERROR,
		position: toast.POSITION.TOP_CENTER,
		});
		setTimeout(() => {
			signOutFunction();
			}, 1.5 * 1000
		);
		return;
	}

  getContentViewEULAAccepted = () => {
    return (
      <div className="content-wrapper">
        <div className="content">
          <div className="row dashboard_boxs">
            <div className="col-lg-4 col-xs-6">
              <a href="/brhdevices">
                <div className="small-box bg-aqua">
                  <div className="inner">
                    <h3>{this.props.activeDevicesCount}</h3>
                    <h4>Active of {this.props.totalDevicesCount} Devices</h4>
                  </div>
                  <div className="icon">
                    <i className="fa fa-laptop" />
                  </div>
                </div>
              </a>
            </div>

            <div className="col-lg-4 col-xs-6">
                <div className="small-box bg-aqua">
                  <div className="inner">
                    <h3>{this.props.rebootsCount}</h3>
                    <h4>
                      Reboots across {this.props.totalDevicesCount} Devices
                    </h4>
                  </div>
                  <div className="icon">
                    <i className="fa fa-history" />
                  </div>
                </div>
            </div>

            <div className="col-lg-4 col-xs-6">
              <a href="/notifications">
                <div className="small-box bg-aqua">
                  <div className="inner">
                    <h3>{this.props.totalAlarmsCount} </h3>
                    <h4>
                      New Alarms across {this.props.totalDevicesCount} Devices
                    </h4>
                  </div>
                  <div className="icon">
                    <i className="fa fa-bell-o" />
                  </div>
                </div>
              </a>
            </div>
          </div>{" "}
          <NotificationTable
            filterURL={this.state.filterURL}
            page={this.state.page}
            changePage={this.changePage}
           />
        </div>{" "}
        {/*--- content ----*/}
      </div>
    );
  }

	getContentView = () => {
		if (this.props.eulaAccepted) {
			return this.getContentViewEULAAccepted();
		} else {
			return this.getContentViewEULANotAccepted();
		}
	}

  render() {
    return <ContentWrapper contentView={this.getContentView()} netError={this.props.netError} />;
  }
}

//Required to tell redux how this component is mapped with Menu reducers
function mapStateToProps({ menuInfo, notificationTableInfo }) {
  return {
    rebootsCount: menuInfo.rebootsCount,
    activeDevicesCount: menuInfo.activeDevicesCount,
    totalDevicesCount: menuInfo.totalDevicesCount,
    totalAlarmsCount: menuInfo.totalAlarmsCount,
		eulaAccepted: menuInfo.isEULAAccepted,
    netError: notificationTableInfo.netError,
    nextLink: notificationTableInfo.nextLink,
    prevLink: notificationTableInfo.prevLink
  };
}

//Required to tell redux this component using which actions
function mapDispatchToProps(dispatch) {
  return bindActionCreators({
			fetchAlarmsData ,
			LicenseAccepted,
			acceptEULAFromDashboard
		}, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(DashboardComponent);
//export default connect(mapStateToProps)(DashboardComponent);
