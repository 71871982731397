import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TextField from '@material-ui/core/TextField';
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import validator from 'validator';
import { toast } from 'react-toastify';

import ContentWrapper from '../../../common/components/ContentWrapper';
import {
  changeSidebarButtonState
} from '../../dashboard/actions/dashboardSidebarActions';

import {
  fetchGlobalSettingsUpgradeData,
  addGlobalConfigNBURL,
  editGlobalConfigNBURL,
  fetchGlobalSettingsNBData
 } from '../actions/settingActions';


class SettingsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      open: false,
      showEditGlobalConfigNBURL:false,
      helperTextURL:"",
      helperTextName:"",
      lastUpgradeInfo:null,
      lastNBInfo:null,
      globalSettingUpgradeInfo:[
        {
        id: "",
        path: "",
        protocol: "",
        name: "",
        server: "",
        username: "",
        created_at: "",
        is_default: true
      }
    ],
    globalSettingNBInfo:[
        {
          id: "",
          name: "",
          scheme: "",
          url: "",
          token: "",
          is_default: true
        }
      ],
    newglobalSettingNBData:{},
    editedFields:{},
    editable:false

    }
    this.getContentView = this.getContentView.bind(this);

    this.addGlobalConfigNBURL = this.addGlobalConfigNBURL.bind(this);
    this.editExistingGlobalConfigNBURL = this.editExistingGlobalConfigNBURL.bind(this);
    this.onChangeNBURL = this.onChangeNBURL.bind(this);
    this.onNameStringChange = this.onNameStringChange.bind(this);
  }

  componentDidMount () {
    this.props.changeSidebarButtonState('settings', 'SETTINGS_BTN_ACTIVE');
    this.props.fetchGlobalSettingsUpgradeData();
    this.props.fetchGlobalSettingsNBData();
  }

  addGlobalConfigNBURL(){
    this.props.addGlobalConfigNBURL(this.state.newglobalSettingNBData,
      (successResponse)=> {
        toast("Global Configuration Added Successfully", {
          autoClose: 5000,
          type: toast.TYPE.SUCCESS,
          position: toast.POSITION.BOTTOM_CENTER,
        });
        this.setState({
          ...this.state,
          open: false,
          showEditGlobalConfigNBURL: true
            }, ()=>{
              this.props.fetchGlobalSettingsUpgradeData();
              this.props.fetchGlobalSettingsNBData();
            });
        },
        (errorResponse)=> {
          toast("Global Configuration Failed", {
            autoClose: 5000,
            type: toast.TYPE.ERROR,
            position: toast.POSITION.TOP_CENTER,
          });
        }
      );
  }


  onChangeNBURL(event){
   var nbURLVal = validator.isURL(event.target.value);

   if(nbURLVal===false){
      this.setState({...this.state,
        newglobalSettingNBData:{...this.state.newglobalSettingNBData,
            url:event.target.value
          },
        helperTextURL: "Enter Valid URL"
      });
    } else {
      this.setState({...this.state,
        newglobalSettingNBData:{...this.state.newglobalSettingNBData,
          url:event.target.value
        },
        helperTextURL: ""
    });
    }
  }

  onNameStringChange (event){
    var nbNameVal = validator.isEmpty(event.target.value);

   if(nbNameVal===true){
      this.setState({...this.state,
          newglobalSettingNBData:{...this.state.newglobalSettingNBData,
            url:event.target.value
          },
        helperTextName: "Name Should not be empty"
      });
    } else {
      this.setState({...this.state,
        newglobalSettingNBData:{...this.state.newglobalSettingNBData,
          name:event.target.value
        },
        helperTextName: ""
    });
    }
  }
  editExistingGlobalConfigNBURL(id){
     this.props.editGlobalConfigNBURL(
      id,
      this.state.newglobalSettingNBData,
      (successResponse)=> {
        toast("NB Configuration Updated Successfully.", {
          autoClose: 5000,
          type: toast.TYPE.SUCCESS,
          position: toast.POSITION.BOTTOM_CENTER,
        });
        this.setState({
          ...this.state,
          open: false,
          showEditAlarm: false
            }, ()=>{
              this.props.fetchGlobalSettingsUpgradeData();
              this.props.fetchGlobalSettingsNBData();
            });
        },
        (errorResponse)=> {
          toast("NB Configuration Update Failed", {
            autoClose: 5000,
            type: toast.TYPE.ERROR,
            position: toast.POSITION.TOP_CENTER,
          });
        }
      );
  }

  getContentView() {
    const upgradeInfoRows = this.props.globalSettingUpgradeInfo;
    let lastUpgradeInfo = upgradeInfoRows[0];
    let nbInfoRows = this.props.globalSettingNBInfo;

    var lastNBInfo;
    if (nbInfoRows.length === 0) {
        lastNBInfo = { 'name': '', 'url' : ''};
    } else {
        lastNBInfo = nbInfoRows[nbInfoRows.length - 1];
    }
    return (
      <div className="content-wrapper">
        <div className="content">
          <div className="row main_top_headding">
            <div className="col-md-4 col-sm-4 col-xs-0"></div>
            <div className="col-md-4 col-sm-4 col-xs-6 main_top_headding_mid">
              <h4> <i className="fa fa-wrench" aria-hidden="true"></i> &nbsp; Global Settings</h4>
            </div>
            <div className="col-md-4 col-sm-4 col-xs-6 main_top_headding_right">
            </div>
          </div>{/*<!-- row ---> */}

          <div className="space"></div>

          <div className="row common_div_row">
            <div className="col-md-4 col-sm-0"></div>

            <div className="col-md-4 col-sm-12 settingpage">
              <form className="dialogContent-form">
                <Accordion defaultActiveKey="0">
                  <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                      <p className="phead"><b>BRH Upgrade</b>
                        <span className="pull-right glyphicon glyphicon-triangle-bottom caret-icon"></span>
                      </p>
                    </Accordion.Toggle>

                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        <br/>
                        <TextField
                          className="outlined-full-width"
                          select
                          label="Method"
                          style={{ marginBottom: 10}}
                          margin="normal"
                          variant="outlined"
                          SelectProps={{
                            MenuProps: {
                            },
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          disabled
                        >
                        <option value={lastUpgradeInfo.protocol}>{lastUpgradeInfo.protocol}</option>
                        </TextField>

                        <TextField
                          className="outlined-full-width"
                          label="Server"
                          style={{ marginBottom: 10}}
                          margin="normal"
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          disabled
                          value={lastUpgradeInfo.server}
                        />

                        <TextField
                          className="outlined-full-width"
                          label="Username"
                          style={{ marginBottom: 10}}
                          margin="normal"
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          disabled
                          value={lastUpgradeInfo.username}
                        />
                        <br/> <br/>
                      </Card.Body>

                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="1">
                      <p className="phead"><b>Keep Alive</b>
                        <span className="pull-right glyphicon glyphicon-triangle-bottom caret-icon"></span>
                      </p>
                    </Accordion.Toggle>

                    <Accordion.Collapse eventKey="1">
                      <Card.Body>
                        <br/>
                        <TextField
                          className="outlined-full-width"
                          label="Interval"
                          style={{ marginBottom: 10}}
                          margin="normal"
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={10}
                          disabled
                        />

                        <TextField
                          className="outlined-full-width"
                          label="Retries"
                          style={{ marginBottom: 10}}
                          margin="normal"
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={3}
                          disabled
                        />
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>


                  <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="2">
                      <p className="phead"><b>NMS Interface</b>
                        <span className="pull-right glyphicon glyphicon-triangle-bottom caret-icon"></span>
                      </p>
                    </Accordion.Toggle>

                    <Accordion.Collapse eventKey="2">
                      <Card.Body>
                        <br/>
                        <TextField
                          className="outlined-full-width"
                          label="Name"
                          placeholder={lastNBInfo === undefined? "":lastNBInfo.name}
                          style={{ marginBottom: 10}}
                          margin="normal"
                          variant="outlined"
                          helperText={this.state.helperTextName}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={this.state.newglobalSettingNBData.name}
                          onChange={this.onNameStringChange}
                        >
                        </TextField>
                          <TextField
                          id="drop_downselect"
                          select
                          label="Method"
                          style={{ marginBottom: 10}}
                          margin="normal"
                          variant="outlined"
                          SelectProps={{
                            MenuProps: {
                              // className: classes.menu,
                            },
                          }}
                          disabled
                        >
                        <option> HTTP/POST </option>
                        </TextField>

                        <TextField
                          className="outlined-full-width"
                          label="URL"
                          placeholder={lastNBInfo === undefined? "":lastNBInfo.url}
                          style={{ marginBottom: 10}}
                          helperText={this.state.helperTextURL}
                          margin="normal"
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={this.state.newglobalSettingNBData.url}

                          onChange={this.onChangeNBURL}
                          InputProps={{
                            endAdornment: (
                            <InputAdornment position="end" className="passwordicon">
                                <IconButton
                                    aria-label="Toggle Add Edit URL"
                                    // onClick={(lastNBInfo.url) ? this.editExistingGlobalConfigNBURL : this.addGlobalConfigNBURL}

                                    onClick={
                                      (lastNBInfo.url) ? (
                                      ()=>
                                    this.editExistingGlobalConfigNBURL(lastNBInfo.id)):this.addGlobalConfigNBURL
                                    }
                                >
                                {(lastNBInfo.url) ? <span className="glyphicon glyphicon-edit"></span> : <span className="glyphicon glyphicon-plus"></span> }
                                </IconButton>
                            </InputAdornment>
                            ),
                        }}
                        />
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </form>
            </div>

            <div className="col-md-4 col-sm-0"></div>
          </div>
        </div> {/*<!-- content ---> */}
      </div>
    );
  }

  render() {
    return (
      <ContentWrapper
        contentView={this.getContentView()}
      />
    );
  }
}

function mapStateToProps(state) {
    return {
      globalSettingUpgradeInfo: state.globalSettingInfo.globalSettingUpgradeInfo,
      globalSettingNBInfo: state.globalSettingInfo.globalSettingNBInfo
    }
  }
function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    changeSidebarButtonState,
    addGlobalConfigNBURL,
    fetchGlobalSettingsUpgradeData,
    fetchGlobalSettingsNBData,
    editGlobalConfigNBURL
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsComponent);
