const initialState = {
  action: "",
  brhDevicesInfo: [],
  firmwareInfo: [],
  statisticsInfo: [],
  historicalInfo: [],
  snapshotsInfo: [],
  configurationsInfo: [],
  netError: null
};
export default (state = initialState, action) => {
  let currentState = state;
  switch (action.type) {
    // success actions
    case "FETCH_BRHDEVICES_DATA_SUCCESS":
      currentState = {
        ...state,
        brhDevicesInfo: action.payload,
        netError: null
      };
      break;
    case "FETCH_SINGLE_CONFIGURATION_DATA_SUCCESS":
      currentState = {
        ...state,
        configurationsInfo: action.payload,
        netError: null
      };
      break;
    case "FETCH_FIRMWARE_GET_DATA_SUCCESS":
      currentState = { ...state, firmwareInfo: action.payload };
      break;
    case "FETCH_DEVICESTATS_GET_DATA_SUCCESS":
      currentState = {
        ...state,
        action: "FETCH_DEVICESTATS_GET_DATA_SUCCESS",
        statisticsInfo: action.payload
      };
      break;
    case "FETCH_HISTORICAL_DEVICESTATS_GET_DATA_SUCCESS":
      currentState = {
        ...state,
        action: "FETCH_HISTORICAL_DEVICESTATS_GET_DATA_SUCCESS",
        historicalInfo: action.payload
      };
      break;
    case "FETCH_SNAPSHOTS_DATA_SUCCESS":
      currentState = {
        ...state,
        snapshotsInfo: action.payload
      };
      break;

    // error actions
    case "FETCH_BRHDEVICES_DATA_FAILURE":
      currentState = { ...state, netError: action.error };
      break;

    default:
  }
  return currentState;
};
