
const initialState = {
    globalSettingUpgradeInfo:
        [
            {
                "id": 2,
                "path": "/firmwares/",
                "protocol": "ftp",
                "name": "FTP Config",
                "server": "ftp.saankhyalabs.com",
                "username": "ftpuser",
                "created_at": "2019-07-17T09:13:12.776776Z",
                "is_default": true
              },
              {
                "id": 2,
                "path": "/firmwares/",
                "protocol": "ftp",
                "name": "FTP Config",
                "server": "ftp.saankhyalabs.com",
                "username": "ftpuser",
                "created_at": "2019-07-17T09:13:12.776776Z",
                "is_default": true
              }
          ],
    globalSettingNBInfo:[
        {
            "id": 1,
            "name": "Default",
            "scheme": "http",
            "url": "http://localhost:5000/emswebhook",
            "token": "",
            "is_default": true
          },
          {
            "id": 1,
            "name": "Default",
            "scheme": "http",
            "url": "http://localhost:5000/emswebhook",
            "token": "",
            "is_default": true
          }
        ]
};

export default  (state = initialState, action) => {
    let currentState = state;
    switch(action.type) {
        case 'FETCH_GLOBAL_SETTINGS_UPGRADE_DATA_SUCCESS':
            currentState = {...state,
                globalSettingUpgradeInfo: action.payload,
            };
        break;

        case 'FETCH_GLOBAL_SETTINGS_NB_DATA_SUCCESS':
            currentState = {...state,
                globalSettingNBInfo: action.payload,
            };
        break;

        default:
    }
    return currentState;
}
