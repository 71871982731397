import HTTPRequestHandler from "../../utils/HTTPRequestHandler";
const httpRequestHandler = new HTTPRequestHandler();
const user_id = localStorage.getItem("user_id");
const user_name = localStorage.getItem("username");
const user_suffix = "?user_id=" + user_id + "&user_name=" + user_name;


const urlInit = "provisioning/";
let url = "";

export function fetchUsersData(user) {
  url = urlInit + user + "/";

  return function(dispatch) {
    httpRequestHandler
      .get(url + user_suffix)
      .then(response => {
        dispatch({
          type:
            user === "admins"
              ? "FETCH_ADMINS_DATA_SUCCESS"
              : "FETCH_OPERATORS_DATA_SUCCESS",
          payload: response.data
        });
      })
      .catch(error => {
        dispatch({
          type:
            user === "admins"
              ? "FETCH_ADMINS_DATA_FAILURE"
              : "FETCH_OPERATORS_DATA_FAILURE",
          error
        });
      });
  };
}

export function addUser(newUser, successCallback, errorCallback) {
  return function(dispatch) {
    httpRequestHandler
      .post(url + user_suffix, newUser)
      .then(response => {
        successCallback(response.data);
      })
      .catch(error => {
        errorCallback(error);
      });
  };
}

export const editUser = (id, existingUser, successCallback, errorCallback) => {
  return function(dispatch) {
    httpRequestHandler
      .patch(url + id + "/" + user_suffix, existingUser)
      .then(response => {
        successCallback(response.data);
      })
      .catch(error => {
        errorCallback(error);
      });
  };
};

export function deleteUserIdData(deleteData, successCallback, errorCallback) {
  return function(dispatch) {
    httpRequestHandler
      .delete(url + deleteData + "/" + user_suffix)
      //.delete(url + deleteData + "/")
      .then(response => {
        successCallback(response.data);
      })
      .catch(error => {
        errorCallback(error);
      });
  };
}

export function adminsList() {
  return function(dispatch) {
    httpRequestHandler
      .get(url)
      .then(response => {
        dispatch({
          type: "FETCH_ADMINS_DATA_SUCCESS",
          payload: response.data
        });
      })
      .catch(error => console.error(error.response));
  };
}

export function toggleUserLogin(userID, successCallback) {
  return function(dispatch) {
    httpRequestHandler
      .patch("loginallowed/"+ user_suffix, { id: userID })
      .then(response => {
        successCallback(response.data);
      })
      .catch(error => {
        console.error(error.response);
      });
  };
}
