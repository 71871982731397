import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { toast } from "react-toastify";

import ContentWrapper from "../../../../common/components/ContentWrapper";
import { changeSidebarButtonState } from "../../../dashboard/actions/dashboardSidebarActions";

import { fetchAlarmsData, editAlarms } from "../actions/alarmConfigsActions";
import { getSeverity } from "../../../../common/constants/constants";
import AppBarComponent from "../../../../common/components/AppBarComponent";
import { TableContainer } from "@material-ui/core";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class AlarmConfigsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      editAlarm: {},
      editedFields: {}
    };
  }

  componentDidMount() {
    this.props.changeSidebarButtonState("alarms", "ALARMS_BTN_ACTIVE");
    this.props.fetchAlarmsData();
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ ...this.state, open: false });
  };

  onSeverityChange = event => {
    this.setState({
      ...this.state,
      editAlarm: {
        ...this.state.editAlarm,
        alarm_config_level: event.target.value
      },
      editedFields: {
        ...this.state.editedFields,
        alarm_config_level: event.target.value
      }
    });
  };

  onAlarmAcknowledgeChange = event => {
    this.setState({
      ...this.state,
      editAlarm: {
        ...this.state.editAlarm,
        alarm_config_autoack: event.target.value
      },
      editedFields: {
        ...this.state.editedFields,
        alarm_config_autoack: event.target.value
      }
    });
  };

  showEditAlarmsDialog = editAlarm => {
    this.setState({
      ...this.state,
      open: true,
      editAlarm
    });
  };

  editExistingAlarm = () => {
    this.props.editAlarms(
      this.state.editAlarm.id,
      this.state.editedFields,
      successResponse => {
        toast("Alarm Updated Successfully.", {
          autoClose: 5000,
          type: toast.TYPE.SUCCESS,
          position: toast.POSITION.BOTTOM_CENTER
        });
        this.setState(
          {
            ...this.state,
            open: false
          },
          () => {
            this.props.fetchAlarmsData();
          }
        );
      },
      errorResponse => {
        toast("Alarm Update Failed", {
          autoClose: 5000,
          type: toast.TYPE.ERROR,
          position: toast.POSITION.TOP_CENTER
        });
      }
    );
  };

  getContentView = () => {
    const alarms = this.props.alarmConfigsInfo;
    var isOwner = localStorage.getItem("roleKey") !== "owner" ? true : false;

    return (
      <div className="content-wrapper">
        <div className="content">
          <div>
            <div className="row main_top_headding">
              <div className="col-md-11 main_top_headding_mid ">
                <h4>
                  <i className="fa fa-bell-o" aria-hidden="true"></i> &nbsp;
                  Alarm Configurations
                </h4>
              </div>
            </div>
            <div className="row main_popup">
              <Dialog
                fullScreen
                open={this.state.open}
                onClose={this.handleClose}
                TransitionComponent={Transition}
                className="popup-module"
                disableBackdropClick
              >
                <AppBarComponent
                  icon={"fa fa-bell-o"}
                  title={"Alarm Configuration"}
                  handleClose={this.handleClose}
                />

                <DialogContent className="dialogContent">
                  <div className="col-md-4 col-sm-0"></div>
                  <div className="col-md-4 col-sm-12">
                    <form className="dialogContent-form">
                      <TextField
                        label="Alarm Type"
                        style={{ marginBottom: 8 }}
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        value={this.state.editAlarm.alarm_config_type}
                        disabled
                      />

                      <TextField
                        label="Name"
                        style={{ marginBottom: 8 }}
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        value={this.state.editAlarm.alarm_config_name}
                        disabled={true}
                      />

                      <TextField
                        label="Source"
                        style={{ marginBottom: 10 }}
                        margin="normal"
                        variant="outlined"
                        SelectProps={{ MenuProps: {} }}
                        InputLabelProps={{ shrink: true }}
                        value={this.state.editAlarm.alarm_config_source}
                        disabled
                      />

                      <TextField
                        multiline
                        label="Info"
                        style={{ marginBottom: 8 }}
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        value={this.state.editAlarm.alarm_config_info}
                        disabled
                      />

                      <TextField
                        select
                        label="Severity"
                        style={{ marginBottom: 10 }}
                        margin="normal"
                        variant="outlined"
                        SelectProps={{
                          MenuProps: {}
                        }}
                        InputLabelProps={{ shrink: true }}
                        onChange={this.onSeverityChange}
                        value={this.state.editAlarm.alarm_config_level}
                      >
                        <option value="0">Info</option>
                        <option value="1">Warning</option>
                        <option value="2">Minor</option>
                        <option value="3">Major</option>
                        <option value="4">Critical</option>
                      </TextField>

                      <TextField
                        select
                        label="Auto Acknowledge"
                        style={{ marginBottom: 10 }}
                        margin="normal"
                        variant="outlined"
                        SelectProps={{
                          MenuProps: {}
                        }}
                        InputLabelProps={{ shrink: true }}
                        onChange={this.onAlarmAcknowledgeChange}
                        value={this.state.editAlarm.alarm_config_autoack}
                      >
                        <option value={false}>False</option>
                        <option value={true}>True</option>
                      </TextField>

                      {!isOwner ? (
                        <div className="form-button-container" >
                        <Button
                          variant="outlined"
                          className="form-submit-button"
                          onClick={this.editExistingAlarm}
                        >
                          Edit Alarm
                        </Button>
                        <Button
                          variant="outlined"
                          className="form-cancel-button"
                          onClick={this.handleClose}
                        >
                          Cancel
                        </Button>
                        </div>
                      ) : null}
                    </form>
                  </div>
                  <div className="col-md-4 col-sm-0"></div>
                </DialogContent>
              </Dialog>
            </div>


            <div className="row common_div_row">
              <Paper>
                <TableContainer className="table-all table-responsive">
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center" className="text-wrap-head">Alarm Type</TableCell>
                        <TableCell align="left" className="text-wrap-head">Alarm Name</TableCell>
                        <TableCell align="left" className="text-wrap-head">Source</TableCell>
                        <TableCell align="left" className="text-wrap-head">Severity</TableCell>
                        <TableCell align="center" className="text-wrap-head">Auto Ack</TableCell>
                        <TableCell align="center" className="text-wrap-head">Details</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {alarms.length > 0 &&
                        alarms.map(row => (
                          <TableRow key={row.id}>
                            <TableCell align="center" className="text-wrap">
                              {row.alarm_config_type}
                            </TableCell>
                            <TableCell align="left" className="text-wrap">
                              {row.alarm_config_name}
                            </TableCell>
                            <TableCell align="left" className="text-wrap">
                              {row.alarm_config_source}
                            </TableCell>
                            <TableCell align="left" className="text-wrap">
                              {getSeverity(row.alarm_config_level)}
                            </TableCell>
                            <TableCell align="center" className="text-wrap">
                              {row.alarm_config_autoack ? "True" : "False"}
                            </TableCell>
                            <TableCell align="center" className="text-wrap">
                              <button
                                type="button"
                                className="btn btn-outline-secondary"
                                onClick={() => {
                                  this.showEditAlarmsDialog(row);
                                }}
                                disabled={false}
                              >
                                {" "}
                                <span className="glyphicon glyphicon-edit"></span>
                              </button>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <ContentWrapper
        contentView={this.getContentView()}
        netError={this.props.netError}
      />
    );
  }
}

// //Required to tell redux how this component is mapped with dashboard reducers
function mapStateToProps(state) {
  return {
    alarmConfigsInfo: state.alarmConfigsInfo.alarmConfigsInfo,
    netError: state.alarmConfigsInfo.netError
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      changeSidebarButtonState,
      fetchAlarmsData,
      editAlarms,
      getSeverity
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AlarmConfigsComponent);
