import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import LoaderRequest from "./LoaderRequest";

const AppBarComponent = props => {
  return (
    <AppBar position="relative">
      <Toolbar>
        <Typography variant="h6" className="appbar-title">
          <i className={props.icon} aria-hidden="true" />
          &nbsp; {props.title}
        </Typography>
        <Button onClick={props.handleClose}>
          <i className="fa fa-times closebts" aria-hidden="true" />
        </Button>
      </Toolbar>

      <LoaderRequest />
    </AppBar>
  );
};

export default AppBarComponent;
