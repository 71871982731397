const initialState = {
  notifications: [],
  nextLink: null,
  prevLink: null,
  count: 0,
  netError: null
};

export default (state = initialState, action) => {
  let currentState = state;
  switch (action.type) {
    case "FETCH_ALARMS_DATA_SUCCESS":
      currentState = {
        ...state,
        notifications: action.append
          ? [...state.notifications, ...action.payload]
          : action.payload,
        nextLink: action.nextLink,
        prevLink: action.prevLink,
        count: action.count,
        netError: null
      };
      break;

    case "FETCH_ALARMS_DATA_FAILURE":
      currentState = {
        ...state,
        netError: action.error
      };
      break;

    default:
  }
  return currentState;
};
