import React, { Component } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";

class StatisticsRowTable extends Component {
  render() {
    const { tableHeader, tableHeader1, data, alarmKeys } = this.props;

    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className="pheading" colSpan="1">
              {tableHeader}
            </TableCell>
            <TableCell className="pheading" colSpan="1" style={{color:'red'}}>
              {tableHeader1}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(data).map((row) => {
            const [label, value] = row;
            return (
              <TableRow key={label}>
                <TableCell
                  className={
                    alarmKeys && alarmKeys.includes(label) ? "alarm" : ""
                  }
                  style={{ width: "50%" }}
                >
                  {label}
                </TableCell>
                <TableCell
                  className={
                    alarmKeys && alarmKeys.includes(label) ? "alarm" : ""
                  }
                >
                  <strong>{value}</strong>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  }
}

export default StatisticsRowTable;
