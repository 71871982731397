import HTTPRequestHandler from '../../../utils/HTTPRequestHandler';
const httpRequestHandler = new HTTPRequestHandler();
const user_id = localStorage.getItem("user_id");
const user_name = localStorage.getItem("username");
const config_suffix_url = "?user_id=" + user_id + "&user_name=" + user_name;
const provisioning_configs = 'provisioning/configs/'

export function getConfigurationData(configuration_id) {
    return (function (dispatch) {
        httpRequestHandler
        .get(provisioning_configs + configuration_id + "/" + config_suffix_url).then((response) => {
            dispatch({
                type: 'FETCH_SINGLE_CONFIGURATION_DATA_SUCCESS',
                payload: response.data
            });
        }).catch((error) => {
            console.error('Error', error);
            dispatch({
                type: 'FETCH_SINGLE_CONFIGURATION_DATA_FAILURE',
                error
            });
        })
    });
};

export function fetchConfigurationData() {
    return (function (dispatch) {
        httpRequestHandler
        .get(provisioning_configs + config_suffix_url).then((response) => {
            dispatch({
                type: 'FETCH_CONFIGURATION_DATA_SUCCESS',
                payload: response.data
            });
        }).catch((error) => {
            console.error('Error', error);
            dispatch({
                type: 'FETCH_CONFIGURATION_DATA_FAILURE',
                error
            });
        })
    });
};

export function addConfiguration(newConfigurations, successCallback, errorCallback) {
    return (function (dispatch) {
        httpRequestHandler
        .post(provisioning_configs + config_suffix_url, newConfigurations)
        .then((response) => {
           successCallback(response.data)
        })
        .catch((error) => {
          errorCallback(error)
        })
    });
};

export function editConfiguration(id, editConfiguration, successCallback, errorCallback) {
    return (function (dispatch) {
        httpRequestHandler
        .patch(provisioning_configs + id + '/' + config_suffix_url, editConfiguration)
        .then((response) => {
            successCallback(response.data)

        })
        .catch((error) => {
            console.error('Error ', error);
            errorCallback(error)
        })
    });
}

export function deletConfigTabelRow(deleteRowId, successCallback, errorCallback) {
    return (function (dispatch) {
        httpRequestHandler
        .delete(provisioning_configs + deleteRowId + '/' + config_suffix_url)
        .then((response) => {
            successCallback(response.data)
        })
        .catch((error) => {
            errorCallback(error)
        })
    });
}
