import validator from "validator";

const supported_severities = ["INFO", "WARNING", "MINOR", "MAJOR", "CRITICAL"];

export function isValidInput(rawSearchString) {
  let valid_keys = ["macid", "severity", "source", "last"];
  // let search_string = searchString.replace(/\s/g, '');
  let searchString = rawSearchString.trim();
  let queryStrings = [];
  let apiResponse = { errorMessage: "", inputValid: true, searchValue: "" };

  if (searchString.length === 0) {
    return apiResponse;
  }
  let tokens = searchString.split(",");
  for (let token of tokens) {
    if (token.trim().length === 0) {
      continue;
    }

    let kvs = token.split("=");

    if (kvs.length !== 2) {
      let errorMessage =
        "Invalid input, valid input format is macid=<mac Address>,severity=<severity>,source=<source>,last=<1d/1h>";
      return {
        ...apiResponse,
        errorMessage: errorMessage,
        inputValid: false,
        searchValue: ""
      };
    }

    let key = kvs[0].trim();
    let val = kvs[1].trim();

    if (valid_keys.indexOf(key) === -1) {
      let errorMessage =
        key +
        '" is not a supported search item. Supported items are ' +
        valid_keys.join();
      return {
        ...apiResponse,
        errorMessage: errorMessage,
        inputValid: false,
        searchValue: ""
      };
    }

    let validatedKeyValue = validateKeyValue(key, val);

    if (validatedKeyValue) {
      let errorMessage = validatedKeyValue;
      return {
        ...apiResponse,
        errorMessage: errorMessage,
        inputValid: false,
        searchValue: ""
      };
    } else {
      let mapped = toApiParams(key);
      if (mapped) {
        if (key === "macid" || key === "source" || key === "last") {
          val = val.toUpperCase();
        }
        if (key === "last") {
          if (val.slice(-1) === "D") {
            val = parseInt(val) * 24;
          } else {
            val = parseInt(val);
          }

					if (val.toString() === "NaN" || val > 720) {
						return {
							...apiResponse,
							errorMessage: "Invalid number of days / hours it should be Nd/Nh format. Max interval:  30d(720h).",
							inputValid: false,
							searchValue: "",
						}
					}
        }
        if (key === "severity") {
          val = val.toUpperCase();
          val = supported_severities.indexOf(val);

        }
        let query_string = mapped + "=" + val;
        queryStrings = queryStrings.concat(query_string);
      }
    }
  }
  return {
    ...apiResponse,
    errorMessage: "",
    inputValid: true,
    searchValue: queryStrings.join("&")
  };
}

function validateKeyValue(key, value) {
  let keyValueErrorMessage = null;
  if (key === "macid") {
    let macValid = validator.isMACAddress(value);
    if (macValid === false) {
      keyValueErrorMessage = "macId value " + value + " is invalid.";
    }
  }

  // FIXME: Get supported severities from constants
  if (key === "severity") {
    let severity = value.toUpperCase()
    if (supported_severities.indexOf(severity) === -1) {
      keyValueErrorMessage =
          "Severity value is not supported. Supported values are:" +
          supported_severities.join();
      }
    }

  // FIXME: Get supported sources from constants
  if (key === "source") {
    value = value.toUpperCase()
    let supported_sources = ["DVBS2", "MPE", "STL_TP", "OTHER", "OAM", "GLOBAL", "GPS", "EMS", "ATSC3"];
    if (supported_sources.indexOf(value) === -1) {
      keyValueErrorMessage = "Source value is not supported. Supported values are:" +
          supported_sources.join();
    }
  }

  if (key === "last") {
    const validFormats = ["D", "H"];
    if (validFormats.indexOf(value.slice(-1).toUpperCase()) === -1) {
      keyValueErrorMessage = "suffix hours(h/H) or days(d/D) ";
    }
  }
  if (!key || !value) {
    keyValueErrorMessage = "Empty Key or Value is not supported";
  }
  return keyValueErrorMessage;
}

function toApiParams(key) {
  let map = {
    severity: "alarm_level",
    source: "alarm_source",
    macid: "alarm_device_id",
    last: "last_hours"
  };
  return map[key];
}
