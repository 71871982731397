import { connect } from "react-redux";
import React from "react";
import {
  Dialog,
  DialogContent,
  Table,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Paper,
  Link,
  TableContainer
} from "@material-ui/core";
import AppBarComponent from "../../../../common/components/AppBarComponent";
import {
  fetchSnapshotsAPI,
  deleteSnapshotAPI
} from "../../actions/brhdeviceActions";
import { toast } from "react-toastify";

import {BASE_URL} from "../../../../utils/HTTPRequestHandler";

const initialState = {};

class Snapshots extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState
    };
  }
  componentDidMount() {
    if(this.props.device) {
      this.props.fetchSnapshotsAPI(this.props.device.provisioned_device.id);
    }
  }

  handleClose = () => {
    this.props.handleClose();
  };

  deleteSnapshot = snapshotId => {
    deleteSnapshotAPI(
      snapshotId,
      successResponse => {
        toast("Snapshot Deleted Successfully", {
          autoClose: 5000,
          type: toast.TYPE.SUCCESS,
          position: toast.POSITION.TOP_CENTER
        });
        if (this.props.device) {
          this.props.fetchSnapshotsAPI(this.props.device.provisioned_device.id);
        }
      },
      errorResponse => {
        toast("Snapshot Delete Failed", {
          autoClose: 5000,
          type: toast.TYPE.ERROR,
          position: toast.POSITION.TOP_CENTER
        });
      }
    );
  };

  showDownloadAction = (data, filename) => {
        let snapshotname = filename || 'snapshot.tgz';
        const link = document.createElement('a');
        link.setAttribute('download', snapshotname);
        document.body.appendChild(link);
  };

  render() {
    const { snapshots } = this.props;
    const dev = this.props.device;
    const dev_serial_no = (dev ? dev.provisioned_device.serial_no: "Unknown");

    return (
      <div>
        <Dialog
          fullScreen
          open={this.props.open}
          onClose={this.handleClose}
          TransitionComponent={this.props.Transition}
          className="popup-module"
        >
          <AppBarComponent
            icon={"fa fa-camera"}
            title={`Device Snapshots (${dev_serial_no})`}
            handleClose={this.handleClose}
          />

          <DialogContent className="dialogContent">
            <div className="row common_div_row">
              <Paper>
                <TableContainer className="table-all  table-responsive">
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell align="left" className="text-wrap-head">
                          Snapshot Name
                        </TableCell>
                        <TableCell align="center" className="text-wrap-head">
                          Download
                        </TableCell>
                        <TableCell align="center" className="text-wrap-head">
                          Delete
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {snapshots.length > 0 &&
                        snapshots.map(row => (
                          <TableRow key={row.id}>
                            <TableCell align="left" className="text-wrap">
                              {row.filename}
                            </TableCell>
                            <TableCell align="center" className="text-wrap">
                            <Link href={BASE_URL + "/frontend/snapshots/" + row.id + "/download/"}>
                                <span className="glyphicon glyphicon-download-alt"></span>
                            </Link>
                            </TableCell>
                            <TableCell align="center" className="text-wrap">
                              <button
                                type="button"
                                className="btn btn-outline-secondary"
                                onClick={() => {
                                  this.deleteSnapshot(row.id);
                                }}
                              >
                                <span className="glyphicon glyphicon-trash"></span>
                              </button>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </div>
            {/*<!-- row ---> */}
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    snapshots: state.brhDevicesInfo.snapshotsInfo
  };
};

const mapDispatchToProps = {
  fetchSnapshotsAPI
};

export default connect(mapStateToProps, mapDispatchToProps)(Snapshots);
