import React from "react";

import MONITOREDICON from "../../statusicons/icon_monitored.svg";
import UNMONITORED from "../../statusicons/icon_unmonitored.svg";
import WARNINGICON from "../../statusicons/warning.svg";
import PROVISIONEDICON from "../../statusicons/icon_provisioned.svg";
import INSTALLATIONICON from "../../statusicons/icon_installation.svg";

import GRAY from "../../statusicons/icon_gray.svg";
import GREEN from "../../statusicons/icon_green.svg";
import RED from "../../statusicons/icon_red.svg";
import LTORANGE from "../../statusicons/icon_lightorange.svg";
import YELLOW from "../../statusicons/icon_yellow.svg";
import BLUE from "../../statusicons/icon_blue.svg";

export const ADMIN_ROLE = "saankhya";
export const OWNER_ROLE = "emsuser";
export const OPERATOR_ROLE = "emsuser";

export const getStatus = stateId => {
  if (stateId === -1) {
    return <img src={WARNINGICON} height={10} className="ml10" alt="" />;
  } else if (stateId === 0) {
    return <img src={PROVISIONEDICON} height={10} className="ml10" alt="" />;
  } else if (stateId === 1) {
    return <img src={UNMONITORED} height={10} className="ml10" alt="" />;
  } else if (stateId === 2) {
    return <img src={INSTALLATIONICON} height={10} className="ml10" alt="" />;
  } else if (stateId === 3) {
    return <img src={MONITOREDICON} height={10} className="ml10" alt="" />;
  }
};

export const getSeverity = severityId => {
  let severityObj = getSeverityObj(severityId);

  return (
    <>
      <img src={severityObj.icon} height={10} alt="" />
      <span style={{ margin: 10 }} />
      {severityObj.text}
    </>
  );
};

export const getSeverityObj = severityId => {
  let severityToUi = {
    0: {
      icon: GREEN,
      text: "Info"
    },
    1: {
      icon: BLUE,
      text: "Warning"
    },
    2: {
      icon: YELLOW,
      text: "Minor"
    },
    3: {
      icon: LTORANGE,
      text: "Major"
    },
    4: {
      icon: RED,
      text: "Critical"
    }
  };

  let severityObj = severityToUi[severityId];
  if (!severityObj) {
    severityObj = { icon: { GRAY }, text: "Unknown" };
  }

  return severityObj;
};


export const getAlarmStateString = level => {
  let levelObj = {
    0: "Not Acknowledged",
    1: "Acknowledged",
    2: "Cleared"
  };

  return (levelObj[level] || "Undefined");
};

export const SpecialCharsRegEx = /[!@#$%^&*()\-+]/;
export const ExcludeSpecialCharsRegEx = /[',"/?;[\]<>:{}_=~`.|]/;

export const COPYRIGHT =
  `Copyright \u00A9 2019 - ${new Date().getFullYear()} Saankhya Labs Private Limited, All rights reserved.`;
