const initialState = {
  auditlogsInfo: [],
  nextLink: null,
  netError: null
};

export default (state = initialState, action) => {
  let currentState = state;
  switch (action.type) {
    case "FETCH_AUDITLOGS_DATA_SUCCESS":
      currentState = {
        ...state,
        auditlogsInfo: action.append
          ? [...state.auditlogsInfo, ...action.payload]
          : action.payload,
        nextLink: action.nextLink,
        netError: null
      };
      break;

    case "FETCH_AUDITLOGS_DATA_FAILURE":
      currentState = { ...state, netError: action.error };
      break;

    default:
  }
  return currentState;
};
