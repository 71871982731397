import HTTPRequestHandler from '../../../utils/HTTPRequestHandler'
const httpRequestHandler = new HTTPRequestHandler();

export function fetchGlobalSettingsUpgradeData() {
    return (function (dispatch) {
        httpRequestHandler
        .get('frontend/upgradeconfigs/').then((response) => {
            dispatch({
                type: 'FETCH_GLOBAL_SETTINGS_UPGRADE_DATA_SUCCESS',
                payload: response.data
            });
        }).catch((error) => {
        })
    });
};
export function fetchGlobalSettingsNBData() {
    return (function (dispatch) {
        httpRequestHandler
        .get('frontend/nbconfigs/').then((response) => {
            dispatch({
                type: 'FETCH_GLOBAL_SETTINGS_NB_DATA_SUCCESS',
                payload: response.data
            });
        }).catch((error) => {
        })
    });
};

export function addGlobalConfigNBURL(newglobalSettingNBURL, successCallback, errorCallback) {
    return (function (dispatch) {
        httpRequestHandler
        .post('frontend/nbconfigs/', newglobalSettingNBURL)
        .then((response) => {
           successCallback(response.data)
        })
        .catch((error) => {
          errorCallback(error)
        })
    });
};

export function editGlobalConfigNBURL(id, editedFields, successCallback, errorCallback) {
    return (function (dispatch) {
        httpRequestHandler
        .patch('frontend/nbconfigs/' + id  + '/', editedFields)
        .then((response) => {
           successCallback(response.data)
        })
        .catch((error) => {
          errorCallback(error)
        })
    });
};
