import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import validator from 'validator';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { toast } from 'react-toastify';

import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'

import lodash from 'lodash';

import ContentWrapper from '../../../common/components/ContentWrapper';
import {
  changeSidebarButtonState
} from '../../dashboard/actions/dashboardSidebarActions';

import {
  fetchConfigurationData,
  addConfiguration,
  editConfiguration,
  deletConfigTabelRow
} from '../actions/configurationsActions';
import { adminsList } from "../../provisioning/devices/actions/devicesActions";

import { TunerVoltages } from '../../../common/constants/config_params';
//import SimpleExpansionPanel from './ExpansionPanel'
import AlertDialogSlide from '../../../common/components/AlertDialog'
import AppBarComponent from "../../../common/components/AppBarComponent";
import { TableContainer } from '@material-ui/core';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class ConfigurationsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteRowId: "",
      open: false,
      canSubmit: false,
      helperTextIP: null,
      helperTextIPGroup: null,
      helperTextPort: null,
      ipValCheck: '',
      portValCheck: '',
      configurationsInfo: [],
      showEditConfiguration: false,
      mpe_config_id: false,
      dvb_s2_config: false,

      newConfiguration: {
        "mpe_pid": 4097,

        "stl_source": 1,
        "stltp_ip_address": "224.0.0.1",
        "stltp_multicast_ip_group": "239.0.0.0",
        "stltp_port": 12345,
        "smpte_control": 0,

        "transition_frequency": 0,

        "dvbs2_center_freq": 950,
        "dvbs2_symbol_rate": 21500000,
        "dvbs2_tuner_voltage": 1,
        "fcc_cw_mode": 0,

        "name": "Default Configuration"
      },

      existingConfiguration: {
        "mpe_pid": null,

        "stl_source": null,
        "stltp_ip_address": null,
        "stltp_multicast_ip_group": null,
        "stltp_port": null,
        "smpte_control": null,

        "transition_frequency": null,

        "dvbs2_center_freq": null,
        "dvbs2_symbol_rate": null,
        "fcc_cw_mode": null,

        "name": null,
      },

      editedFields: {
      }
    }

    this.getContentView = this.getContentView.bind(this);

    this.onConfigFieldChange = this.onConfigFieldChange.bind(this);

    this.addNewConfiguration = this.addNewConfiguration.bind(this);
    this.showEditConfigurationDialog = this.showEditConfigurationDialog.bind(this);

    this.editExistingConfiguration = this.editExistingConfiguration.bind(this);

    this.deleteTableRow = this.deleteTableRow.bind(this);
    this.onIPValCheck = this.onIPValCheck.bind(this);
    this.onIPGroupValCheck = this.onIPGroupValCheck.bind(this);
    this.onPortValCheck = this.onPortValCheck.bind(this);
  }

  componentDidMount() {
    this.props.changeSidebarButtonState('configuration', 'CONFIGURATION_BTN_ACTIVE');
    this.props.fetchConfigurationData();
    this.props.adminsList();
  }

  /*---------------------------------------------------------------------*/

  isFormValid() {

    let enableNewSubmit = (Boolean(
      !(this.state.helperTextIP) && !(this.state.helperTextIPGroup) &&
      !(this.state.helperTextPort) &&

      this.state.newConfiguration.name &&

      this.state.newConfiguration.dvbs2_center_freq &&
      this.state.newConfiguration.dvbs2_symbol_rate

    ));
    let enableEditSubmit = (Boolean(
      !(this.state.helperTextIP) && !(this.state.helperTextIPGroup) &&
      !(this.state.helperTextPort) &&

      this.state.existingConfiguration.name &&

      this.state.existingConfiguration.dvbs2_center_freq &&
      this.state.existingConfiguration.dvbs2_symbol_rate
    ));

    this.setState({
      ...this.state,
      canSubmit: (enableNewSubmit || enableEditSubmit),
    });

  }


  onConfigFieldChange(event, configDictKey, configDictParam) {


    let val = event.target.value;
    let selected_stl_source = parseInt(val);
    if (this.state.showEditConfiguration) {
      if (configDictKey === "stl_source") {
        this.state.existingConfiguration.stl_source = selected_stl_source;
      }

      if (configDictKey === "smpte_control") {
        val = Boolean(parseInt(val));
      }
      if (configDictKey === "transition_frequency") {
        val = Boolean(parseInt(val));
      }
      if (configDictKey === "fcc_cw_mode") {
        val = parseInt(val);
      }
      let ed = this.state.editedFields;
      if (configDictParam === undefined) {
        ed[configDictKey] = val;
      } else {
        if (ed[configDictKey] === undefined) {
          ed[configDictKey] = {}
        }
        ed[configDictKey][configDictParam] = val;
      }

      let existcfg = this.state.existingConfiguration;
      if (this.state.existingConfiguration.stl_source === 1) {
        this.state.mpe_config_id = false;
        this.state.dvb_s2_config = false;
      }
      else {
        this.state.mpe_config_id = true;
        this.state.dvb_s2_config = true;
      }
      if (configDictParam === undefined) {
        existcfg[configDictKey] = val;
      } else {
        existcfg[configDictKey][configDictParam] = val;
      }

      this.setState(
        {
          ...this.state,
          editedFields: {
            ...this.state.editedFields,
            ...ed
          },
          existingConfiguration: {
            ...this.state.existingConfiguration,
            ...existcfg
          },
        },
        () => this.isFormValid()
      )
    }
    else {
      let newcfg = this.state.newConfiguration;
      if (configDictKey === "stl_source") {
        this.state.newConfiguration.stl_source = selected_stl_source;
      }
      if (this.state.newConfiguration.stl_source === 1) {
        this.state.mpe_config_id = false;
        this.state.dvb_s2_config = false;
      }
      else {
        this.state.mpe_config_id = true;
        this.state.dvb_s2_config = true;
      }
      if (configDictParam === undefined) {
        newcfg[configDictKey] = val;
      } else {
        newcfg[configDictKey][configDictParam] = val;
      }

      this.setState(
        {
          ...this.state,
          newConfiguration: {
            ...this.state.newConfiguration,
            ...newcfg
          }
        },
        () => this.isFormValid()
      )
    }
  }

  onIPValCheck(event) {
    var ipValCheck = validator.isIP(event.target.value);
    if (!ipValCheck) {
      this.setState(
        {
          ...this.state,
          helperTextIP: 'IP Address Entered is not valid.'
        },
        () => this.isFormValid(),
      );
    } else {
      this.setState(
        {
          ...this.state,
          helperTextIP: ''
        },
        () => this.isFormValid(),
      );
    }
  }

  onIPGroupValCheck(event) {
    var ipGroupValCheck = validator.isIP(event.target.value);
    if (!ipGroupValCheck) {
      this.setState(
        {
          ...this.state,
          helperTextIPGroup: 'Multicast IP Group Address Entered is not valid.'
        },
        () => this.isFormValid(),
      );
    } else {
      this.setState(
        {
          ...this.state,
          helperTextIPGroup: ''
        },
        () => this.isFormValid(),
      );
    }
  }

  onPortValCheck(event) {
    var portValCheck = validator.isPort(event.target.value);
    if (!portValCheck) {
      this.setState(
        {
          ...this.state,
          helperTextPort: 'Enter valid Port Number (1025-65535)'
        },
        () => this.isFormValid(),
      );
    } else {
      if (parseInt(event.target.value) < 1025) {
        this.setState(
          {
            ...this.state,
            helperTextPort: 'Enter valid Port Number (1025-65535)'
          },
          () => this.isFormValid(),
        );
      } else {
        this.setState(
          {
            ...this.state,
            helperTextPort: ''
          },
          () => this.isFormValid(),
        )
      }
    }
  }

  /*---------------------------------------------------------------------*/


  addNewConfiguration() {
    this.props.addConfiguration(this.state.newConfiguration,
      (successResponse) => {
        toast("Device Configuration Added Successfully", {
          autoClose: 5000,
          type: toast.TYPE.SUCCESS,
          position: toast.POSITION.BOTTOM_CENTER,
        });
        this.setState({
          ...this.state,
          open: false,
          showEditConfiguration: false
        }, () => {
          this.props.fetchConfigurationData();
        });
      },
      (errorResponse) => {
        toast("Adding Device Configuration Failed", {
          autoClose: 5000,
          type: toast.TYPE.ERROR,
          position: toast.POSITION.TOP_CENTER,
        });
      }
    );
  }

  editExistingConfiguration() {
    if (lodash.isEmpty(this.state.editedFields)) {
      this.handleClose();
      return;
    }

    this.props.editConfiguration(this.state.existingConfiguration.id, this.state.editedFields,
      (successResponse) => {
        toast("Device Configuration Updated Successfully", {
          autoClose: 5000,
          type: toast.TYPE.SUCCESS,
          position: toast.POSITION.BOTTOM_CENTER,
        });
        this.setState({
          ...this.state,
          open: false,
          showEditConfiguration: false
        }, () => {
          this.props.fetchConfigurationData();
        });
      },
      (errorResponse) => {
        toast("Editing Device Configuration Failed", {
          autoClose: 5000,
          type: toast.TYPE.ERROR,
          position: toast.POSITION.TOP_CENTER,
        });
      }
    );
  }

  showEditConfigurationDialog = (row) => {
    var rowCopy = JSON.parse(JSON.stringify(row));
    this.setState({
      ...this.state,
      showEditConfiguration: true,
      open: true,
      existingConfiguration: rowCopy
    },
      () => this.isFormValid()
    );
  };

  deleteTableRow(deleteRowId) {
    this.props.deletConfigTabelRow(deleteRowId,
      (successResponse) => {
        toast("Device Configuration Deleted Successfully", {
          autoClose: 5000,
          type: toast.TYPE.SUCCESS,
          position: toast.POSITION.BOTTOM_CENTER,
        });
        this.setState({
          ...this.state
        }, () => {
          this.props.fetchConfigurationData();
        });
      },
      (errorResponse) => {
        toast('Device Configuration can not be deleted, Kindly contact Administrator', {
          autoClose: 5000,
          type: toast.TYPE.ERROR,
          position: toast.POSITION.TOP_CENTER,
        });
      });
    this.closeDeleteConfAlert();
  }

  handleClickOpen = () => {
    this.setState(
      {
        ...this.state,
        open: true,
      },
      () => this.isFormValid()
    );
  };

  handleClose = () => {
    this.setState({
      ...this.state,
      open: false,
      showEditConfiguration: false,
      helperTextIP: '',
      helperTextIPGroup: '',
      helperTextPort: '',
      helperText: '',
      editedFields: {},
    });
  };

  openDeleteConfAlert = (row) => {
    this.setState({
      openAlert: true,
      deviceID: row.id
    })
  }

  closeDeleteConfAlert = () => {
    this.setState({
      openAlert: false,
      deviceID: null
    })
  }

  getUsername = (userId) => {
    const user = this.props.adminsInfo.find(admin => admin.id === userId);

    return user === undefined ? "-" : user.username;
  }

  getContentView() {
    const configurations = this.props.configurationsInfo;
    var disabled = localStorage.getItem('roleKey') === 'operator' ? true : false;
    var notOperator = !disabled;

    if (this.state.showEditConfiguration) {
      if (parseInt(this.state.existingConfiguration.stl_source) != 1) {
        this.state.mpe_config_id = true;
        this.state.dvb_s2_config = true;
      }
    }

    else if (this.state.newConfiguration) {
      if (parseInt(this.state.newConfiguration.stl_source) != 1) {
        this.state.mpe_config_id = true;
        this.state.dvb_s2_config = true;
      }
      else {
        this.state.mpe_config_id = false;
        this.state.dvb_s2_config = false;
      }
    }


    return (
      <div className="content-wrapper">
        <div className="content">
          <div className="row main_top_headding">
            <div className="col-md-4 col-sm-4 col-xs-0"></div>
            <div className="col-md-4 col-sm-4 col-xs-6 main_top_headding_mid">
              <h4> <i className="fa fa-cog" aria-hidden="true"></i> &nbsp; DEVICE CONFIGURATIONS</h4>
            </div>
            <div className="col-md-4 col-sm-4 col-xs-6 main_top_headding_right">
              {disabled ?
                null :
                <button
                  type="button"
                  className="btn btn-outline-secondary pull-right"
                  onClick={this.handleClickOpen}
                  disabled={disabled}
                >
                  <span className="glyphicon glyphicon-plus"></span>
                  &nbsp; <strong>
                    Add Config
                  </strong>
                </button>
              }
            </div>
          </div>{/*<!-- row ---> */}

          <div className="row main_popup">
            <Dialog
              fullScreen
              open={this.state.open}
              onClose={this.handleClose}
              TransitionComponent={Transition}
              className="popup-module"
              disableBackdropClick
            >
              <AppBarComponent
                icon={"fa fa-cog"}
                title={"Device Configuration"}
                handleClose={this.handleClose}
              />

              <DialogContent>
                <form className="dialogContent-form">

                  <Grid container spacing={24}>
                    <Grid item sm={4}></Grid>
                    <Grid item sm={4}>
                      <TextField
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        id="config-name"
                        label="Configuration Name"
                        style={{ marginBottom: 10 }}
                        margin="normal"
                        value={
                          (this.state.showEditConfiguration) ? this.state.existingConfiguration.name
                            : this.state.newConfiguration.name
                        }
                        onChange={(e) => this.onConfigFieldChange(e, "name")}
                      />
                    </Grid>
                    <Grid item sm={4}></Grid>
                  </Grid>

                  <Grid container spacing={24}>
                    <Grid item sm={4}></Grid>
                    <Grid item sm={4}>
                      {/* FIXME don't delete, uncomment when done <SimpleExpansionPanel
                        showEditConfiguration={this.state.showEditConfiguration}
                        existingConfiguration={this.state.existingConfiguration}
                        newConfiguration={this.state.newConfiguration}
                        onConfigFieldChange={this.onConfigFieldChange}
                      /> */}
                      <Accordion defaultActiveKey="0">
                        <Card>
                          <Accordion.Toggle as={Card.Header} eventKey="0">
                            <p className="phead"><b>Operations and Management</b>
                              <span className="pull-right glyphicon glyphicon-triangle-bottom caret-icon">
                              </span>
                            </p>
                          </Accordion.Toggle>

                          <Accordion.Collapse eventKey="0">
                            <Card.Body>

                              <TextField
                                variant="outlined"
                                id="transition_frequency"
                                select
                                label="Transmission Bandwidth"
                                style={{ marginBottom: 10 }}
                                margin="normal"
                                SelectProps={{
                                  MenuProps: {
                                    //className: classes.menu,
                                  },
                                }}
                                value={
                                  (this.state.showEditConfiguration) ?
                                    (this.state.existingConfiguration.transition_frequency ? "1" : "0") :
                                    this.state.newConfiguration.transition_frequency
                                }
                                onChange={(e) => this.onConfigFieldChange(e, "transition_frequency")}
                              // onChange={this.onConfigurationListChange}
                              >
                                <option selected value={0}>6 MHz</option>
                                <option value={1}>8 MHz</option>
                              </TextField>

                              <TextField
                                variant="outlined"
                                id="fcc_cw_mode"
                                select
                                label="Mode of Operation"
                                style={{ marginBottom: 10 }}
                                margin="normal"
                                SelectProps={{
                                  MenuProps: {
                                    //className: classes.menu,
                                  },
                                }}
                                value={
                                  (this.state.showEditConfiguration) ?
                                    (this.state.existingConfiguration.fcc_cw_mode ? "1" : "0") :
                                    this.state.newConfiguration.fcc_cw_mode
                                }
                                onChange={(e) => this.onConfigFieldChange(e, "fcc_cw_mode")}
                              >
                                <option selected value={0}>ATSC 3.0 MODE</option>
                                <option value={1}>CW MODE</option>
                              </TextField>

                              <TextField
                                variant="outlined"
                                id="backhaul-stltp-source"
                                select
                                label="Backhaul/STL-TP Source"
                                style={{ marginBottom: 10 }}
                                margin="normal"
                                SelectProps={{
                                  MenuProps: {
                                    //className: classes.menu,
                                  },
                                }}
                                value={
                                  (this.state.showEditConfiguration) ?
                                    this.state.existingConfiguration.stl_source :
                                    this.state.newConfiguration.stl_source
                                }
                                onChange={(e) => this.onConfigFieldChange(e, "stl_source")}
                              //onChange={this.onConfigurationListChange}
                              >
                                <option value={0}>Ethernet</option>
                                <option value={1}>DVB-S2</option>
                                <option value={2}>Optical Fiber</option>
                              </TextField>
                              {(
                                (this.state.mpe_config_id)
                              ) ? (
                                <div>
                                  <TextField
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    id="config-stlipconfig-multicastip"
                                    type="text"
                                    label="STL-TP Source IP Address"
                                    helperText={this.state.helperTextIP}
                                    style={{ marginBottom: 10 }}
                                    margin="normal"
                                    placeholder="STL-TP Source IP Address."
                                    value={
                                      (this.state.showEditConfiguration) ?
                                        this.state.existingConfiguration.stltp_ip_address :
                                        this.state.newConfiguration.stltp_ip_address
                                    }
                                    onChange={(e) => this.onConfigFieldChange(e, "stltp_ip_address")}
                                    onBlur={this.onIPValCheck}
                                  />
                                  <TextField
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    id="config-stlipconfig-multicastip-group"
                                    type="text"
                                    label="STL-TP Source Multicast IP Group"
                                    helperText={this.state.helperTextIPGroup}
                                    style={{ marginBottom: 10 }}
                                    margin="normal"
                                    placeholder="Multicast IP Group Address."
                                    value={
                                      (this.state.showEditConfiguration) ?
                                        this.state.existingConfiguration.stltp_multicast_ip_group :
                                        this.state.newConfiguration.stltp_multicast_ip_group
                                    }
                                    onChange={(e) => this.onConfigFieldChange(e, "stltp_multicast_ip_group")}
                                    onBlur={this.onIPGroupValCheck}
                                  />
                                  <TextField
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    id="config-stlipconfig-port"
                                    label="STL-TP Source Port Number"
                                    type="text"
                                    helperText={this.state.helperTextPort}
                                    style={{ marginBottom: 10 }}
                                    margin="normal"
                                    placeholder="Port number between 1025-65535"
                                    value={
                                      (this.state.showEditConfiguration) ?
                                        this.state.existingConfiguration.stltp_port :
                                        this.state.newConfiguration.stltp_port
                                    }
                                    onChange={(e) => this.onConfigFieldChange(e, "stltp_port")}
                                    onBlur={this.onPortValCheck}
                                  />
                                </div>
                              ) : null}
                              <TextField
                                variant="outlined"
                                id="config-smpteconfig-control"
                                select
                                label="SMPTE Control"
                                style={{ marginBottom: 10 }}
                                margin="normal"
                                SelectProps={{
                                  MenuProps: {
                                    //className: classes.menu,
                                  },
                                }}
                                value={
                                  (this.state.showEditConfiguration) ?
                                    (this.state.existingConfiguration.smpte_control ? "1" : "0") :
                                    this.state.newConfiguration.smpte_control
                                }
                                onChange={(e) => this.onConfigFieldChange(e, "smpte_control")}
                              //onChange={this.onConfigurationListChange}
                              >
                                <option value={0}>Disabled</option>
                                <option value={1}>Enabled</option>
                              </TextField>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>

                        {
                          (this.state.mpe_config_id) ? null :
                            <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="2">
                                <p className="phead">
                                  <b>MPE Configurations</b>
                                  <span className="pull-right glyphicon glyphicon-triangle-bottom caret-icon"></span>
                                </p>
                              </Accordion.Toggle>

                              <Accordion.Collapse eventKey="2">
                                <Card.Body>
                                  <TextField
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    id="config-mpeconfig-pid"
                                    label="PID"
                                    type="number"
                                    style={{ marginBottom: 10 }}
                                    inputProps={{ min: "0", max: "8191", step: "1" }}
                                    value={
                                      (this.state.showEditConfiguration) ?
                                        this.state.existingConfiguration.mpe_pid :
                                        this.state.newConfiguration.mpe_pid
                                    }
                                    onChange={(e) => this.onConfigFieldChange(e, "mpe_pid")}
                                  />
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>
                        }

                        {
                          (this.state.dvb_s2_config) ? null :
                            <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="3">
                                <p className="phead">
                                  <b>DVB-S2 Configurations</b>
                                  <span className="pull-right glyphicon glyphicon-triangle-bottom caret-icon"></span>
                                </p>
                              </Accordion.Toggle>

                              <Accordion.Collapse eventKey="3">
                                <Card.Body>
                                  <TextField
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    id="config-dvbs2config-centerfreq"
                                    label="Center Frequency (MHz)"
                                    type="number"
                                    inputProps={{ min: "950", max: "2150", step: "0.1" }}
                                    style={{ marginBottom: 10 }}
                                    margin="normal"
                                    value={
                                      (this.state.showEditConfiguration) ?
                                        this.state.existingConfiguration.dvbs2_center_freq :
                                        this.state.newConfiguration.dvbs2_center_freq
                                    }
                                    onChange={(e) => this.onConfigFieldChange(e,
                                      "dvbs2_center_freq")}
                                  />

                                  <TextField
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    id="config-dvbs2config-symbolrate"
                                    label="Symbol Rate"
                                    type="number"
                                    inputProps={{ min: "1000000", max: "40000000", step: "500000" }}
                                    style={{ marginBottom: 10 }}
                                    margin="normal"
                                    value={
                                      (this.state.showEditConfiguration) ?
                                        this.state.existingConfiguration.dvbs2_symbol_rate :
                                        this.state.newConfiguration.dvbs2_symbol_rate
                                    }
                                    onChange={(e) => this.onConfigFieldChange(e,
                                      "dvbs2_symbol_rate")}
                                  />
                                  <TextField
                                    variant="outlined"
                                    select
                                    id="config-dvbs2config-tunervoltage"
                                    label="Tuner Voltage"
                                    SelectProps={{
                                      MenuProps: {
                                        //className: classes.menu,
                                      },
                                    }}
                                    value={
                                      (this.state.showEditConfiguration) ?
                                        this.state.existingConfiguration.dvbs2_tuner_voltage :
                                        this.state.newConfiguration.dvbs2_tuner_voltage
                                    }
                                    onChange={(e) => this.onConfigFieldChange(e,
                                      "dvbs2_tuner_voltage")}
                                  >
                                    {TunerVoltages.map(option => (
                                      <option value={option.value}> {option.key} </option>))}

                                  </TextField>
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>
                        }
                      </Accordion>
                    </Grid>
                    <Grid item sm={4}></Grid>
                  </Grid>

                  <Grid container spacing={24}>
                    <Grid item sm={4}></Grid>
                    <Grid item sm={4}>
                      {(disabled) ? null :
                        <div className="form-button-container">
                          <Button
                            variant="outlined"
                            className="form-submit-button"
                            onClick={(this.state.showEditConfiguration) ?
                              this.editExistingConfiguration :
                              this.addNewConfiguration}
                            disabled={!this.state.canSubmit}
                          >
                            {(this.state.showEditConfiguration) ? 'Save Configuration' : 'Add Configuration'}
                          </Button>
                          <Button
                            variant="outlined"
                            className="form-cancel-button"
                            onClick={this.handleClose}
                          >
                            Cancel
                          </Button>
                        </div>
                      }
                    </Grid>
                    <Grid item sm={4}></Grid>
                  </Grid>
                </form>
              </DialogContent>
            </Dialog>
          </div>{/*<!-- row main popup---> */}

          <div className="row common_div_row">
            <Paper>
              <TableContainer className="table-all  table-responsive">
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" className="text-wrap-head">Configuration Name (ID)</TableCell>
                      <TableCell align="left" className="text-wrap-head">Created by</TableCell>
                      <TableCell align="center" className="text-wrap-head">Details</TableCell>
                      {notOperator ? (
                        <TableCell align="center" className="text-wrap-head">Delete</TableCell>
                      ) : null}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {configurations.length > 0 && configurations.map(row => (
                      <TableRow key={row.id}>
                        <TableCell align="left" className="text-wrap">{row.name} ({row.id})</TableCell>
                        <TableCell align="left" className="text-wrap">{this.getUsername(row.created_by)}</TableCell>
                        <TableCell align="center" className="text-wrap">
                          <button
                            type="button"
                            className="btn btn-outline-secondary"
                            onClick={() => {
                              this.showEditConfigurationDialog(row);
                            }}
                          > <span className="glyphicon glyphicon-edit"></span>
                          </button>
                        </TableCell>
                        {notOperator ? (
                          <TableCell align="center" className="text-wrap">
                            <button
                              type="button"
                              className="btn btn-outline-secondary"
                              onClick={() => {
                                this.openDeleteConfAlert(row);
                              }}
                              disabled={disabled}
                            > <span className="glyphicon glyphicon-trash"></span>
                            </button>
                          </TableCell>
                        ) : null}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>  {/*<!-- row ---> */}
          <AlertDialogSlide
            open={this.state.openAlert}
            handleClose={this.closeDeleteConfAlert}
            title={"Delete Device Configuration?"}
            desc={"are you sure you want to delete device configuration?"}
            action={() => { this.deleteTableRow(this.state.deviceID) }}
          />
        </div>
      </div>
    );
  }

  render() {

    return (
      <ContentWrapper
        contentView={this.getContentView()}
        netError={this.props.netError}
      />
    );
  }
}

//Required to tell redux how this component is mapped with dashboard reducers
function mapStateToProps(state) {
  return {
    configurationsInfo: state.configurationsInfo.configurationsInfo,
    adminsInfo: state.adminsInfo.adminsInfo,
    netError: state.configurationsInfo.netError,

  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    changeSidebarButtonState,
    fetchConfigurationData,
    addConfiguration,
    editConfiguration,
    deletConfigTabelRow,
    adminsList
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurationsComponent);
