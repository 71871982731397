import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { toast } from "react-toastify";
import Switch from "@material-ui/core/Switch";
import ContentWrapper from "../../../../common/components/ContentWrapper";

import { changeSidebarButtonState } from "../../../dashboard/actions/dashboardSidebarActions";
import {
  fetchDevicesData,
  adminsList,
  operatorsList,
  configurationList,
  addDevices,
  editDevices,
  deleteDevicesRow,
  uploadCert,
  revokeCert
} from "../actions/devicesActions";

import AlertDialogSlide from "../../../../common/components/AlertDialog";

import UploadCertForm from "./UploadCertForm";
import DeviceForm from "./DeviceForm";
import { TableContainer } from "@material-ui/core";

class DeviceComponent extends Component {
  state = {
    openDeviceForm: false,
    openUploadCert: false,
    openAlertDelete: false,
    openAlertRevoke: false,
    showEditDevice: false,
    deviceID: null,
    existingDevice: {}
  };

  componentDidMount() {
    this.props.changeSidebarButtonState("devices", "DEVICES_BTN_ACTIVE");
    this.props.fetchDevicesData();
    this.props.adminsList();
    this.props.operatorsList();
    this.props.configurationList();
  }

  toggleSecurity = (deviceID, secure_communication) => {
    this.props.editDevices(
      deviceID,
      { secure_communication: !secure_communication },
      successResponse => {
        toast("Device Updated Successfully.", {
          autoClose: 5000,
          type: toast.TYPE.SUCCESS,
          position: toast.POSITION.TOP_CENTER
        });
        this.setState(
          {
            ...this.state,
            open: false,
            showEditDevice: false
          },
          () => {
            this.props.fetchDevicesData();
          }
        );
      },
      errorResponse => {
        toast("Device Update Failed", {
          autoClose: 5000,
          type: toast.TYPE.ERROR,
          position: toast.POSITION.TOP_CENTER
        });
      }
    );
  };

  toggleNotifications = (deviceID, enable_notifications) => {
    this.props.editDevices(
      deviceID,
      { enable_notifications: !enable_notifications },
      successResponse => {
        toast("Device Updated Successfully.", {
          autoClose: 5000,
          type: toast.TYPE.SUCCESS,
          position: toast.POSITION.TOP_CENTER
        });
        this.setState(
          {
            ...this.state,
            open: false,
            showEditDevice: false
          },
          () => {
            this.props.fetchDevicesData();
          }
        );
      },
      errorResponse => {
        toast("Device Update Failed", {
          autoClose: 5000,
          type: toast.TYPE.ERROR,
          position: toast.POSITION.TOP_CENTER
        });
      }
    );
  };

  openDeleteDeviceAlert = device => {
    this.setState({
      openAlertDelete: true,
      deviceID: device.id
    });
  };

  closeDeleteDeviceAlert = () => {
    this.setState({
      openAlertDelete: false,
      deviceID: null
    }, () => {
    });
  };

  deleteDeviceDataRow(deleteOperatorID) {
    this.props.deleteDevicesRow(
      deleteOperatorID,
      successResponse => {
        toast("Device Deleted Successfully", {
          autoClose: 5000,
          type: toast.TYPE.SUCCESS,
          position: toast.POSITION.TOP_CENTER
        });
        this.setState(
          {
            ...this.state
          },
          () => {
            this.props.fetchDevicesData();
          }
        );
      },
      errorResponse => {
        toast(
          "Device might be associated with any device, Kindly contact Administrator",
          {
            autoClose: 5000,
            type: toast.TYPE.ERROR,
            position: toast.POSITION.TOP_CENTER
          }
        );
      }
    );
    this.closeDeleteDeviceAlert();
  }

  handleOpenUploadCert = deviceID => {
    this.setState({ openUploadCert: true, deviceID });
  };

  handleCloseUploadCert = () => {
    this.setState({ openUploadCert: false, deviceID: null });
  };

  openRevokeAlert = device => {
    this.setState({
      openAlertRevoke: true,
      deviceID: device.id
    });
  };

  closeRevokeAlert = () => {
    this.setState({
      openAlertRevoke: false,
      deviceID: null
    });
  };

  revokeCert = deviceID => {
    this.props.revokeCert(
      deviceID,
      successResponse => {
        toast("Device Certificate Revoked Successfully.", {
          autoClose: 5000,
          type: toast.TYPE.SUCCESS,
          position: toast.POSITION.TOP_CENTER
        });
        this.setState(
          {
            ...this.state,
            certificate: null,
            key: null,
            deviceID: null
          },
          () => {
            this.props.fetchDevicesData();
          }
        );
      },
      errorResponse => {
        toast("Device Certificate Revoke Failed", {
          autoClose: 5000,
          type: toast.TYPE.ERROR,
          position: toast.POSITION.TOP_CENTER
        });
      }
    );
    this.closeRevokeAlert();
  };

  showEditDeviceDialog = existingDevice => {
    this.setState({
      ...this.state,
      showEditDevice: true,
      openDeviceForm: true,
      existingDevice
    });
  };

  handleClickOpenDeviceForm = () => {
    this.setState({ openDeviceForm: true });
  };

  handleCloseDeviceForm = () => {
    this.setState({
      openDeviceForm: false,
      showEditDevice: false,
      existingDevice: {}
    });
  };

  // handleClose = () => {
  //   this.setState({
  //     ...this.state,
  //     open: false,
  //     openUploadCert: false,
  //     showEditDevice: false,
  //     helperTextCertificate: null,
  //     helperTextKey: null,
  //     helperTextMacId:null,
  //     newDevice:{
  //       admin : localStorage.getItem('user_id'),
  //       operators: []
  //     }
  //   });
  // };

  getContentView = () => {
    const classes = this.props;
    const devices = this.props.devicesInfo;
    const operators = this.props.operatorsInfo;
    const admins = this.props.adminsInfo;
    const configurations = this.props.configurationsInfo;
    const role = localStorage.getItem("roleKey");

    return (
      <div className="content-wrapper">
        <div className="content">
          <div className="row main_top_headding">
            <div className="col-md-4 col-sm-4 col-xs-0"></div>
            <div className="col-md-4 col-sm-4 col-xs-6 main_top_headding_mid">
              <h4>
                <i className="fa fa-laptop" aria-hidden="true"></i> &nbsp;
                Devices
              </h4>
            </div>
            <div className="col-md-4 col-sm-4 col-xs-6 main_top_headding_right">
              <button
                type="button"
                className="btn btn-outline-secondary pull-right"
                onClick={this.handleClickOpenDeviceForm}
              >
                <span className="glyphicon glyphicon-plus"></span>
                  &nbsp; <strong>
                  Add Device
                  </strong>
              </button>
            </div>
          </div>
          {/*<!-- row ---> */}

          <div className="row common_div_row">
            <Paper>
              <TableContainer className="table-all table-responsive">
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" className="text-wrap-head">Description</TableCell>
                      <TableCell align="center" className="text-wrap-head">Serial No.</TableCell>
                      <TableCell align="center" className="text-wrap-head">Mac ID</TableCell>
                      <TableCell align="center" className="text-wrap-head">Model Number</TableCell>
                      <TableCell align="left" className="text-wrap-head">Admin</TableCell>
                      <TableCell align="left" className="text-wrap-head">Operators</TableCell>
                      <TableCell align="left" className="text-wrap-head">Configuration</TableCell>
                      <TableCell align="center" className="text-wrap-head">Details</TableCell>
                      {role !== "operator" ? (
                        <TableCell align="center" className="text-wrap-head">Delete</TableCell>
                      ) : null}
                      {role === "owner" ? (
                        <TableCell align="center" className="text-wrap-head">TLS</TableCell>
                      ) : null}
											{(role === "admin" || role === "owner") ? (
                        <TableCell align="center" className="text-wrap-head">NOTIFICATINS</TableCell>
											) : null }
                      {role === "owner" ? (
                        <TableCell align="center" className="text-wrap-head">Certificate</TableCell>
                      ) : null}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {devices.length > 0 &&
                      devices.map(row => (
                        <TableRow key={row.id}>
                          <TableCell align="left"  className="text-wrap">
                            {row.description}
                          </TableCell>
                          <TableCell align="center" className="text-wrap">{row.serial_no}</TableCell>
                          <TableCell align="center" className="text-wrap">
                            {row.mac_address}
                          </TableCell>
                          <TableCell align="center" className="text-wrap">
                            {row.model_no}
                          </TableCell>
                          <TableCell align="left" className="text-wrap">
                          {(() => {
                                      var admin = admins.find(
                                        admin => admin.id === row.admin
                                      );
                                      return admin ? admin.name : "Unknown";
                                    })()}
                            {/* {row.admin_name} */}
                            </TableCell>
                          <TableCell align="left" className="text-wrap">
                          {(() => {
                                      let assignedOperators = operators.filter(
                                        operator => row.operators.includes(operator.id)
                                      );
                                      return assignedOperators
                                        .map(op => op.name)
                                        .join(", ");
                                    })()}
                            {/* {row.operators} */}
                            </TableCell>
                          <TableCell align="left" className="text-wrap">
                            {(() => {
                              var config = configurations.find(
                                config => config.id === row.config
                              );
                              return config ? `${config.name} (${config.id})` : "Unknown";
                            })()}
                          </TableCell>
                          <TableCell align="center" className="text-wrap">
                            <button
                              type="button"
                              className="btn btn-outline-secondary"
                              onClick={() => {
                                this.showEditDeviceDialog(row);
                              }}
                            >
                              <span className="glyphicon glyphicon-edit"></span>
                            </button>
                          </TableCell>
                          {role !== "operator" ? (
                            <TableCell align="center" className="text-wrap">
                              <button
                                type="button"
                                className="btn btn-outline-secondary"
                                onClick={() => {
                                  this.openDeleteDeviceAlert(row);
                                }}
                              >
                                <span className="glyphicon glyphicon-trash"></span>
                              </button>
                            </TableCell>
                          ) : null}
                          {role === "owner" ? (
                            <TableCell align="center" className="text-wrap">
                              <Switch
                                checked={row.secure_communication}
                                onClick={e => {
                                  this.toggleSecurity(
                                    row.id,
                                    row.secure_communication
                                  );
                                }}
                                color="primary"
                              />
                            </TableCell>
                          ) : null}
                          {(role === "owner" || role === "admin") ? (
                            <TableCell align="center" className="text-wrap">
                              <Switch
                                checked={row.enable_notifications}
                                onClick={e => {
                                  this.toggleNotifications(
                                    row.id,
                                    row.enable_notifications
                                  );
                                }}
                                color="primary"
                              />
                            </TableCell>
                          ) : null}
                          {role === "owner" ? (
                            <TableCell align="center" className="text-wrap">
                              {row.security_state === 1 ||
                              row.security_state === 4 ? (
                                <button
                                  disabled={row.secure_communication === false}
                                  onClick={() =>
                                    this.handleOpenUploadCert(row.id)
                                  }
                                >
                                  Upload Certificate
                                </button>
                              ) : (
                                <button
                                  disabled={row.secure_communication === false}
                                  onClick={() => this.openRevokeAlert(row)}
                                >
                                  Revoke Certificate
                                </button>
                              )}
                            </TableCell>
                          ) : null}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>
          {/*<!-- row ---> */}
          <AlertDialogSlide
            open={this.state.openAlertDelete}
            handleClose={this.closeDeleteDeviceAlert}
            title={"Delete Device?"}
            desc={"are you sure you want to delete the device?"}
            action={() => this.deleteDeviceDataRow(this.state.deviceID)}
          />
          <AlertDialogSlide
            open={this.state.openAlertRevoke}
            handleClose={this.closeRevokeAlert}
            title={"Revoke Certificate?"}
            desc={"are you sure you want to revoke uploaded certificate?"}
            action={() => this.revokeCert(this.state.deviceID)}
          />
          {this.state.openUploadCert ? (
            <UploadCertForm
              openUploadCert={this.state.openUploadCert}
              deviceID={this.state.deviceID}
              handleCloseUploadCert={this.handleCloseUploadCert}
              uploadCert={this.props.uploadCert}
              fetchDevicesData={this.props.fetchDevicesData}
            />
          ) : null}

          {this.state.openDeviceForm ? (
            <DeviceForm
              openDeviceForm={this.state.openDeviceForm}
              showEditDevice={this.state.showEditDevice}
              existingDevice={this.state.existingDevice}
              handleCloseDeviceForm={this.handleCloseDeviceForm}
              addDevices={this.props.addDevices}
              editDevices={this.props.editDevices}
              fetchDevicesData={this.props.fetchDevicesData}
              admins={admins}
              operators={operators}
              configurations={configurations}
              classes={classes}
            />
          ) : null}
        </div>
      </div>
    );
  };

  render() {
    return (
      <ContentWrapper
        contentView={this.getContentView()}
        netError={this.props.netError}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    devicesInfo: state.devicesInfo.devicesInfo,
    operatorsInfo: state.operatorsInfo.operatorsInfo,
    adminsInfo: state.adminsInfo.adminsInfo,
    configurationsInfo: state.configurationsInfo.configurationsInfo,
    netError: state.devicesInfo.netError
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      changeSidebarButtonState,
      fetchDevicesData,
      adminsList,
      operatorsList,
      configurationList,
      addDevices,
      editDevices,
      deleteDevicesRow,
      uploadCert,
      revokeCert
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(DeviceComponent);



// {(() => {
//   var admin = admins.find(
//     admin => admin.id === row.admin
//   );
//   return admin ? admin.name : "Unknown";
// })()}

// {(() => {
//   let assignedOperators = operators.filter(
//     operator => row.operators.includes(operator.id)
//   );
//   return assignedOperators
//     .map(op => op.name)
//     .join(", ");
// })()}
