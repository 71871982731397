const initialState = {
    configurationsInfo:[],
    netError: null
};

export default  (state = initialState, action) => {
    let currentState = state;
    switch(action.type) {

        case 'FETCH_CONFIGURATION_DATA_SUCCESS':
            currentState = {...state,
                configurationsInfo: action.payload,
                netError: null
            };
        break;

        case 'FETCH_CONFIGURATION_DATA_FAILURE':
            currentState = {...state,
                netError: action.error
            };
        break;

        case 'FETCH_SINGLE_CONFIGURATION_DATA_SUCCESS':
            currentState = {...state,
                configurationsInfo: action.payload,
                netError: null
            };
        break;

        case 'FETCH_SINGLE_CONFIGURATION_DATA_FAILURE':
            currentState = {...state,
                netError: action.error
            };
        break;

        default:
    }
    return currentState;
}
