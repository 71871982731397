import React from 'react'
import { connect } from 'react-redux'
import {
    Dialog,
    DialogContent,
    Grid,
    TextField
} from "@material-ui/core";
import AppBarComponent from "../../../../common/components/AppBarComponent";
import { getConfigurationData } from '../../../configurations/actions/configurationsActions';
import { TunerVoltages } from '../../../../common/constants/config_params';


const initialState = {};

class DeviceConfiguration extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ...initialState
        };
    }

    handleClose = () => {
        this.props.handleClose();
    };

    componentDidMount() {
        if (this.props.device) {
            this.props.getConfigurationData(parseInt(this.props.device.provisioned_device.config));
        }
    }



    render() {

        const dev = this.props.device;
        const dev_serial_no = (dev ? dev.provisioned_device.serial_no : "Unknown");


        var device_configuration = this.props.configurationsInfo;
        const stl_source_list = ["Ethernet", "DVB-S2", "Optical Fibre"]

        const getValue = (array, value) => {
            let r = array.find((e) => e.value === value);
            return r ? r.key : "undefined";
        };


        return (
            <div>
                <Dialog
                    fullScreen
                    open={this.props.open}
                    onClose={this.handleClose}
                    TransitionComponent={this.props.Transition}
                    className="popup-module"
                >
                    <AppBarComponent
                        icon={"fa fa-cog"}
                        title={`Device Configuration (${dev_serial_no})`}
                        handleClose={this.handleClose}
                    />

                    <DialogContent className="dialogContent">

                        <Grid container spacing={24}>
                            <Grid item sm={4}></Grid>
                            <Grid item sm={4}>
                                <TextField
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    id="config-name"
                                    label="Configuration Name"
                                    value={device_configuration.name}
                                    style={{ marginBottom: 10 }}
                                    margin="normal"
                                    inputProps={
                                        { readOnly: true, }
                                    }
                                />
                            </Grid>
                            <Grid item sm={4}></Grid>
                        </Grid>


                        <Grid container spacing={24}>
                            <Grid item sm={4}></Grid>
                            <Grid item sm={4}>
                                <TextField
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    id="config-name"
                                    label={'Transmission Bandwidth'}
                                    value={device_configuration.transition_frequency ? '8 Mhz' : '6 Mhz'}
                                    style={{ marginBottom: 10 }}
                                    margin="normal"
                                    inputProps={
                                        { readOnly: true, }
                                    }
                                />
                            </Grid>
                            <Grid item sm={4}></Grid>
                        </Grid>

                        <Grid container spacing={24}>
                            <Grid item sm={4}></Grid>
                            <Grid item sm={4}>
                                <TextField
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    id="config-name"
                                    label={'Mode of Operation'}
                                    value={device_configuration.fcc_cw_mode ? 'CW Mode' : 'ATSC 3.0 Mode'}
                                    style={{ marginBottom: 10 }}
                                    margin="normal"
                                    inputProps={
                                        { readOnly: true, }
                                    }
                                />
                            </Grid>
                            <Grid item sm={4}></Grid>
                        </Grid>

                        <Grid container spacing={24}>
                            <Grid item sm={4}></Grid>
                            <Grid item sm={4}>
                                <TextField
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    id="config-name"
                                    label={'Backhaul/STL-TP Source'}
                                    value={stl_source_list[device_configuration.stl_source]}
                                    style={{ marginBottom: 10 }}
                                    margin="normal"
                                    inputProps={
                                        { readOnly: true, }
                                    }
                                />
                            </Grid>
                            <Grid item sm={4}></Grid>
                        </Grid>


                        {device_configuration.stl_source === 1 ? (

                            <Grid container spacing={24}>
                                <Grid item sm={4}></Grid>
                                <Grid item sm={4}>
                                    <TextField
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        id="config-name"
                                        label={'DVB-S2 MPE PID'}
                                        value={device_configuration.mpe_pid}
                                        style={{ marginBottom: 10 }}
                                        margin="normal"
                                        inputProps={
                                            { readOnly: true, }
                                        }
                                    />
                                </Grid>
                                <Grid item sm={4}></Grid>
                            </Grid>

                        ) : null}

                        {device_configuration.stl_source === 1 ? (

                            <Grid container spacing={24}>
                                <Grid item sm={4}></Grid>
                                <Grid item sm={4}>
                                    <TextField
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        id="config-name"
                                        label={'DVB-S2 Center Frequency'}
                                        value={device_configuration.dvbs2_center_freq}
                                        style={{ marginBottom: 10 }}
                                        margin="normal"
                                        inputProps={
                                            { readOnly: true, }
                                        }
                                    />
                                </Grid>
                                <Grid item sm={4}></Grid>
                            </Grid>

                        ) : null}

                        {device_configuration.stl_source === 1 ? (

                            <Grid container spacing={24}>
                                <Grid item sm={4}></Grid>
                                <Grid item sm={4}>
                                    <TextField
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        id="config-name"
                                        label={'DVB-S2 Symbol Rate'}
                                        value={device_configuration.dvbs2_symbol_rate}
                                        style={{ marginBottom: 10 }}
                                        margin="normal"
                                        inputProps={
                                            { readOnly: true, }
                                        }
                                    />
                                </Grid>
                                <Grid item sm={4}></Grid>
                            </Grid>

                        ) : null}

                        {device_configuration.stl_source === 1 ? (

                            <Grid container spacing={24}>
                                <Grid item sm={4}></Grid>
                                <Grid item sm={4}>
                                    <TextField
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        id="config-name"
                                        label={'DVB-S2 Tuner Voltage'}
                                        value={getValue(TunerVoltages, device_configuration.dvbs2_tuner_voltage)}
                                        style={{ marginBottom: 10 }}
                                        margin="normal"
                                        inputProps={
                                            { readOnly: true, }
                                        }
                                    />
                                </Grid>
                                <Grid item sm={4}></Grid>
                            </Grid>

                        ) : null}

                        {device_configuration.stl_source !== 1 ? (

                            <Grid container spacing={24}>
                                <Grid item sm={4}></Grid>
                                <Grid item sm={4}>
                                    <TextField
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        id="config-name"
                                        label={'STL-TP Source IP Address'}
                                        value={device_configuration.stltp_ip_address}
                                        style={{ marginBottom: 10 }}
                                        margin="normal"
                                        inputProps={
                                            { readOnly: true, }
                                        }
                                    />
                                </Grid>
                                <Grid item sm={4}></Grid>
                            </Grid>

                        ) : null}

                        {device_configuration.stl_source !== 1 ? (

                            <Grid container spacing={24}>
                                <Grid item sm={4}></Grid>
                                <Grid item sm={4}>
                                    <TextField
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        id="config-name"
                                        label={'STL-TP Source Multicast IP Group'}
                                        value={device_configuration.stltp_multicast_ip_group}
                                        style={{ marginBottom: 10 }}
                                        margin="normal"
                                        inputProps={
                                            { readOnly: true, }
                                        }
                                    />
                                </Grid>
                                <Grid item sm={4}></Grid>
                            </Grid>

                        ) : null}

                        {device_configuration.stl_source !== 1 ? (

                            <Grid container spacing={24}>
                                <Grid item sm={4}></Grid>
                                <Grid item sm={4}>
                                    <TextField
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        id="config-name"
                                        label={'STL-TP Source Port Number'}
                                        value={device_configuration.stltp_port}
                                        style={{ marginBottom: 10 }}
                                        margin="normal"
                                        inputProps={
                                            { readOnly: true, }
                                        }
                                    />
                                </Grid>
                                <Grid item sm={4}></Grid>
                            </Grid>

                        ) : null}

                        <Grid container spacing={24}>
                            <Grid item sm={4}></Grid>
                            <Grid item sm={4}>
                                <TextField
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    id="config-name"
                                    label={'SMPTE Control'}
                                    value={device_configuration.smpte_control ? 'Enabled' : 'Disabled'}
                                    style={{ marginBottom: 10 }}
                                    margin="normal"
                                    inputProps={
                                        { readOnly: true, }
                                    }
                                />
                            </Grid>
                            <Grid item sm={4}></Grid>
                        </Grid>

                    </DialogContent>
                </Dialog>
            </div>
        )
    }


}

const mapStateToProps = state => {
    return {
        configurationsInfo: state.configurationsInfo.configurationsInfo,
    };
};

const mapDispatchToProps = {
    getConfigurationData
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceConfiguration);
