import validator from "validator";
import { SpecialCharsRegEx } from "../constants/constants";

const apiToFormFieldIDs = {
  name: { key: "user-name", editable: true },
  username: { key: "user-username", editable: false },
  password: {
    key: "user-password",
    editable: false,
    customValidator: value => onUserPasswordChangeError()
  },
  telephone: {
    key: "user-telephone",
    editable: true,
    customValidator: value =>
      (value.length > 10)
        ? { text: "", error: false }
        : { text: "Invalid  Phone Number", error: true }
  },
  email: {
    key: "user-email",
    editable: true,
    customValidator: value =>
      validator.isEmail(value)
        ? { text: "", error: false }
        : { text: "Invalid Email ID", error: true }
  },
  address_1: {
    key: "user-address_1",
    editable: true,
    customValidator: value =>
      value.length < 100
        ? { text: "", error: false }
        : { text: "Address must be less than 100", error: true }
  }
};

const onUserPasswordChangeError = () => {
  var hasUpperCase = /[A-Z]/.test(this.state.newUser.password);
  var hasLowerCase = /[a-z]/.test(this.state.newUser.password);
  var hasNumbers = /\d/.test(this.state.newUser.password);
  var hasSpecialChars = SpecialCharsRegEx.test(this.state.newUser.password);
  if (
    !hasUpperCase ||
    !hasLowerCase ||
    !hasNumbers ||
    !hasSpecialChars ||
    this.state.newUser.password.length < 6
  ) {
    return {
      text:
        "needs min 6 chars, 1 lowercase, uppercase, special character(!,@,#,$,%,^,&,*,(,),-,+), number.",
      error: true
    };
  } else {
    return { text: "", error: false };
  }
};

const validateNotEmpty = value => {
  return value && value.length > 0;
};

export const validateFormPreSubmit = userData => {
  let obj = userData;
  let errorCount = 0;
  let validateFormErrors = {};

  // Non Empty validations first
  for (let prop in apiToFormFieldIDs) {
    if (!apiToFormFieldIDs[prop].editable) {
      continue;
    }

    let fieldval = obj[prop];

    let result = validateNotEmpty(fieldval);
    if (!result) {
      validateFormErrors[prop] = {
        text: "This field should be Non-Empty",
        error: true
      };

      errorCount += 1;
    } else if (apiToFormFieldIDs[prop].customValidator) {
      let fn = apiToFormFieldIDs[prop].customValidator;
      let result = fn(fieldval);

      if (result.error) {
        validateFormErrors[prop] = result;
        errorCount += 1;
      }
    }
  }
  if (errorCount > 0) {
    return { formValid: false, formErrors: validateFormErrors };
  }

  // catchall false for now
  return { formValid: true, formErrors: {} };
};

export const isFormFieldInvalid = (validateFormErrors, field) => {
  var err = validateFormErrors[field];

  if (err) {
    return { helperText: err.text, error: err.error };
  }
  return null;
};

export const displayAPIErrorstoFormFields = errorResponseMessages => {
  let validateFormErrors = {};
  let errorCount = 0;

  for (let APIKey in errorResponseMessages) {
    if (APIKey === "message") {
      continue;
    }
    let APIErrorMessage = errorResponseMessages[APIKey].join(" ");
    validateFormErrors[APIKey] = { text: APIErrorMessage, error: true };
    errorCount += 1;
    if (errorCount > 0) {
      return { formErrors: validateFormErrors };
    }
    return { formErrors: {} };
  }
};
