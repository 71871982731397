function getNormalizedChartData(data) {
  let arr = [];
  data.slice(1).forEach(e => {
    arr.push(e.slice(1));
  });

  let valuesArray = getValuesArray(data, arr);
  let normalizedValuesArray = normalizeArray(valuesArray);
  let normalizedoriginalArray = getValuesArray(data, normalizedValuesArray);
  let normalizedChartData = [];

  for (let i = 0; i < arr.length; i++) {
    let tooltipsData = getTooltipsData(data[0], data.slice(1)[i]);
    normalizedChartData.push(joiner(normalizedoriginalArray[i], tooltipsData));
  }

  for (let i = 1; i < data[0].length; i += 2) {
    data[0].splice(i + 1, 0, { role: "tooltip", type: "string" });
  }
  // normalizedChartData = splicer(data, normalizedChartData)

  return normalizedChartData;
}

function getValuesArray(data, arr1) {
  let arr2 = [];
  for (let i1 = 0; i1 < data.length; i1++) {
    let var3 = arr1.map(function(var2) {
      return var2[i1];
    });
    if (!var3.includes(undefined)) {
      arr2.push(var3);
    }
  }
  return arr2;
}

function normalizeArray(arrays) {
  let normArr = [];
  for (let arr of arrays) {
    normArr.push(normalize(arr));
  }
  return normArr;
}

function normalize(list) {
  let minMax = list.reduce(
    (acc, value) => {
      if (value < acc.min) {
        acc.min = value;
      }

      if (value > acc.max) {
        acc.max = value;
      }

      return acc;
    },
    { min: Number.POSITIVE_INFINITY, max: Number.NEGATIVE_INFINITY }
  );

  return list.map(value => {
    // Verify that you're not about to divide by zero
    if (minMax.max === minMax.min) {
      return 1 / list.length;
    }

    let diff = minMax.max - minMax.min;
    return (value - minMax.min) / diff;
  });
}

function joiner(normArr, tooltipsData) {
  let arrData = [];
  for (let i = 0; i < normArr.length; i++) {
    arrData = arrData.concat(normArr[i], tooltipsData);
  }

  return arrData;
}

/*
function splicer(data, arr) {
  for (let i = 0; i < arr.length; i++) {
    arr[i].splice(0, 0, data[i + 1][0]);
  }
  arr.splice(0, 0, data);
  return arr;
}
*/

const getTooltipsData = (labels, origArr) => {
  let tooltipsData = "";
  for (let i = 0; i < origArr.length; i++) {
    tooltipsData += labels[i] + ": " + origArr[i] + "\n";
  }

  return tooltipsData.trim();
};

export default getNormalizedChartData;
