import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { toast } from 'react-toastify';

import ContentWrapper from '../../../common/components/ContentWrapper';
import { BRHModels } from "../../../common/constants/config_params";

import {
  changeSidebarButtonState
} from '../../dashboard/actions/dashboardSidebarActions';
import {
  addFirmware,
  firmwaresList,
  deleteFirmwareData
} from '../actions/brhFirmwareActions';
import { adminsList } from '../../provisioning/devices/actions/devicesActions'

import AlertDialogSlide from '../../../common/components/AlertDialog'
import AppBarComponent from "../../../common/components/AppBarComponent";
import { TableContainer, Link } from '@material-ui/core';

const user_id = localStorage.getItem("user_id");
const user_name = localStorage.getItem("username");
const user_suffix = "?user_id=" + user_id + "&user_name=" + user_name;
//const base_url = base_url_resolver('firmwares').substring(base_url_resolver('firmwares').slice(0,-1).lastIndexOf('/')+1)
const base_url = "api/";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const initialState = {
      open:false,
      newFirmware: {
        filename: {},
        release_notes: {},
        uploaded_by:localStorage.getItem('user_id'),
        version:'',
        model_no: BRHModels[0],
      },
      helperText: {
        filename: '',
        release_notes: '',
        version: '',
        model_no: '',
      }
}

class BRHfirmwaresComponent extends Component {
  state = { ...initialState }

  componentDidMount () {
    this.props.changeSidebarButtonState('brhfirmwares', 'BRHFIRMWARES_BTN_ACTIVE');
    this.props.firmwaresList();
    this.props.adminsList();
  }

  onFileNameChange = ({target}) => {
    var file = target.files[0];
    if (!file) {
      this.setState({
        newFirmware: {...this.state.newFirmware,
          filename: {},
        }
      })
      return;
    }
    this.setFile(file, 'filename');
    this.setState({
      helperText: {
        ...this.state.helperText,
        filename: ""
      }
    });
  }

  onReleaseNotesChange = (event) => {
    let file = event.target.files[0];
    if (!file) {
      this.setState({
        newFirmware: { ...this.state.newFirmware, release_notes: {} },
        helperText:{
          ...this.state.helperText,
          release_notes: ""
        }
      });
      return;
    }
    if (this.validateReleaseNotes(file.name)) {
      this.setFile(file, 'release_notes');
    }
  };

  validateReleaseNotes = (fileName) => {
    if (fileName) {
      let filenNameSplits = fileName.split(".");
      let extension = filenNameSplits[filenNameSplits.length - 1];
      let isPdf = extension.toLowerCase() === "pdf";
      let message = isPdf ? "" : "Only pdf files are accepted";
      this.setState({
        helperText:{
          ...this.state.helperText,
          release_notes: message
        }
      });

      return isPdf;
    }
    return !this.state.helperText.release_notes;
  };

  setFile = (file, propName) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.setState({
        newFirmware: {
          ...this.state.newFirmware,
          [propName]: file,
        },
      });
    };
  };

  onVersionChange = (event) => {
    this.setState(
      {...this.state,
        newFirmware: {...this.state.newFirmware,
          version:event.target.value
        }
      }
    )
  }

  onModelNochange = (event) => {
      this.setState(
        {...this.state,
          newFirmware: { ...this.state.newFirmware,
            model_no: event.target.value
          }
        }
      )
    }

  onVersionValidate = (event) => {
    let version = this.state.newFirmware.version.toLowerCase();

    var helperTextVersion = "";

    let versionRe = /^v(\d+)\.(\d+)\.(\d+)(-rc(\d+))?$/;
    let versionValid = versionRe.test(version);

    if(!versionValid) {
      helperTextVersion = "Version format should be 'v<major>.<minor>.<patch>[-rcN]'";
    }

    this.setState({
      helperText: {
        ...this.state.helperText,
        version: helperTextVersion
      }
    })
    return versionValid
  }

  isValidFilename = () => {
    if (!this.state.newFirmware.filename.name) {
      this.setState({
        helperText: {
          ...this.state.helperText,
          filename: "No file selected"
        }
      })
      return false;
    }
    return true;
  }

  addNewFirmwares = () => {
    if (!this.isValidFilename() || !this.onVersionValidate() || !this.validateReleaseNotes()){
      return ;
    }
    this.props.addFirmware(
      this.state.newFirmware,
      response => {
        toast("Firmware Added Successfully", {
          autoClose: 5000,
          type: toast.TYPE.SUCCESS,
          position: toast.POSITION.BOTTOM_CENTER,
        });
        this.handleClose(true)
      },
      error => {
        var errorText = "";
				console.error(error);
        if (error.response) {
					if (error.response.data) {
						errorText = JSON.stringify(error.response.data)
						if (errorText.includes("unique")){
							errorText = "Firmware with same Version and Model already Exists"
						}
					} else {
						errorText = "Firmware Adding Failed. Server returned an error.";
					}
        } else {
          errorText = "Firmware Adding Failed. Timeout occurred, try again in sometime.";
        }
        toast(errorText, {
          autoClose: 5000,
          type: toast.TYPE.ERROR,
          position: toast.POSITION.TOP_CENTER,
        });
      });
  }

  deleteFirmware = (deleteFirmwareId) => {
    this.props.deleteFirmwareData(deleteFirmwareId,
    (successResponse)=> {
      toast("Firmware Deleted Successfully", {
        autoClose: 5000,
        type: toast.TYPE.SUCCESS,
        position: toast.POSITION.BOTTOM_CENTER,
      });
      this.setState({
        ...this.state
          }, ()=>{
            this.props.firmwaresList();
          });
      },
      (errorResponse)=> {
        toast("Not able to delete, Kindly contact Administrator", {
          autoClose: 5000,
          type: toast.TYPE.ERROR,
          position: toast.POSITION.TOP_CENTER,
        });
      });
    this.closeDeleteFirmwareAlert();
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = (fetch=false) => {
    this.setState({ ...initialState }, () => {
      if (fetch){
        this.props.firmwaresList();
      }
    });
  };

  openDeleteFirmwareAlert = (row) => {
    this.setState({
      openAlert: true,
      firmwareID: row.id
    })
  }

  closeDeleteFirmwareAlert = () => {
    this.setState({
      openAlert: false,
      firmwareID: null
    })
  }

  getUsername = (userId) => {
    const user = this.props.adminsInfo.find(admin => admin.id === userId);

    return user === undefined ? "-" : user.username;
  }

  handleKeyUp = (e) => {
    if (e.keyCode === 13) {
      this.addNewFirmwares();
    }
  }

  setHelperText = (data) => {
    let helperText = {};
    for (let propName in data) {
      if (this.state.helperText.hasOwnProperty(propName)) {
        helperText[propName] = data[propName].join('');
      }
    }
    this.setState({
       helperText: {
         ...this.state.helperText,
         ...helperText
       }
    })
  }

  getContentView() {
    const classes  = this.props;
    var notOperator = localStorage.getItem("roleKey") !== "operator" ? true : false;
    const firmwares = this.props.firmwareInfo;
    return (
      <div className="content-wrapper">
        <div className="content">
          <div>
            <div className="row main_top_headding">
              <div className="col-md-4"></div>
              <div className="col-md-4 main_top_headding_mid">
              <h4> <i className="fa fa-upload" aria-hidden="true"></i> &nbsp; BRH Firmwares</h4>
              </div>
              {(notOperator) ? (
              <div className="col-md-4 main_top_headding_right">
                <button
                  type="button"
                  className="btn btn-outline-secondary pull-right"
                  onClick={this.handleClickOpen}
                > <span className="glyphicon glyphicon-plus"></span>
                  &nbsp; <strong>
                  Add Firmware
                  </strong>
                </button>
              </div>
              ) : null }
            </div>
            <div className="row main_popup">
              <Dialog
                fullScreen
                open={this.state.open}
                onClose={this.handleClose}
                TransitionComponent={Transition}
                className="popup-module"
                disableBackdropClick
              >
        <AppBarComponent
          icon={"fa fa-upload"}
          title={"BRH Firmware"}
          handleClose={this.handleClose}
          />

                <DialogContent className="dialogContent">
                  <div className="col-md-4 col-sm-0"></div>
                  <div className="col-md-4 col-sm-12">
                    <div className="dialogContent-form">
                      <TextField
                          label="Firmware (*.tar.gz only)"
                          type="file"
                          margin="normal"
                          variant="outlined"
                          InputLabelProps={{
                              shrink: true,
                          }}
                          onChange={this.onFileNameChange}
                          helperText={this.state.helperText.filename}
                          error={Boolean(this.state.helperText.filename)}
                      />

                      <TextField
                          label="Release Notes (*.pdf only)"
                          type="file"
                          margin="normal"
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          onChange={this.onReleaseNotesChange}
                          helperText={this.state.helperText.release_notes}
                          error={Boolean(this.state.helperText.release_notes)}
                      />

                      <TextField
                          label="Version"
                          margin="normal"
                          variant="outlined"
                          InputLabelProps={{
                              shrink: true,
                          }}
                          onChange={this.onVersionChange}
                          value={this.state.newFirmware.version}
                          helperText={this.state.helperText.version}
                          error={Boolean(this.state.helperText.version)}
                          autoComplete="off"
                          placeholder="v<major>.<minor>.<patch>[-rcN]"
                          onKeyUp={this.handleKeyUp}
                      />

                      <div  className="formCtr">
                          <FormControl className="formCtr">
                              <InputLabel id="demo-simple-select-label">Model No</InputLabel>
                              <Select
                                  labelId="demo-simple-select-label"
                                  id="device-model-no"
                                  SelectProps={{
                                      MenuProps: {
                                      className: classes.menu
                                      }
                                  }}
                                  value={this.state.newFirmware.model_no}
                                  onChange={this.onModelNochange}
                               >
                              {BRHModels.map(option => (
                                  <MenuItem value={option}>{option}</MenuItem>
                              ))}
                              </Select>
                          </FormControl>
                      </div>

                      <div className="form-button-container">
                      <Button variant="outlined"
                          className="form-submit-button"
                          onClick={this.addNewFirmwares}
                      >
                        Add BRH Firmware
                      </Button>
                      <Button
                          variant="outlined"
                          className="form-cancel-button"
                          onClick={this.handleClose}
                      >
                        Cancel
                      </Button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-0"></div>
                </DialogContent>
              </Dialog>
            </div>


            <div className="row common_div_row">
              <Paper>
                <TableContainer className="table-all  table-responsive">
                  <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" className="text-wrap-head">File Name</TableCell>
                            <TableCell align="left" className="text-wrap-head">Uploaded by</TableCell>
                            <TableCell align="left" className="text-wrap-head">Model Number</TableCell>
                            <TableCell align="center" className="text-wrap-head">Version</TableCell>
                            <TableCell align="center" className="text-wrap-head">SHA256</TableCell>
                            {(notOperator)?
                              (
                                <TableCell align="center" className="text-wrap-head">Delete</TableCell>
                              ) : null
                            }
                            <TableCell align="center" className="text-wrap-head">Release Notes</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                      {firmwares.length > 0 && firmwares.map(row => (

                         <TableRow key={row.id}>
                            <TableCell align="left" className="text-wrap">{row.filename}</TableCell>
                            <TableCell align="left" className="text-wrap">{this.getUsername(row.uploaded_by)}</TableCell>
                            <TableCell align="left" className="text-wrap">{row.model_no}</TableCell>
                            <TableCell align="center" className="text-wrap">{row.version}</TableCell>
                            <TableCell align="center" className="text-wrap">{row.shasum}</TableCell>
                            {(notOperator) ?
                              (
                                <TableCell align="center" className="text-wrap">
                                <button
                                type="button"
                                className="btn btn-outline-secondary"
                                onClick={()=>{
                                  this.openDeleteFirmwareAlert(row);
                                }}
                                > <span className="glyphicon glyphicon-trash"></span>
                                </button>
                                </TableCell>
                              ) : null }
                            <TableCell align="center" className="text-wrap">
                                {row.release_notes ? (
                                  <Link href={`${base_url}frontend/firmwares/${row.id}/download/`+user_suffix} >
                                    <button type="button" className="btn btn-outline-secondary" >
                                      <span className="glyphicon glyphicon-download-alt" />
                                    </button>
                                  </Link>
                                ) : "-" }
                            </TableCell>
                        </TableRow>

                      ))}

                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </div>
            <AlertDialogSlide
              open={this.state.openAlert}
              handleClose={this.closeDeleteFirmwareAlert}
              title={"Delete Firmware?"}
              desc={"are you sure you want to delete the firmware?"}
              action={()=>this.deleteFirmware(this.state.firmwareID)}
            />
          </div>{/*<!-- main DIV ---> */}
        </div>
      </div>
    );
  }

  render() {
    return (
      <ContentWrapper
        contentView={this.getContentView()}
        netError={this.props.netError}
      />
    );
  }
}

//Required to tell redux how this component is mapped with dashboard reducers
function mapStateToProps(state) {
    return {
      firmwareInfo: state.firmwareInfo.firmwareInfo,
      adminsInfo: state.adminsInfo.adminsInfo,
      netError: state.firmwareInfo.netError,
    }
  }

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    changeSidebarButtonState,
    addFirmware,
    firmwaresList,
    deleteFirmwareData,
    adminsList
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(BRHfirmwaresComponent);
