import React from "react";
import dateFormat from "dateformat";
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  LayersControl,
  LayerGroup,
} from "react-leaflet";
import { Icon } from "leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import {} from "react-leaflet-fullscreen";
import "react-leaflet-fullscreen/dist/styles.css";

const redIcon = new Icon({
  iconUrl:
    "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

const greenIcon = new Icon({
  iconUrl:
    "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

function PopupTableComponent(device) {
  return (
    <div style={{ width: "300px" }}>
      <table style={{ "font-weight": "bold", width: "300px" }}>
        <tr>
          <td>
            <h4>Serial No: {device.provisioned_device.serial_no}</h4>
          </td>
        </tr>
        <tr>
          <td>
            <h4>Mac Address: {device.provisioned_device.mac_address}</h4>
          </td>
        </tr>
        <tr>
          <td>
            <h4>Model: {device.provisioned_device.model_no}</h4>
          </td>
        </tr>
        <tr>
          <td>
            <h4>Latitude, Longitude: {device.device_latitude.toFixed(4)}, {device.device_longitude.toFixed(4)}</h4>
          </td>
        </tr>
        <tr>
          <td>
            <h4>
              Last Updated Time: {dateFormat(device.device_updated_at)} UTC
            </h4>
          </td>
        </tr>
      </table>
    </div>
  );
}

function PopUpComponent(device) {
  return (
    <Popup
      position={[
        device.device_latitude, //Latitude
        device.device_longitude, //Latitude
      ]}
    >
      {PopupTableComponent(device)}
    </Popup>
  );
}

function MarkerComponent(devices, icon) {
  return (
    <Marker
      key={devices.provisioned_device.mac_address}
      position={[
        devices.device_latitude, //Latitude
        devices.device_longitude, //Longitude
      ]}
      icon={icon}
    >
      {PopUpComponent(devices)}
    </Marker>
  );
}

export default function MapcontainerComponent(props) {

  const activeDevices = props.devices.filter(
    (device) =>
      device.device_state !== -1 &&
      (parseInt(device.device_longitude) ||
        parseInt(device.device_latitude)) !== 0
  );

  const inactiveDevices = props.devices.filter(
    (device) =>
      device.device_state === -1 &&
      (parseInt(device.device_longitude) ||
        parseInt(device.device_latitude)) !== 0
  );

  var inactiveDevicesLegend = "Inactive Devices ("  + inactiveDevices.length + ")"
  var activeDevicesLegend = "Active Devices ("  + activeDevices.length + ")"

  const h4style = {
    position: "absolute",
    top: "15px",
    zIndex: "9999",
    textAlign: "right",
    left: "50%",
    marginLeft: "-75px",
    paddingLeft: "400px",
    color:"white",
  }

  return (
    <div>
      <h4 style={h4style} >Total Devices ({activeDevices.length + inactiveDevices.length})</h4>
      <MapContainer
        center={ 
          props.devices.length === 1 ? 
          [props.devices[0].device_latitude, props.devices[0].device_longitude] 
          : 
          [12.98, 77.59] 
        }
        zoom={13}
        scrollWheelZoom={true}
        maxZoom={18}
        attributionControl={false}
        minZoom={3}
        doubleClickZoom={false}
        touchZoom={true}
        fullscreenControl={true}
        style={{
          width: "100%",
          height: "100vh",
          position: "static",
        }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />

        {/* <LayersControl position="topright" collapsed={true} >
          <LayersControl.Overlay name='Details' checked={true}>
            <h4 style={{position: 'relative'}} >Total Number of Devices: {activeDevices.length + inactiveDevices.length}</h4>
          </LayersControl.Overlay>
        </LayersControl> */}

        <LayersControl position="topleft" collapsed={false}>
          <LayersControl.Overlay name={inactiveDevicesLegend} checked={props.devices.length === 1 ? true : false}>
            <LayerGroup>
              <MarkerClusterGroup disableClusteringAtZoom={19}>
                {inactiveDevices.map((device) =>
                  MarkerComponent(device, redIcon)
                )}
              </MarkerClusterGroup>
            </LayerGroup>
          </LayersControl.Overlay>

          <LayersControl.Overlay name={activeDevicesLegend} checked={true}>
            <LayerGroup>
              <MarkerClusterGroup disableClusteringAtZoom={19}>
                {activeDevices.map((device) =>
                  MarkerComponent(device, greenIcon)
                )}
              </MarkerClusterGroup>
            </LayerGroup>
          </LayersControl.Overlay>
        </LayersControl>
      </MapContainer>
    </div>
  );
}
