import React from "react";
import { Chart } from "react-google-charts";

export default function ChartComponent(props) {
  return (
    <Chart
      width={"100%"}
      height={"550px"}
      chartType="LineChart"
      loader={<div>Loading Chart</div>}
      data={props.chartData}
      options={{
        chartArea: { width: "90%", height: "85%" },
        pointSize: 8,

        legend: "bottom",
        hAxis: {
          format: "dd-MMM, HH:mm",
          ticks: props.ticks
        },
        vAxis: {
          textPosition: "none"
        },
        curveType: "function"
      }}
      rootProps={{ "data-testid": "2" }}
    />
  );
}
