export const dvbs2CodeRates = [
    {
        key: "1/4",
        value: 1
    },
    {
        key: "1/3",
        value: 2
    },
    {
        key: "2/5",
        value: 3
    },
    {
        key: "1/2",
        value: 4
    },
    {
        key: "3/5",
        value: 5
    },
    {
        key: "2/3",
        value: 6
    },
    {
        key: "3/4",
        value: 7
    },
    {
        key: "4/5",
        value: 8
    },
    {
        key: "5/6",
        value: 9
    },
    {
        key: "8/9",
        value: 10
    },
    {
        key: "9/10",
        value: 11
    },
    {
        key: "3/5",
        value: 12
    },
    {
        key: "2/3",
        value: 13
    },
    {
        key: "3/4",
        value: 14
    },
    {
        key: "5/6",
        value: 15
    },
    {
        key: "8/9",
        value: 16
    },
    {
        key: "9/10",
        value: 17
    },
    {
        key: "2/3",
        value: 18
    },
    {
        key: "3/4",
        value: 19
    },
    {
        key: "4/5",
        value: 20
    },
    {
        key: "5/6",
        value: 21
    },
    {
        key: "8/9",
        value: 22
    },
    {
        key: "9/10",
        value: 23
    },
    {
        key: "3/4",
        value: 24
    },
    {
        key: "4/5",
        value: 25
    },
    {
        key: "5/6",
        value: 26
    },
    {
        key: "8/9",
        value: 27
    },
    {
        key: "9/10",
        value: 28
    }
];

export const dvbs2CodeRatesOp4 = [
  {
    key: "1/4",
    value: 0
  },
  {
    key: "1/3",
    value: 1
  },
  {
    key: "2/5",
    value: 2
  },
  {
    key: "1/2",
    value: 3
  },
  {
    key: "3/5",
    value: 4
  },
  {
    key: "2/3",
    value: 5
  },
  {
    key: "3/4",
    value: 6
  },
  {
    key: "4/5",
    value: 7
  },
  {
    key: "5/6",
    value: 8
  },
  {
    key: "8/9",
    value: 9
  },
  {
    key: "9/10",
    value: 10
  },
  {
  key: "2/9",
  value: 11
  },
  {
    key: "13/45",
    value: 12
  },
  {
    key: "9/20",
    value: 13
  },
  {
    key: "90/180",
    value: 14
  },
  {
    key: "96/180",
    value: 15
  },
  {
    key: "11/20",
    value: 16
  },
  {
    key: "100/180",
    value: 17
  },
  {
    key: "104/180",
    value: 18
  },
  {
    key: "26/45",
    value: 19
  },
  {
    key: "18/30",
    value: 20
  },
  {
    key: "28/45",
    value: 21
  },
  {
  key: "23/36",
  value: 22
  },
  {
  key: "116/180",
  value: 23
  },
  {
  key: "20/30",
  value: 24
  },
  {
  key: "124/180",
  value: 25
  },
  {
  key: "25/36",
  value: 26
  },
  {
  key: "128/180",
  value: 27
  },
  {
  key: "13/18",
  value: 28
  },
  {
  key: "132/180",
  value: 29
  },
  {
  key: "22/30",
  value: 30
  },
  {
  key: "135/180",
  value: 31
  },
  {
  key: "140/180",
  value: 32
  },
  {
  key: "7/9",
  value: 33
  },
  {
  key: "154/180",
  value: 34
  },
  {
  key: "11/45",
  value: 35
  },
  {
  key: "4/15",
  value: 36
  },
  {
  key: "14/45",
  value: 37
  },
  {
  key: "7/15",
  value: 38
  },
  {
  key: "8/15",
  value: 39
  },
  {
  key: "32/45",
  value: 40
  }
];


export const dvbs2Modulations = [
    {
      key: "QPSK",
      value: 0
    },
    {
      key: "8PSK",
      value: 1,
    },
    {
      key: "16APSK",
      value: 2,
    },
    {
      key: "32APSK",
      value: 3,
    }
];

export const dvbs2ModulationsOp4 = [
    {
      key: "BPSK",
      value: 1
    },
    {
      key: "QPSK",
      value: 2
    },
    {
      key: "8PSK",
      value: 3,
    },
    {
      key: "16APSK",
      value: 4,
    },
    {
      key: "32APSK",
      value: 5,
    },
    {
      key: "64APSK",
      value: 6,
    },
    {
      key: "128APSK",
      value: 7,
    },
    {
      key: "256APSK",
      value: 8,
    }
];



export const plpModulations = [
  {
    key: "QPSK",
    value: 0
  },

  {
    key: "16QAM-NUC",
    value: 1
  },


  {
    key: "64QAM-NUC",
    value: 2
  },

  {
    key: "256QAM-NUC",
    value: 3
  },

  {
    key: "1024QAM-NUC",
    value: 4
  },

  {
    key: "4096QAM-NUC",
    value: 5
  }
];

export const plpCodeRates = [

  {
    key: "2/15",
    value: 0
  },
  {
    key: "3/15",
    value: 1
  },
  {
    key: "4/15",
    value: 2
  },
  {
    key: "5/15",
    value: 3
  },
  {
    key: "6/15",
    value: 4
  },
  {
    key: "7/15",
    value: 5
  },
  {
    key: "8/15",
    value: 6
  },
  {
    key: "9/15",
    value: 7
  },
  {
    key: "10/15",
    value: 8
  },
  {
    key: "11/15",
    value: 9
  },
  {
    key: "12/15",
    value: 10
  },
  {
    key: "13/15",
    value: 11
  }

];

export const transmitFrequencies6M = [
  {
    value: 473.0,
    key: "473 / CH-14"
  },
  {
    value: 479.0,
    key: "479 / CH-15"
  },
  {
    value: 485.0,
    key: "485 / CH-16"
  },
  {
    value: 491.0,
    key: "491 / CH-17"
  },
  {
    value: 497.0,
    key: "497 / CH-18"
  },
  {
    value: 503.0,
    key: "503 / CH-19"
  },
  {
    value: 509.0,
    key: "509 / CH-20"
  },
  {
    value: 515.0,
    key: "515 / CH-21"
  },
  {
    value: 521.0,
    key: "521 / CH-22"
  },
  {
    value: 527.0,
    key: "527 / CH-23"
  },
  {
    value: 533.0,
    key: "533 / CH-24"
  },
  {
    value: 539.0,
    key: "539 / CH-25"
  },
  {
    value: 545.0,
    key: "545 / CH-26"
  },
  {
    value: 551.0,
    key: "551 / CH-27"
  },
  {
    value: 557.0,
    key: "557 / CH-28"
  },
  {
    value: 563.0,
    key: "563 / CH-29"
  },
  {
    value: 569.0,
    key: "569 / CH-30"
  },
  {
    value: 575.0,
    key: "575 / CH-31"
  },
  {
    value: 581.0,
    key: "581 / CH-32"
  },
  {
    value: 590.0,
    key: "590 / CH-DD"
  },
  {
    value: 587.0,
    key: "587 / CH-33"
  },
  {
    value: 593.0,
    key: "593 / CH-34"
  },
  {
    value: 599.0,
    key: "599 / CH-35"
  },
  {
    value: 605.0,
    key: "605 / CH-36"
  },
  ]


export const transmitFrequencies8M = [
  {
    value: 474.0,
    key: "474 / CH-21"
  },
  {
    value: 482.0,
    key: "482 / CH-22"
  },
  {
    value: 490.0,
    key: "490 / CH-23"
  },
  {
    value: 498.0,
    key: "498 / CH-24"
  },
  {
    value: 506.0,
    key: "506 / CH-25"
  },
  {
    value: 514.0,
    key: "514 / CH-26"
  },
  {
    value: 522.0,
    key: "522 / CH-27"
  },
  {
    value: 530.0,
    key: "530 / CH-28"
  },
  {
    value: 538.0,
    key: "538 / CH-29"
  },
  {
    value: 546.0,
    key: "546 / CH-30"
  },
  {
    value: 554.0,
    key: "554 / CH-31"
  },
  {
    value: 562.0,
    key: "562 / CH-32"
  },
  {
    value: 570.0,
    key: "570 / CH-33"
  },
  {
    value: 578.0,
    key: "578 / CH-34"
  },
  {
    value: 586.0,
    key: "586 / CH-35"
  },
  {
    value: 590.0,
    key: "590 / CH-DD"
  },
  {
    value: 594.0,
    key: "594 / CH-36"
  },
  {
    value: 602.0,
    key: "602 / CH-37"
  },
]

export const transmitFrequencies5w = [
  {
    value: 724.8,
    key: "724.8 / CH-1"
  },
  {
    value: 724.9,
    key: "724.9 / CH-2"
  },
  {
    value: 725.0,
    key: "725 / CH-3"
  },
  {
    value: 725.1,
    key: "725.1 / CH-4"
  },
  {
    value: 725.2,
    key: "725.2 / CH-5"
  },
  {
    value: 725.3,
    key: "725.3 / CH-6"
  }
]



export const paControl = [
  {
    value: 0,
    key: "OFF"
  },
  {
    value: 1,
    key: "ON"
  },
]


export const plpGuardInterval = [
  {
    key: "Reserved",
    value: 0
  },
  {
    key: "GI1_192",
    value: 1
  },
  {
    key: "GI2_384",
    value: 2
  },
  {
    key: "GI3_512",
    value: 3
  },
  {
    key: "GI4_768",
    value: 4
  },
  {
    key: "GI5_1024",
    value: 5
  },
  {
    key: "GI6_1536",
    value: 6
  },
  {
    key: "GI7_2048",
    value: 7
  },
  {
    key: "GI8_2432",
    value: 8
  },
  {
    key: "GI9_3072",
    value: 9
  },
  {
    key: "GI10_3648",
    value: 10
  },
  {
    key: "GI11_4096",
    value: 11
  },
  {
    key: "GI12_4864",
    value: 12
  },
  {
    key: "Reserved",
    value: 13
  },
  {
    key: "Reserved",
    value: 14
  },
  {
    key: "Reserved",
    value: 15
  },
  ]

export const plpPilotConfig = [
  {
    key: "SP3_2",
    value: 0
  },
  {
    key: "SP3_4",
    value: 1
  },
  {
    key: "SP4_2",
    value: 2
  },
  {
    key: "SP4_4",
    value: 3
  },
  {
    key: "SP6_2",
    value: 4
  },
  {
    key: "SP6_4",
    value: 5
  },
  {
    key: "SP8_2",
    value: 6
  },
  {
    key: "SP8_4",
    value: 7
  },
  {
    key: "SP12_2",
    value: 8
  },
  {
    key: "SP12_4",
    value: 9
  },
  {
    key: "SP16_2",
    value: 10
  },
  {
    key: "SP16_4",
    value: 11
  },
  {
    key: "SP24_2",
    value: 12
  },
  {
    key: "SP24_4",
    value: 13
  },
  {
    key: "SP32_2",
    value: 14
  },
  {
    key: "SP32_4",
    value: 15
  },
]

export const fftSizes = [
  {
    key: "8K",
    value: 0
  },
  {
    key: "16K",
    value: 1
  },
  {
    key: "32K",
    value: 2
  }
]

export const outerParityTypes = [ "BCH", "CRC", "None"]

export const ldpcTypes = [ "Type-A", "Type-B"]

export const polarizationValues = [ "RHCP_18v"]

export const RfPowerLevels = [
  {
    value: 0,
    key: "10W"
  },
  {
    value: 1,
    key: "20W"
  },
  {
    value: 2,
    key: "30W"
  },
  {
    value: 3,
    key: "40W"
  }
]

export const RfPowerLevels5w = [
  {
    value: 0,
    key: "30dBm/1W"
  },
  {
    value: 1,
    key: "31dBm/1.26W"
  },
  {
    value: 2,
    key: "32dBm/1.58W"
  },
  {
    value: 3,
    key: "33dBm/2W"
  },
  {
    value: 4,
    key: "34dBm/2.51W"
  },
  {
    value: 5,
    key: "34.8dBm/3W"
  },
  {
    value: 6,
    key: "35dBm/3.16W"
  },
  {
    value: 7,
    key: "36dBm/4W"
  },
  {
    value: 8,
    key: "37dBm/5W"
  }
]



export const TunerVoltages = [
  {
    value: 0,
    key: "RHCP 13V"
  },
  {
    value: 1,
    key: "LHCP 18V"
  },
  {
    value: 2,
    key: "RHCP 13V with 22KHz tone"
  },
  {
    value: 3,
    key: "LHCP 18V with 22KHZ tone"
  }
]

export const BRHModels = ['SLBRH40WA', 'SLBRH40WB', 'SLBRH05WA'];

export const MaxSubframes = 4;
export const MaxPlpsPerSubframe = 4;
