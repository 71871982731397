
import { API_URL } from '../../../../utils/constants';
import HTTPRequestHandler from '../../../../utils/HTTPRequestHandler';
const httpRequestHandler = new HTTPRequestHandler();
const operator_url = API_URL + '/provisioning/operators/';
const user_url = API_URL + '/provisioning/admins/';
const user_id = localStorage.getItem("user_id");
const user_name = localStorage.getItem("username");
const role_name = localStorage.getItem("roleKey");
const provisioining_url = "provisioning/devices/?user_id=" + user_id + "&user_name=" + user_name;


export function fetchDevicesData() {
    return (function (dispatch) {
        httpRequestHandler
        .get(provisioining_url)
        .then((response) => {
            dispatch({
                type: 'FETCH_DEVICES_DATA_SUCCESS',
                payload: response.data
            });
        }).catch((error) => {
            console.error('Error ', error);
            dispatch({
                type: 'FETCH_DEVICES_DATA_FAILURE',
                error
            });
        })
    });
};

export function addDevices(newdevice, successCallback, errorCallback) {
    return (function (dispatch) {
        httpRequestHandler
        .post(provisioining_url, newdevice)
        .then((response) => {
           successCallback(response.data)
        })
        .catch((error) => {
          errorCallback(error)
        })
    });
};

export function editDevices(id, editedFields, successCallback, errorCallback) {
    return (function (dispatch) {
        httpRequestHandler
        .patch('provisioning/devices/' + id  + '/?user_id=' + user_id + '&user_name=' + user_name, editedFields)
        .then((response) => {
           successCallback(response.data)
        })
        .catch((error) => {
          errorCallback(error)
        })
    });
};


export function deleteDevicesRow(deleteData, successCallback, errorCallback) {
    return (function (dispatch) {
        httpRequestHandler
        .delete('provisioning/devices/'+ deleteData +'/?user_id=' + user_id + '&user_name=' + user_name + '&role_name=' + role_name)
        .then((response) => {
           successCallback(response.data)
        })
        .catch((error) => {
          errorCallback(error)
        })
    });
};

export function adminsList() {
    return (function (dispatch) {
        httpRequestHandler
        .get(user_url)
        .then(response => {
            dispatch({
                type: 'FETCH_ADMINS_DATA_SUCCESS',
                payload: response.data
            });
       })
        .catch(error => console.error(error.response));
    });
};

export function operatorsList() {
    return (function (dispatch) {
        httpRequestHandler
        .get(operator_url)
        .then((response) => {
            dispatch({
                type: 'FETCH_OPERATORS_DATA_SUCCESS',
                payload: response.data
            });
        })
        .catch((error) => {
            console.error('Error', error);
        })
    });
};

export function configurationList() {
    return (function (dispatch) {
        httpRequestHandler
        .get('provisioning/configs/').then((response) => {
            dispatch({
                type: 'FETCH_CONFIGURATION_DATA_SUCCESS',
                payload: response.data
            });
        }).catch((error) => {
            console.error('Error', error);
        })
    });
};

export function uploadCert(deviceId, cert,key, successCallback, errorCallback) {
    return (function (dispatch) {
    httpRequestHandler
    .post('provisioning/devices/'+ deviceId +'/uploadcert/?user_id=' + user_id + '&user_name=' + user_name, {"cert":cert,"key":key})
        .then((response) => {
            successCallback(response.data)
        })
        .catch((error) => {
            errorCallback(error)
        })
    });
}

export function revokeCert(deviceId, successCallback, errorCallback) {
    return (function (dispatch) {
    httpRequestHandler
    .post('provisioning/devices/'+ deviceId +'/revokecert/?user_id=' + user_id + '&user_name=' + user_name)
        .then((response) => {
            successCallback(response.data)
        })
        .catch((error) => {
            errorCallback(error)
        })
    });

}
