const initialState = {
  alarmConfigsInfo: [],
  netError: null
};

export default (state = initialState, action) => {
  let currentState = state;
  switch (action.type) {
    case "FETCH_ALARMS_CONFIGS_DATA_SUCCESS":
      currentState = {
        ...state,
        alarmConfigsInfo: action.payload,
        netError: null
      };
      break;

    case "FETCH_ALARMS_CONFIGS_DATA_FAILURE":
      currentState = { ...state, netError: action.error };
      break;

    default:
  }
  return currentState;
};
