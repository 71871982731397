import React from 'react';
import { Route, Switch, Router, Redirect } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import SigninComponent from './pages/signin/components/SigninComponent';
import DashboardComponent from './pages/dashboard/components/DashboardComponent';
import BRHDeviceComponent from './pages/brhdevice/components/BRHDeviceComponent';
import NotificationsComponent from './pages/notifications/components/notificationsComponents';
import ConfigurationsComponent from './pages/configurations/components/configurationsComponents';
import AuditlogsComponent from './pages/auditlogs/components/auditlogsComponents';
import DeviceComponent from './pages/provisioning/devices/components/DeviceComponent';
import OperatorComponent from './pages/provisioning/operators/components/OperatorComponent';
import AdminComponent from './pages/provisioning/admins/components/AdminComponent';
import BRHfirmwaresComponent from './pages/brhfirmwares/components/brhfirmwaresComponents';
import AlarmConfigsComponent from './pages/provisioning/alarmConfigs/components/AlarmConfigsComponent';
import settingComponents from './pages/setting/components/settingComponents';

import TitleComponent from './common/components/TitleComponent'


const Status = function ({ code, children }) {
  return (
    <Router
      render={function ({ staticContext }) {
        if (staticContext) {
          staticContext.status = code;
        }
        return children;
      }}
    />
  );
};

const theme = createMuiTheme({
  palette: {
    type: 'light',
  },
});

const NotFound = function () {
  return (
    <Status code={404}>
      <div>
        <h2> Sorry, can’t find this page</h2>
      </div>
    </Status>
  );
};

const withTitle = ({component: Component, title}) => {
  return class Title extends React.Component {
    render(){
      const favicon = ( process.env.REACT_APP_FAVICON_PATH || "/favicon.png");
      const faviconType = (process.env.REACT_APP_FAVICON_TYPE || "image/png");

      return (
        <React.Fragment>
          <TitleComponent title={title} favicon={favicon} faviconType={faviconType} />
          <Component {...this.props} />
        </React.Fragment>
      );
    }
  }
}

const SigninComponentTitle = withTitle({component: SigninComponent, title: 'Login'})
const DashboardComponentTitle = withTitle({component: DashboardComponent, title: 'Dashboard'})
const BRHDeviceComponentTitle = withTitle({component: BRHDeviceComponent, title: 'BRH Devices'})
const NotificationsComponentTitle = withTitle({component: NotificationsComponent, title: 'Alarms'})
const ConfigurationsComponentTitle = withTitle({component: ConfigurationsComponent, title: 'Configurations \u00BB Devices'})
const AlarmConfigsComponentTitle = withTitle({component: AlarmConfigsComponent, title: 'Configurations \u00BB Alarms'})
const AdminComponentTitle = withTitle({component: AdminComponent, title: 'Provisioning \u00BB Admins'})
const OperatorComponentTitle = withTitle({component: OperatorComponent, title: 'Provisioning \u00BB Operators'})
const DeviceComponentTitle = withTitle({component: DeviceComponent, title: 'Provisioning \u00BB Devices'})
const AuditlogsComponentTitle = withTitle({component: AuditlogsComponent, title: 'Audit Logs'})
const BRHfirmwaresComponentTitle = withTitle({component: BRHfirmwaresComponent, title: 'BRH Firmwares'})
const settingComponentsTitle = withTitle({component: settingComponents, title: 'Settings'})
const NotFoundTitle = withTitle({component: NotFound, title: '404 Page Not Found'})

const Routes = (
  <MuiThemeProvider theme={theme} >
    <Switch>
      <Route path='/' exact={true} component={SigninComponentTitle} />
      <Route path='/signin' exact={true} component={SigninComponentTitle} />
      <Route exact path="/" render={() => <Redirect to="/signin" />} />
      <Route path='/dashboard' exact={true} component={DashboardComponentTitle} />
      <Route path='/brhdevices' exact={true} component={BRHDeviceComponentTitle} />
      <Route path='/notifications' exact={true} component={NotificationsComponentTitle} />
      <Route path='/admins' exact={true} component={AdminComponentTitle} />
      <Route path='/operators' exact={true} component={OperatorComponentTitle} />
      <Route path='/devices' exact={true} component={DeviceComponentTitle} />
      <Route path='/configurations' exact={true} component={ConfigurationsComponentTitle} />
      <Route path='/auditlogs' exact={true} component={AuditlogsComponentTitle} />
      <Route path='/brhfirmwares' exact={true} component={BRHfirmwaresComponentTitle} />
      <Route path='/alarms' exact={true} component={AlarmConfigsComponentTitle} />
      <Route path='/settings' exact={true} component={settingComponentsTitle} />
      <Route component={NotFoundTitle} />
    </Switch>
  </MuiThemeProvider>
);

export default Routes;
