const initialState = {
  rebootsCount: null,
  activeDevicesCount: null,
  totalDevicesCount: null,
  totalAlarmsCount: null,
  netError: null,
	isEULAAccepted: false
};

export default (state = initialState, action) => {
  let currentState = state;
  switch (action.type) {
    case "FETCH_BRHDEVICES_COUNT_SUCCESS":
      let totalDevicesCount = action.payload.length;
      let activeDevicesCount = 0;
      let rebootsCount = 0;
      action.payload.forEach(device => {
        if (device.device_state === 3) activeDevicesCount++;
        if (device.device_reboot_counts) {
          rebootsCount += device.device_reboot_counts;
        }
      });
      currentState = {
        ...state,
        activeDevicesCount,
        totalDevicesCount,
        rebootsCount,
        netError: null
      };
      break;

    case "FETCH_ALARMS_COUNT_SUCCESS":
      currentState = {
        ...state,
        totalAlarmsCount: action.payload,
        netError: null
      };
      break;

    case "FETCH_ALARMS_COUNT_FAILURE":
    case "FETCH_BRHDEVICES_COUNT_FAILURE":
      currentState = {
        ...state,
        netError: action.error,
        activeDevicesCount: null,
        totalDevicesCount: null,
        totalAlarmsCount: null,
      };
      break;

		case "USER_ACCEPTED_EULA":
			currentState = {
				...state,
				isEULAAccepted: action.payload,
			};
			break;
    default:
  }
  return currentState;
};
