const initialState = {
    firmwareInfo:[],
    netError: null
}

export default  (state = initialState, action) => {
    let currentState = state;
    switch(action.type) {
        case 'FETCH_FIRMWARE_GET_DATA_SUCCESS':
            currentState = {...state,
                firmwareInfo: action.payload,
                netError: null
            };
        break;

        case 'FETCH_FIRMWARE_GET_DATA_FAILURE':
            currentState = {...state, netError: action.error};
        break;

        default:
    }
    return currentState;
}
