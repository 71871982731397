import React, { Component } from 'react';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Routes from './Routes';
import reducers from './common/reducers';
const middleware = applyMiddleware(thunk);
const initialState = window.INITIAL_STATE;
export const store = createStore(reducers, initialState, middleware);

class App extends Component {
  render() {
    return (
      <div>
      <Provider store={store}>
        <Router>
          {Routes}
        </Router>
      </Provider>
      <ToastContainer hideProgressBar/>
      </div>
    );
  }
}

export default App;
