import HTTPRequestHandler from "../../utils/HTTPRequestHandler";

const httpRequestHandler = new HTTPRequestHandler();
const user_id = localStorage.getItem("user_id");
const user_name = localStorage.getItem("username");
const alarm_url = "frontend/alarms/?user_id=" + user_id + "&user_name=" + user_name;
const user_suffix = "?user_id=" + user_id + "&user_name=" + user_name;

export function fetchAlarmsData(
  url = alarm_url+"&page=1&alarm_state=0",
  append = false
) {
  return function(dispatch) {
    httpRequestHandler
      .get(url)
      .then(response => {
        dispatch({
          type: "FETCH_ALARMS_DATA_SUCCESS",
          payload: response.data.results,
          nextLink: response.data.next,
          prevLink: response.data.previous,
          append: append,
          count: response.data.count
        });
      })
      .catch(error => {
        console.error(error);
        dispatch({
          type: "FETCH_ALARMS_DATA_FAILURE",
          error
        });
      });
  };
}

export function acknowledgeAlarmData(alarmId, data, successCallback, errorCallback) {
  return function(dispatch) {
    httpRequestHandler
      .post("frontend/alarms/" + alarmId + "/acknowledge/"+user_suffix, data)
      .then(response => {
        successCallback(response.data);
      })
      .catch(error => {
        errorCallback(error);
      });
  };
}
