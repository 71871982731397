const insialstate = {
    dashboardSidebar: {
        activeButton: 'dashboard'
    }
};

export function dashboardSidebarReducers ( state = insialstate, action){
    let currentState = state;
    switch (action.type) {
        case 'BRHDEVICES_BTN_ACTIVE':
        currentState = { ...state,
            dashboardSidebar: {
                activeButton: 'brhdevices'
            }
        };
        break;

        case 'NOTIFICATIONS_BTN_ACTIVE':
        currentState = { ...state,
            dashboardSidebar: {
                activeButton: 'notifications'
            }
        };
        break;

        case 'AUDITLOGS_BTN_ACTIVE':
        currentState = { ...state,
            dashboardSidebar: {
                activeButton: 'auditlogs'
            }
        };
        break;

        case 'BRHFIRMWARES_BTN_ACTIVE':
        currentState = { ...state,
            dashboardSidebar: {
                activeButton: 'brhfirmwares'
            }
        };
        break;

        case 'ADMINS_BTN_ACTIVE':
        currentState = { ...state,
            dashboardSidebar: {
                activeButton: 'provisioning',
                submenu: 'admins'
            }
        };
        break;

        case 'OPERATORS_BTN_ACTIVE':
        currentState = { ...state,
            dashboardSidebar: {
                activeButton: 'provisioning',
                submenu: 'operators'
            }
        };
        break;

        case 'DEVICES_BTN_ACTIVE':
        currentState = { ...state,
            dashboardSidebar: {
                activeButton: 'provisioning',
                submenu: 'device',

            }
        };
        break;

        case 'ALARMS_BTN_ACTIVE':
        currentState = { ...state,
            dashboardSidebar: {
                activeButton: 'configuration',
                submenu: 'alarms'
            }
        };
        break;

        case 'CONFIGURATION_BTN_ACTIVE':
        currentState = { ...state,
            dashboardSidebar: {
                activeButton: 'configuration',
                submenu: 'devices'
            }
        };
        break;
        default:
    }
    return currentState;
}

export default dashboardSidebarReducers;
