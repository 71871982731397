import React, { Component } from "react";
import Header from "../../../src/common/components/Header";
import Footer from "../../../src/common/components/Footer";
import Menu from "../../../src/common/components/Menu";
import LoadingOverlay from "react-loading-overlay";
import LoaderRequest from "../../../src/common/components/LoaderRequest";

class ContentWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialMount: true,
      loading: true,
      showSpinner: true,
      loaderText: "Loading Content..."
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (!props.netError) {
      if (state.initialMount) {
        // when called after constructor
        return { initialMount: false };
      } else {
        return { loading: false };
      }
    } else {
      return {
        loading: true,
        showSpinner: false,
        loaderText: "Network Error Occurred. Try again later."
      };
    }
  }

  render() {
    return (
      <div>
        <div> <Header /></div>
        <div>
        <Menu />
        <LoaderRequest />
        <LoadingOverlay
          active={this.state.loading}
          spinner={this.state.showSpinner}
          text={this.state.loaderText}
          styles={{
            overlay: base => ({
              ...base,
              background: "rgba(128,128,128, 1)"
            })
          }}
        >
          {this.props.contentView}
        </LoadingOverlay>
        </div>
        <Footer />
      </div>
    );
  }
}

export default ContentWrapper;
