import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import Slide from "@material-ui/core/Slide";
import validator from "validator";
import HTTPRequestHandler from "../../../utils/HTTPRequestHandler";
import { toast } from "react-toastify";
import AppBarComponent from "../../../common/components/AppBarComponent";

const httpRequestHandler = new HTTPRequestHandler();

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


class ForgotPasswordComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      username: "",
      helperTextEmail: "",
      helperTextUsername: "",
      errorEmail: false,
      errorUsername: false
    };
  }

  handleClose = () => {
    this.setState(
      {
        email: "",
        username: "",
        helperTextEmail: "",
        helperTextUsername: "",
        errorEmail: false,
        errorUsername: false
      },
      () => {
        this.props.handleClose();
      }
    );
  };

  onEmailChange = event => {
    this.setState({
      email: event.target.value
    });
  };

  onUsernameChange = event => {
    this.setState({
      username: event.target.value
    });
  };

  showToast = statusCode => {
    if (statusCode === 200 || statusCode === 404) {
      toast(
        "You should receive password reset instructions at this email address " +
          "if it is registered for a user with given username",
        {
          autoClose: 5000,
          type: toast.TYPE.INFO,
          position: toast.POSITION.TOP_CENTER
        }
      );
    } else {
      toast("unknown error. please try again later", {
        autoClose: 5000,
        type: toast.TYPE.ERROR,
        position: toast.POSITION.TOP_CENTER
      });
    }
    this.props.handleClose();
  };

  sendEmail = () => {
    if (validator.isEmail(this.state.email) && !validator.isEmpty(this.state.username)) {
      this.setState(
        {
          helperTextEmail: "",
          errorEmail: false,
          helperTextUsername: "",
          errorUsername: false
        },
        () => {
          httpRequestHandler
            .post("forgotpassword/", { email: this.state.email, username: this.state.username })
            .then(response => {
              this.showToast(response.status);
            })
            .catch(error => {
              if (error.response && error.response.data.message) {
                this.showToast(error.response.status);
              } else {
                this.showToast(500);
              }
            });
        }
      );
    } else
    {
      if (!validator.isEmail(this.state.email)) {
        this.setState({
          helperTextEmail: "Enter Valid Email Address!",
          errorEmail: true,
        });
      } else {
        this.setState({
          helperTextEmail: "",
          errorEmail: false,
        });
      }

      if (validator.isEmpty(this.state.username)) {
        this.setState({
          helperTextUsername: "Enter Valid Username!",
          errorUsername: true
        });
      } else {
        this.setState({
          helperTextUsername: "",
          errorUsername: false,
        });
      }
    }
  };

  render() {
    return (
      <div className="row main_popup">
        <Dialog
          fullScreen
          open={this.props.open}
          onClose={this.handleClose}
          TransitionComponent={Transition}
          className="popup-module"
          disableBackdropClick
        >
          <AppBarComponent
            icon={"fa fa-unlock-alt"}
            title={"Forgot Password"}
            handleClose={this.handleClose}
          />

          <DialogContent className="dialogContent">
            <div className="col-md-4 col-sm-0"></div>
            <div className="col-md-4 col-sm-12">
              <form className="dialogContent-form">
                <TextField
                  required
                  label="User Name"
                  type="text"
                  helperText={this.state.helperTextUsername}
                  error={this.state.errorUsername}
                  style={{ marginBottom: 8 }}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={this.onUsernameChange}
                />
                <TextField
                  required
                  label="Email Id"
                  type="text"
                  helperText={this.state.helperTextEmail}
                  error={this.state.errorEmail}
                  style={{ marginBottom: 8 }}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={this.onEmailChange}
                  onKeyUp={(e) => e.keyCode === 13 && this.sendEmail()}
                />
                <div className="form-button-container">
                <Button
                  variant="outlined"
                  className="form-submit-button"
                  onClick={this.sendEmail}
                >
                  Send Email
                  <i className="fa fa-paper-plane" aria-hidden="true"></i>
                </Button>
                <Button
                  variant="outlined"
                  className="form-cancel-button"
                  onClick={this.handleClose}
                >
                  Cancel
                </Button>
                </div>
              </form>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default ForgotPasswordComponent;
