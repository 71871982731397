import HTTPRequestHandler from '../../../utils/HTTPRequestHandler';
const httpRequestHandler = new HTTPRequestHandler();

export function signinAction(apiInput, successCallBack, errorCallBack) {
  return (function (dispatch) {
        const url = 'login/';

        httpRequestHandler
        .post(url, apiInput)
        .then((response) => {
            localStorage.setItem('roleKey',response.data.role);
            localStorage.setItem('username',response.data.username);
            localStorage.setItem('user_id',response.data.user_id);
            localStorage.setItem('organization',response.data.organization);
						localStorage.setItem('eulaAccepted', response.data.eula_accepted);
            successCallBack(response);
            window.location.reload();
        }).catch((error) => {
            let errorResponse = error.response ? error.response : error
            errorCallBack(errorResponse);
        });
    });
}

export function resetPasswordAPI(data, successCB, errorCB) {
    httpRequestHandler.post("resetpassword", data)
    .then(response => {
        successCB(response.data.message);
    })
    .catch(err => {
        errorCB(err.response.data.message);
    })
}
