import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  Dialog,
  DialogContent,
  Card,
  CardContent,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Slide,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
} from "@material-ui/core";

import AppBarComponent from "../../../../common/components/AppBarComponent";
import {
  firmwaresList,
  upgradeDevice,
  upgradeManyDevicesAPI,
  fetchUpgradeTaskStatus,
} from "../../actions/brhdeviceActions";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const styles = {
  formControl: {
    minWidth: 120,
    maxWidth: 300,
  },
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MAX_SELECT_DEVICES_LIMIT = 5;

const initialState = {
  selectedDevices: [],
  note: "",
  upgrade: {
    firmware_id: "0",
    swap_and_reboot: 1,
  },
};

class UpgradeModal extends React.Component {
  state = { ...initialState };

  componentDidMount() {
    this.props.firmwaresList(this.props.selectedModel);
  }

  handleChangeSelectedDevices = (e) => {
    if (e.target.value.length <= MAX_SELECT_DEVICES_LIMIT) {
      this.setState({ selectedDevices: e.target.value });
    }
  };

  handleChangeNote = (e) => this.setState({ note: e.target.value });

  onVersionChange = (event) => {
    this.setState({
      upgrade: { ...this.state.upgrade, firmware_id: event.target.value },
    });
  };

  autoSwapChange = () => {
    this.setState({
      upgrade: {
        ...this.state.upgrade,
        swap_and_reboot: this.state.upgrade.swap_and_reboot ? 0 : 1,
      },
    });
  };

  handleClose = () => {
    this.setState({ ...initialState });
    this.props.handleClose();
  };

  showToast = (message, type = "info") => {
    toast(message, {
      autoClose: 5000,
      position: "top-center",
      type,
    });
  };

  onUpgradeClick = () => {
    if (this.state.upgrade.firmware_id === "0") {
      this.showToast("Please select a valid firmware version", "error");
      return;
    }
    if (this.props.upgradeMany) {
      if (!this.state.selectedDevices.length) {
        this.showToast("Please select devices to upgrade", "error");
        return;
      }
      this.upgradeManyDevices();
    } else {
      this.upgradeRunningDevice();
    }
  };

  upgradeManyDevices = () => {
    const data = this.state.note
      ? {
          ...this.state.upgrade,
          devices: this.state.selectedDevices,
          note: this.state.note,
        }
      : { ...this.state.upgrade, devices: this.state.selectedDevices };
    upgradeManyDevicesAPI(
      data,
      (successResponse) => {
        this.showToast(
          "Software upgrade requested for selected devices. See Auditlogs for the status of each device.",
          "info"
        );
        this.handleClose();
      },
      (errorResponse) => {
        this.showToast(
          "Software upgrade request failed for selected devices",
          "error"
        );
      }
    );
  };

  upgradeRunningDevice = () => {
    const deviceId = localStorage.getItem("deviceId");
    let deviceMacId = this.props.selectedDevice[0].provisioned_device.mac_address

    const data = this.state.note
      ? { ...this.state.upgrade, note: this.state.note }
      : this.state.upgrade;
    upgradeDevice(
      data,
      deviceMacId,
      (successResponse) => {
        this.showToast("Software Upgrade Requested.", "info");
        this.handleClose();
        this.fetchUpgradeTaskStatusPeriodic(successResponse.task_id, deviceId);
      },
      (errorResponse) => {
        console.error(errorResponse);
        let errorMessage = "Unknown Error Occurred.";
        if (errorResponse && errorResponse.data) {
          errorMessage = errorResponse.data.message;
          this.showToast(
            "Software Upgrade Request Failed." + errorMessage,
            "error"
          );
        }
      }
    );
  };

  fetchUpgradeTaskStatusPeriodic = (taskId, deviceId) => {
    let deviceMacId = this.props.selectedDevice[0].provisioned_device.mac_address
    this.intervalID = setInterval(() => {
      fetchUpgradeTaskStatus(
        taskId,
        deviceMacId,
        (successResponse) => {
          successResponse.task_status === "OK"
            ? this.showToast("Software Upgrade Successful.", "success")
            : this.showToast("Software Upgrade Failed.", "error");
          clearInterval(this.intervalID);
        },
        (errorResponse) => {
          console.error(errorResponse);
          clearInterval(this.intervalID);
        }
      );
    }, 10000);
  };

  updateOption(provisionedDevices) {

   for (var i = 0; i < this.props.activeDevices.length; i++) {
   if (this.props.activeDevices[i].provisioned_device.mac_address === provisionedDevices.mac_address ) {
       if (this.props.activeDevices[i].device_upgrade_requested === 3 || this.props.activeDevices[i].device_upgrade_requested === 1) {
         return true
       } else {
         return false
       }
    }
   }
 }


  render() {
    const {
      open,
      selectedSerialNo,
      selectedModel,
      requested_at,
      firmwares,
      activeDevices,
      upgradeMany,
    } = this.props;

    return (
      <Dialog
        fullScreen
        open={open}
        onClose={this.handleClose}
        TransitionComponent={Transition}
        className="popup-module"
        disableBackdropClick
      >
        <AppBarComponent
          icon={"fa fa-laptop"}
          title={
            selectedSerialNo
              ? `Upgrade Device (${selectedSerialNo})`
              : `Upgrade Multiple Devices (Model: ${selectedModel})`
          }
          handleClose={this.handleClose}
        />

        <DialogContent className="dialogContent">
          <div className="col-md-4 col-sm-0"></div>
          <div className="col-md-4 col-sm-12">
            <form className="dialogContent-form">
              <p className="phead">
                <b>BRH Firmware</b>{" "}
              </p>
              <Card>
                <CardContent>
                  {requested_at ? (
                    <Typography>
                      Last Performed:
                      {new Date(requested_at).toLocaleString()}
                    </Typography>
                  ) : null}

                  {upgradeMany && (
                    <FormControl margin="normal" style={styles.formControl}>
                      <InputLabel>Select Devices</InputLabel>
                      <Select
                        multiple

                        value={this.state.selectedDevices}
                        onChange={this.handleChangeSelectedDevices}
                        MenuProps={MenuProps}
                        renderValue={() =>
                          activeDevices
                            .filter(({ provisioned_device }) =>
                              this.state.selectedDevices.includes(provisioned_device.mac_address)
                            )
                            .map(
                              ({ provisioned_device }) =>
                                provisioned_device.serial_no
                            )
                            .join(", ")
                        }
                      >
                        {activeDevices.map(({ provisioned_device, id }) => (
                          <MenuItem key={provisioned_device.mac_address} value={provisioned_device.mac_address} className={this.updateOption(provisioned_device) === false ? 'show-in': 'hide'}>

                            <Checkbox
                              checked={this.state.selectedDevices.includes(provisioned_device.mac_address)}
                            />
                            <ListItemText
                              primary={provisioned_device.serial_no}
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}

                  <TextField
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    label="Note (Reason/Cause for Action)"
                    value={this.state.note}
                    onChange={this.handleChangeNote}
                    fullWidth
                    multiline
                    rowsMax={4}
                    margin="normal"
                  />

                  <TextField
                    id="drop_downselect"
                    select
                    label="Version"
                    style={{ marginBottom: 10 }}
                    margin="normal"
                    variant="outlined"
                    value={this.state.upgrade.firmware_id}
                    onChange={this.onVersionChange}
                    SelectProps={{
                      MenuProps: {},
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    <option value="0">--Choose Version--</option>
                    {firmwares.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.version}
                      </option>
                    ))}
                  </TextField>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Boolean(this.state.upgrade.swap_and_reboot)}
                      />
                    }
                    label="Swap and Reboot"
                    style={{ fontSize: 25 }}
                    onChange={this.autoSwapChange}
                  />

                  <br />
                  <div className="form-button-container">
                    <Button
                      variant="outlined"
                      className="form-submit-button"
                      onClick={this.onUpgradeClick}
                    >
                      Upgrade
                    </Button>
                  </div>
                </CardContent>
              </Card>
            </form>
            <br/>
            {upgradeMany && (
              <div style={{border: "2px solid red", padding: "10px"}}>
              <label>Note: Bulk Upgrade is supported upto 5 devices.</label>
            </div>
            )}
          </div>
          <div className="col-md-4 col-sm-0"></div>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    firmwares: state.brhDevicesInfo.firmwareInfo,
  };
};

const mapDispatchToProps = {
  firmwaresList,
};

export default connect(mapStateToProps, mapDispatchToProps)(UpgradeModal);
