import React, { Component } from "react";
import {
  Dialog,
  DialogContent,
  Slide,
  TextField,
  Button,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import AppBarComponent from "../../../common/components/AppBarComponent";
import { resetPasswordAPI } from "../actions/signinActions";
import { toast } from "react-toastify";
import validator from "validator";
import { SpecialCharsRegEx } from "../../../common/constants/constants";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const initialState = {
  data: {
    username: "",
    reset_token: "",
    new_password: "",
    confirm_password: "",
  },
  showNewPassword: false,
  showConfirmPassword: false,
};

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
    };
  }

  handleOnChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.id]: e.target.value,
      },
    });
  };

  handleOnSubmit = () => {
    if (this.formDataValid()) {
      this.resetPassword();
    }
  };

  formDataValid = () => {
    if (
      this.fieldsEmpty() ||
      !this.newPasswordValid() ||
      !this.passwordsMatch()
    ) {
      return false;
    }
    return true;
  };

  fieldsEmpty = () => {
    for (let key in this.state.data) {
      const value = this.state.data[key];
      if (validator.isEmpty(value)) {
        this.showToast("Empty fields are not allowed", "error");
        return true;
      }
    }
    return false;
  };

  newPasswordValid = () => {
    const password = this.state.data.new_password;

    const hasUpper = /[A-Z]/.test(password);
    const hasLower = /[a-z]/.test(password);
    const hasDigit = /[0-9]/.test(password);
    const hasSpecialChar = SpecialCharsRegEx.test(password);

    if (
      password.length >= 6 &&
      hasUpper &&
      hasLower &&
      hasDigit &&
      hasSpecialChar
    ) {
      return true;
    } else {
      this.showToast(
        "Password should atleast have 1 upper, lower, digit, special char and minimum length of 6",
        "error"
      );
      return false;
    }
  };

  passwordsMatch = () => {
    const { new_password, confirm_password } = this.state.data;
    if (new_password === confirm_password) {
      return true;
    } else {
      this.showToast("Passwords do not match", "error");
      return false;
    }
  };

  resetPassword = () => {
    resetPasswordAPI(
      this.state.data,
      (message) => {
        this.showToast(message, "success");
        this.handleClose();
      },
      (message) => this.showToast(message, "error")
    );
  };

  showToast = (message, type = "info") => {
    toast(message, {
      autoclose: 5000,
      type: type,
      position: "top-center",
    });
  };

  handleClose = () => {
    this.setState({
      ...initialState,
    });
    this.props.handleClose();
  };

  handleClickShowNewPassword = () => {
    this.setState({ showNewPassword: !this.state.showNewPassword });
  };

  handleClickShowConfirmPassword = () => {
    this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
  };

  handleKeyUp = (e) => {
    if (e.keyCode === 13) {
      this.handleOnSubmit();
    }
  }

  render() {
    return (
      <Dialog
        fullScreen
        open={this.props.open}
        onClose={this.handleClose}
        className={"popup-module"}
        TransitionComponent={Transition}
      >
        <AppBarComponent
          icon={"fa fa-unlock-alt"}
          title={"Reset Password"}
          handleClose={this.handleClose}
        />
        <DialogContent className="dialogContent">
          <div className="col-md-4 col-sm-0"></div>
          <div className="col-md-4 col-sm-12">
            <div className="dialogContent-form">
              <TextField
                id="username"
                label="Username"
                value={this.state.data.username}
                onChange={this.handleOnChange}
                required
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                margin="normal"
              />
              <TextField
                id="reset_token"
                label="Password Reset Token"
                value={this.state.data.reset_token}
                onChange={this.handleOnChange}
                required
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                margin="normal"
              />
              <TextField
                id="new_password"
                label="New Password"
                value={this.state.data.new_password}
                onChange={this.handleOnChange}
                type={this.state.showNewPassword ? "text" : "password"}
                required
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <InputAdornment className="passwordicon">
                      <IconButton onClick={this.handleClickShowNewPassword}>
                        {this.state.showNewPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                id="confirm_password"
                label="Confirm Password"
                value={this.state.data.confirm_password}
                onChange={this.handleOnChange}
                type={this.state.showConfirmPassword ? "text" : "password"}
                required
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <InputAdornment className="passwordicon">
                      <IconButton onClick={this.handleClickShowConfirmPassword}>
                        {this.state.showConfirmPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onKeyUp={this.handleKeyUp}
              />
            </div>
            <div className="form-button-container">
            <Button
              className="form-submit-button"
              variant="outlined"
              onClick={this.handleOnSubmit}
            >
              Reset Password
            </Button>
            <Button
              variant="outlined"
              className="form-cancel-button"
              onClick={this.handleClose}
            >
              Cancel
            </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

export default ResetPassword;
