import HTTPRequestHandler from "../../utils/HTTPRequestHandler";
import {signOutFunction} from "../actions/signoutActions";
import {toast} from 'react-toastify';

const httpRequestHandler = new HTTPRequestHandler();
const user_id = localStorage.getItem("user_id");
const user_name = localStorage.getItem("username");
const alarm_url = "frontend/alarms/?user_id=" + user_id + "&user_name=" + user_name;
const device_url = "frontend/devices/?&menu=1&user_id=" + user_id + "&user_name=" + user_name;


export function fetchBRHDevicesCount(url = device_url) {
  return function(dispatch) {
    httpRequestHandler
      .get(url)
      .then(response => {
        dispatch({
          type: "FETCH_BRHDEVICES_COUNT_SUCCESS",
          payload: response.data
        });
      })
      .catch(error => {
        if(error.response) {
          if (error.response.status === 403 || error.response.status === 401) {
            toast("Session Invalid. Logging Out.", {
              autoClose: 5000,
              type: toast.TYPE.ERROR,
              position: toast.POSITION.TOP_CENTER,
              });
              setTimeout(() => {
                signOutFunction();
              }, 1.5 * 1000);
              return;
          }
        }
        dispatch({
          type: "FETCH_BRHDEVICES_COUNT_FAILURE",
          error
        });
      });
  };
}

export function fetchAlarmsCount(
  url = alarm_url + "&menu=1&page=1&alarm_state=0"
) {
  return function(dispatch) {
    httpRequestHandler
      .get(url)
      .then(response => {
        dispatch({
          type: "FETCH_ALARMS_COUNT_SUCCESS",
          payload: response.data.count
        });
      })
      .catch(error => {
        dispatch({
          type: "FETCH_ALARMS_COUNT_FAILURE",
          error
        });
      });
  };
}

export function LicenseAccepted(accepted) {
	return function(dispatch) {
		dispatch( {
			type: "USER_ACCEPTED_EULA",
			payload: accepted
			});
	}
}
