import React from "react";
import Select,{ components } from 'react-select';

const dvbs2_options = [
    {
        type: 'group', label: 'Chart Data', options: [
            { value: "dvbs2_per", label: 'PER (DVBS2)' },
            { value: "dvbs2_snr", label: 'SNR (DVBS2)' },
            //{ value: "backhaul_dvb_s2_datarate", label: 'Data Rate (DVBS2)' },
            { value: "dvbs2_input_dbm", label: 'Input DBM (DVBS2)' },
            { value: "dvbs2_mpe_section_error_count", label: 'MPE Section Errors (DVBS2)' },
            // { value: "system_stats_brh_temp", label: 'System Status Temp (DVBS2)' },
            { value: "dvbs2_rtp_seq_error_count", label: 'RTP Sequence Error Count' },
            { value: "dvbs2_ts_continuity_error_count", label: 'TS Continuity Error Count (DVBS2)' }

            /* { value: "backhaul_stl_tp_datarate", label: 'Data Rate (STL-TP)' },
            { value: "backhaul_stl_tp_per", label: 'PER (STL-TP)' },
            { value: "backhaul_stl_tp_epocsync", label: 'Epoch Sync (STL-TP)' },
            { value: "baseband_fec_payload_fmt_time", label: 'FEC Payload FMT Time (BB Processor)' },
            { value: "preamble_effective_bw", label: 'Effective BW (Preamble)' },
            //{ value: "preamble_rf_power_level", label: 'RF Power Level' },
            { value: "oam_temp", label: 'OAM Temp' },
            { value: "parser_l1s_max_parsing_time", label: 'L1S Max Parse Time (Parser)' },
            { value: "parser_tmp_max_parsing_time", label: 'TMP Max Parse Time (Parser)' },
            { value: "pipeline_sched_avg_pipeline_delay", label: 'Average Pipeline Delay (Scheduler)' },
            { value: "pipeline_sched_last_plp_emission_time", label: 'Last PLP Emission Time  (Scheduler)' }, */
        ]
    }
];

const ethernet_options = [
  {
      type: 'group', label: 'Chart Data', options: [
          { value: "dvbs2_rtp_seq_error_count", label: 'RTP Sequence Error Count' },
      ]
  }
];

const optical_fibre_options = [
  {
      type: 'group', label: 'Chart Data', options: [
          { value: "dvbs2_rtp_seq_error_count", label: 'RTP Sequence Error Count' },
      ]
  }
];

const Menu = props => {
  const optionSelectedLength = props.getValue().length || 0;
  const maxSelections = 4;

  return (
    <components.Menu {...props}>
      {optionSelectedLength < maxSelections ? (
        props.children
      ) : (
          <div style={{ margin: 25 , textAlign: 'center', color: 'red'}}><h4>Max limit achieved: {maxSelections}</h4></div>
      )}
    </components.Menu>
  );
};

export default function Options(props) {

  const getOptionsFromSource = (source) => {
    if (source === 0){
      return ethernet_options;
    }else if (source === 1){
      return dvbs2_options;
    }else if (source === 2){
      return optical_fibre_options;
    }
  }

  const isValidNewOption = (inputValue, selectValue) =>
    inputValue.length > 0 && selectValue.length < 5;
  return (
    <div >
      <Select
        components={{ Menu }}
        textAlign="center"
        isMulti
        isValidNewOption={isValidNewOption}
        options={getOptionsFromSource(props.deviceSource)}
        defaultValue={props.defaultValue}
        onChange={props.getSelected}
        placeholder={<strong style={{color:'green'}}>Select Options to Plot Chart</strong>}
      />
    </div>
  );
}
