export default (state = false, action) => {

  switch (action.type) {
    case "SHOW_LOADER":
      return action.data;

    default:
      return state;
  }
};
